<button mat-raised-button color="primary" mat-button *ngIf="canCreate" (click)="create()" class="mb-2">Start Audit</button>
<mtx-grid [data]="list"
          [columns]="columns"
          [loading]="isLoading"
          [columnResizable]="columnResizable"
          [multiSelectable]="multiSelectable"
          [rowSelectable]="rowSelectable"
          [hideRowSelectionCheckbox]="hideRowSelectionCheckbox"
          [rowHover]="rowHover"
          [rowStriped]="rowStriped"
          [showToolbar]="showToolbar"
          [toolbarTitle]="'List Fire Safety Audit'"
          [columnHideable]="columnHideable"
          [columnMovable]="columnMovable"
          [expandable]="expandable"
          [pageOnFront]="showPaginator"
          [showPaginator]="showPaginator"
          [pageSizeOptions]="pageOptions"
          [pageIndex]="0"
          [pageSize]="pageSize"
          (sortChange)="changeSort($event)"
          (selectionChange)="changeSelect($event)"
          (page)="changePage($event)"
          (rowClick)="showDetail($event)">
</mtx-grid>
