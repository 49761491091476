import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MODE } from '@core/app.constants';
import { SiteService } from '@core/service/site.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditSiteComponent } from './create-edit-site/create-edit-site.component';
import { ImportSiteDataComponent } from './import-site-data/import-site-data.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Project Number',
      field: 'internalSiteId',
      sortable: true,
      minWidth: 100,
      class: 'project-number',
    },
    {
      header: 'Site Name',
      field: 'siteName',
      minWidth: 100,
    },
    {
      header: 'Street',
      field: 'street',
      minWidth: 100,
    },
    {
      header: 'City',
      field: 'city',
      minWidth: 100,
    },
    {
      header: 'Post Code',
      field: 'postCode',
      minWidth: 100,
    },
    {
      header: 'Fire Manager',
      field: 'fireManagerName',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this site?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  @Input() site: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentCompanyId: any;
  constructor(
    private cdr: ChangeDetectorRef,
    public dialog: MtxDialog,
    private siteService: SiteService,
    private route: ActivatedRoute,
    private router: Router,
    private toastHandlerService: ToastHandlerService
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.currentCompanyId = id;
      this.getData();
    });
    this.isLoading = false;
  }
  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditSiteComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.siteService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditSiteComponent, {
      width: '600px',
      data: {
        mode: MODE.CREATE,
        currentCompId: this.currentCompanyId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.siteService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(`Site ${PopUpMessages.CreateSuccess}`, '');
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.siteService.getByCompanyId(this.currentCompanyId).subscribe(
      res => {
        this.site = res;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }
  changePage(event: PageEvent) {}
  delete(value: any) {
    this.siteService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`You have deleted ${value.siteName}!`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  showDetail(row: any) {
    this.router.navigate([`/sites/detail/${row.rowData.id}`]);
  }
  importExcel() {
    const dialog = this.dialog.originalOpen(ImportSiteDataComponent, {
      width: '500px',
      data: {
        currentCompanyId: this.currentCompanyId,
      },
    });
    dialog.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.getData();
      }
    });
  }
}
