<page-header [title]="title" class="bg-blue-600"></page-header>
<mat-card>
    <mat-card-title>
        Observation Database Detail
    </mat-card-title>
    <div class="content">
        <div class="left-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Fire Safety Measure: {{observationData?.fireSafetyMeasure}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Standard: {{observationData?.standard}}
            </div>
        </div>
    </div>
</mat-card>
<app-observation-mapping *ngIf="!isLoading" [observationMappings]="observationMappings"></app-observation-mapping>
