import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '@core/bootstrap/menu.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { BreadCrumb } from '@shared/utils/breadcrumb';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: BreadCrumb[];
  @Input() nav: string[] = [];

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private menu: MenuService
  ) {
    this.breadcrumbs = localStorageService.getItemsFromStorage();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(() => {
      this.breadcrumbs = this.localStorageService.getItemsFromStorage();
    });
    this.nav = Array.isArray(this.nav) ? this.nav : [];

    if (this.nav.length === 0) {
      this.genBreadcrumb();
    }
  }

  trackByNavlink(index: number, navlink: string): string {
    return navlink;
  }

  genBreadcrumb() {
    const routes = this.router.url.slice(1).split('/');
    this.nav = this.menu.getLevel(routes);
    this.nav.unshift('Home');
  }
}
