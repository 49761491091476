import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class FireUserService extends BaseApiService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }
  changeUrl() {
    return 'FireUser';
  }
  public getUserByRole(role?: any): Observable<any> {
    if(role != null){
      return this.http.get<any>(this.rootUrl + '/GetUserByRole?role=' + role);
    }
    else{
      return this.http.get<any>(this.rootUrl + '/GetUserByRole');
    }
  }
}


