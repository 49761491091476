import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import jwt_decode from "jwt-decode";
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionStateService {

  private permissionsValue = [];

  constructor(private auth: AuthService) {
    this.initValue();
  }

  initValue() {
    this.auth.isAuthenticated$.subscribe(res => {
      if (res) {
        this.auth.getAccessTokenSilently().subscribe(token => {
          const tokenValue: any = jwt_decode(token);
          this.permissionsValue = tokenValue.permissions;
        }, (err) => console.log('err', err));
      }
    });
  }
  get permissions() {
    return this.permissionsValue;
  }
  hasPermission(permission) {
    return this.permissionsValue.includes(permission);
  }
}
