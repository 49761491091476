import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@core/authentication/interface';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
      <img class="matero-user-panel-avatar" [src]="user?.picture" alt="avatar" width="64" />
      <h4 class="matero-user-panel-name">{{ user?.nickname }}</h4>
      <h5 class="matero-user-panel-email">{{ user?.email }}</h5>
      <div class="matero-user-panel-icons">
        <a routerLink="/profile/overview" mat-icon-button>
          <mat-icon class="icon-20">account_circle</mat-icon>
        </a>
        <a routerLink="/profile/settings" mat-icon-button>
          <mat-icon class="icon-20">settings</mat-icon>
        </a>
        <a (click)="logout()" mat-icon-button>
          <mat-icon class="icon-20">exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit {
  user: Partial<User>;

  constructor(
    private router: Router, 
    private auth: AuthService,  
    @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      this.user = user;
    })
  }

  logout() {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
}
