<nav aria-label="breadcrumb">
  <ol class="matero-breadcrumb">
    <li
      class="matero-breadcrumb-item"
      *ngFor="let navlink of nav; trackBy: trackByNavlink; first as isFirst"
    >
      <a routerLink="#" class="link" *ngIf="isFirst">Home</a>
      <ng-container *ngIf="!isFirst">
        <mat-icon class="chevron">chevron_right</mat-icon>
        <span class="link">{{ navlink | translate }}</span>
      </ng-container>
    </li>
    <li class="matero-breadcrumb-item" *ngIf="breadcrumbs && breadcrumbs.length > 0">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
        <ng-container *ngIf="i === breadcrumbs.length - 1">
          <mat-icon class="chevron">chevron_right</mat-icon>
          {{ breadcrumb.label }}
        </ng-container>
        <ng-container *ngIf="i !== breadcrumbs.length - 1">
          <mat-icon class="chevron">chevron_right</mat-icon>
          <a class="link" routerLink="/{{ breadcrumb.url }}">
            {{ breadcrumb.label }}
          </a>
        </ng-container>
      </ng-container>
    </li>

    <router-outlet></router-outlet>
  </ol>
</nav>
