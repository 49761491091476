import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FIREROLE, MODE } from '@core/app.constants';

@Component({
  selector: 'app-create-edit-fire-user',
  templateUrl: './create-edit-fire-user.component.html',
  styleUrls: ['./create-edit-fire-user.component.scss']
})
export class CreateEditFireUserComponent implements OnInit {

  fireUserForm: FormGroup;
  mode;
  MODE = MODE;
  roles: any;
  FIREROLE = FIREROLE;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditFireUserComponent>,
    private fb: FormBuilder) {
    this.mode = this.data.mode;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.mode === MODE.EDIT) {
      this.fireUserForm.patchValue(this.data.record);
    }
    this.roles = [{ value: FIREROLE.FireManager, name: "Fire Manager" }, { value: FIREROLE.FireAssessor, name: "Fire Assessor" }];

  }

  initForm() {
    this.fireUserForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      mobile: [''],
      email: ['', [Validators.required, Validators.email]],
      role: ['', [Validators.required]]
    });
  }

  getErrorMessage(form: FormGroup) {
    return form.get('email')?.hasError('required')
      ? 'validations.required'
      : form.get('email')?.hasError('email')
        ? 'validations.invalid_email'
        : '';
  }
  saveAndClose() {
    const formValue = this.fireUserForm.value;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }

}
