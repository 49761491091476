import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FireSafetyAuditService } from '@core/service/fire-safety-audit.service';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { FireSafetyAuditClient, FireSafetyAuditDetailDto, ObservationDetailDto, ObservationStatus } from '@shared/tc-api-model';
import { ObservationsComponent } from 'app/routes/observations/observations.component';
import { MtxDialog } from '@ng-matero/extensions';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'app-fire-safety-audit-detail',
  templateUrl: './fire-safety-audit-detail.component.html',
  styleUrls: ['./fire-safety-audit-detail.component.scss'],
})
export class FireSafetyAuditDetailComponent implements OnInit {
  public title = 'Fire Safety Audit Profile';
  public subtitle = '';
  fireSafetyAudit: FireSafetyAuditDetailDto;
  fireSafetyAuditId: any;
  observation: ObservationDetailDto[] = [];
  isLoading = true;

  @ViewChild('appObservations') appObservations: ObservationsComponent;

  constructor(
    public datePipe: DatePipe,
    private fireSafetyAuditService: FireSafetyAuditService,
    private fireSafetyAuditClient: FireSafetyAuditClient,
    private route: ActivatedRoute,
    private mtxDialog: MtxDialog,
    private readonly titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.fireSafetyAuditId = id;
      this.getData().add(() => {
        if (this.fireSafetyAudit && this.fireSafetyAudit.inspectDate){
          let dateStr = this.datePipe.transform(this.fireSafetyAudit.inspectDate, 'yyyy-MM-dd');
          this.titleService.setTitle(
            `${this.title} - ${dateStr}`
          );
        }
      });
    });
    this.isLoading = false;
  }

  getData() {
      return this.fireSafetyAuditClient.get(this.fireSafetyAuditId).subscribe(res => {
      if (res) {
        this.fireSafetyAudit = res;
        if (res.observations) {
          this.observation = res.observations;
        }
        this.isLoading = false;
      }
    });
  }

  generateReport() {
    let self = this;
    let runReport = () => {
      self.fireSafetyAuditService.generateReport(self.fireSafetyAuditId).subscribe({
        next: res => {
          const fileName = res.headers.get('File-Name');
          saveAs(res.body, `${fileName}`);
        },
        error: err => {
          console.log(err);
        },
      });
    };

    let allChecked = this.appObservations.AllChecked();
    if(!allChecked) {
      Utils.OpenConfirmDialog(this.mtxDialog,"Some observations'statuses have not been assigned. Continue?", undefined, () => runReport());
    } else {
      runReport();
    }


  }
}
