import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

export const fsmNames = [
  "Access panels, doors and hoppers to fire-resisting shafts",
  "Automatic fail-safe devices",
  "Automatic fire detection and alarm systems",
  "Automatic fire suppression systems",
  "Emergency lifts",
  "Emergency lighting",
  "Emergency planning",
  "Emergency warning and intercommunication systems",
  "Exit signs",
  "Fire alarm monitoring",
  "Fire control centres and rooms",
  "Fire dampers",
  "Fire doors",
  "Fire hose reel systems",
  "Fire hydrant systems",
  "Fire seals protecting openings in fire-resisting components of the building",
  "Fire shutters",
  "Fire windows",
  "Lightweight construction",
  "Mechanical air handling systems",
  "Paths of Travel to an Exit",
  "Perimeter vehicle access for emergency vehicles",
  "Portable fire extinguishers",
  "Safety curtains in proscenium openings",
  "Smoke alarms and heat alarms",
  "Smoke and heat vents",
  "Smoke dampers",
  "Smoke detectors and heat detectors",
  "Smoke doors",
  "Solid core doors",
  "Standby power systems",
  "Wall-wetting sprinkler and drencher systems",
  "Warning and operational signs"
];

export const mspNames = [
  ["BCA C3.15",
    "AS 1530.4",
    "AS 4072.1"],
  ["BCA Clause D2.21"],
  ["AS1670.1-2004",
    "AS1670.1-2015",
    "AS1670.1-2018",
    "AS1668.1-1998"],
  ["AS2118.1-1982",
    "AS2118.1-1988",
    "AS2118.1-1994",
    "AS2118.1-1999",
    "AS2118.1-2017"],
  ["BCA 3.4"],
  ["AS/NZS 2293.1-2018"],
  ["AS3745-2010"],
  ["AS1670.4-2004",
    "AS1670.4-2015",
    "AS1670.4-2018"],
  ["AS/NZS 2293.1-2018"],
  ["AS 4428.7"],
  ["BCA E1.8"],
  ["AS1668.1-1991",
    "AS1668.1-1998",
    "AS1668.1-2015"],
  ["BAC C3.8",
    "AS 1905.1"],
  ["AS2441-2005"],
  ["Queensland Building Act 1975",
    "NSW Ordinance 70",
    "AS2419-1994",
    "AS2419-2005"],
  ["BCA C3.15",
    "AS 1530.4",
    "AS 4072.1"],
  ["BCA Spec C3.4",
    "AS1905.2-2005"],
  ["BCA Spec C3.4"],
  ["BCA C1.8", "Spec C1.1"],
  ["AS1668.1-1991",
    "AS1668.1-1998"],
  ["BCA Parts D1 and D2", "Division 7"],
  ["BCA C2.4"],
  ["AS2444-2001"],
  ["BCA Spec H1.3"],
  ["AS3786-2014"],
  ["AS1668.1-1991", "AS1668.1-1998"],
  ["AS1668.1-1991", "AS1668.1-1998"],
  ["AS1670.1-2004",
    "AS1670.1-2015",
    "AS1670.1-2018",
    "AS1668.1-1998"],
  ["BCA Spec C3.4"],
  ["BCA C3.11"],
  ["BCA G3 cl 6"],
  ["BCA C3.4", "AS 2118.2"],
  ["BCA D2.23", "E3"]
];

export const defectCategory = ["Major Works", "Minor Works", "Repairs"];
export const defectCriticality = ["Critical Defect", "Non-Critical Defect", "Non-Conformance"];
export const obsComments = [
  [{
    "comment": "Access panel appears damaged and requires repairs",
    "rectification": "Repair access panel",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Fail safe devices require maintenance",
    "rectification": "Maintain fail safe devices",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Smoke detectors have not been installed in this area and are required to be provided",
    "rectification": "Install smoke detection as required to comply with the nominated standard",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Smoke detection installed in kitchen area, which may be the subject of false alarms.",
    "rectification": "Review the spacing of the detection and change to heat detection as required.",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "No occupant warning speakers are fitted to this area, unlikely to achieve the required sound pressure levels",
    "rectification": "Provide additional speakers as required to achieve the correct sound pressure levels",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [
    {
      "comment": "Sprinklers are omitted from this area and required to be provided",
      "rectification": "Complete a design for this area, and install new sprinklers as required by AS2118.1. Provide installation certificate at the conclusion of the works",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Sprinklers are baffled by other services",
      "rectification": "Relocate the existing sprinklers or install new, as required by AS2118.1. Provide installation certificate at the conclusion of the works",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Sprinklers are corroded",
      "rectification": "Replace existing sprinklers with new",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Sprinkler block plan has faded",
      "rectification": "Replace the existing block plan with new",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Sprinkler block plan has not been installed",
      "rectification": "Install new sprinkler block plan",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Sprinkler block plan has incorrect information",
      "rectification": "Install new sprinkler block plan",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Sprinkler pump requires service",
      "rectification": "Service the sprinkler pump in accordance with AS1851",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Fire Sprinkler spares box must have the correct spare fire sprinkler head for each installation.",
      "rectification": "Provide required sprinklers within the spares box",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Evidence of external water on the sprinkler tank indicates Tank(s) internal liner may require replacement. Tanks to be maintained in accordance with AS 1851 and the tank manufacturer’s recommendations.",
      "rectification": "Complete tank maintenance",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Some isolation valves were observed as unmonitored, or were disconnected from monitoring services. Main stop valves were also left unlocked.",
      "rectification": "Review all valves, and install Class B monitoring devices throughout the Automatic Fire Sprinkler System in accordance with AS 2118.1-1999, Cl 3.4.2.3 and 3.4.3.",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    }
  ],
  [{
    "comment": "Emergency lift requires signage",
    "rectification": "Provide signage to lift",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "No emergency lighting installed throughout the warehouse. This is a non-conformance with AS2293.1.",
    "rectification": "Install emergency lights to comply with AS2293.1.",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Emergency plans do not reflect the building layout accurately",
    "rectification": "Update the emergency plans in accordance with AS3745",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Emergency plans have not been fitted",
    "rectification": "Install emergency plans in accordance with AS3745",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Staff have been observed wearing hearing protection. Visual alarm devices are required to be provided",
    "rectification": "Provide strobe lights as required",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "The ambient noise levels in this area necessetate the provision of visual alarm devices.",
    "rectification": "Provide strobe lights as required",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "No occupant warning speakers are fitted to this area, unlikely to achieve the required sound pressure levels",
    "rectification": "",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Exit sign is not illuminated. Maintenance required.",
    "rectification": "Maintain the exit signs",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Due to wall and door configuration, an additional exit light is required",
    "rectification": "Provide additional exit signs as required to suit the partition layout",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Alarm signalling equipment is indicating fault",
    "rectification": "Repair the fault",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Fire control room is missing the required furnishings",
    "rectification": "Provide all furnishings required by NCC-BCA",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Fire dampers are showing signs of damage and require maintenance",
    "rectification": "Repair the dampers",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [
    {
      "comment": "Automatic Door Closer not fastened to Fire Door.",
      "rectification": "Inspect door and Automatic Door Closer. Repair all items as required.",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Door does not close completely.",
      "rectification": "Inspect door and repair as required.",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Tag painted over and doors permanently opened.",
      "rectification": "Inspect door, re-tag door and frame. Or;Replace entire assembly and frame if found not to comply with the relevant standard",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    }
  ],
  [
    {
      "comment": "Maintenance Tag is out of date",
      "rectification": "Complete maintenance routine and stamp tag accordingly",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    }
  ],
  [
    {
      "comment": "Fire hydrant valves are not fitted with the correct outlets",
      "rectification": "Replace the outlets as necessary",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Fire hydrant maintenance tags are out of date",
      "rectification": "Perform maintenance routine and stamp the maintenance tag",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Fire hydrant valves are installed at the incorrect height",
      "rectification": "Install fire hydrant valves at the correct height as per AS2419",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Fire hydrant booster is too far from appropriate hardstand",
      "rectification": "Relocate the booster to be within 20m of hardstand",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Hydrant block plan has faded",
      "rectification": "Replace the existing block plan with new",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Hydrant block plan has not been installed",
      "rectification": "Install new hydrant block plan",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Hydrant block plan has incorrect information",
      "rectification": "Install new hydrant block plan",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[2]
    },
    {
      "comment": "Evidence of external water on the hydrant tank indicates Tank(s) internal liner may require replacement. Tanks to be maintained in accordance with AS 1851 and the tank manufacturer’s recommendations.",
      "rectification": "Complete tank maintenance",
      "defectCategory": defectCategory[2],
      "defectCriticality": defectCriticality[1]
    },
    {
      "comment": "Some isolation valves were observed as unmonitored, or were disconnected from monitoring services. Main stop valves were also left unlocked.",
      "rectification": "Review all valves, and install Class B monitoring devices throughout the  System in accordance with AS 2419",
      "defectCategory": defectCategory[1],
      "defectCriticality": defectCriticality[1]
    }
  ],
  [{
    "comment": "Fire seals appear to be damaged and require replacement",
    "rectification": "Replace fire seals",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Fire shutters appear to be damaged and require repair",
    "rectification": "Repair fire shutter",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Fire windows appear to be damaged and require repair",
    "rectification": "Repair fire window",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Partitions appear to be damaged and require repair",
    "rectification": "Repair partition",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Fire fan control panel requires labelling",
    "rectification": "Provide labelling to the fire fan control panel",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Storage is obstructing access to the exit and requires removal",
    "rectification": "Remove storage",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Storage is obstructing access to the exit and requires removal",
    "rectification": "Remove storage",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Exit doors are obstructed by security gate",
    "rectification": "Remove security gate",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Exit doors are obstructed by non-compliant door hardware",
    "rectification": "Replace door hardware with compliant fittings",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Perimeter vehicle access is obstructed by storage",
    "rectification": "Remove storage",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Maintenance tag is missing",
    "rectification": "Provide maintenance tag",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Maintenance Tag is out of date",
    "rectification": "Maintain the extinguisher and stamp the tag accordingly",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Wrong extinguisher type for this occupancy",
    "rectification": "Replace the extinguisher with the correct type",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Extinguisher is obstructed by storage",
    "rectification": "Remove storage",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Safety curtain requires maintenance",
    "rectification": "Maintain curtain",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Smoke alarm has not been installed",
    "rectification": "Install smoke alarm",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Smoke and heat vents have been obstructed",
    "rectification": "Remove obstruction",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Smoke dampers are showing signs of damage and require maintenance",
    "rectification": "Repair the dampers",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Smoke detectors have not been installed in this area and are required to be provided",
    "rectification": "Install smoke detection as required to comply with the nominated standard",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Smoke detection installed in kitchen area, which may be the subject of false alarms.",
    "rectification": "Review the spacing of the detection and change to heat detection as required.",
    "defectCategory": defectCategory[1],
    "defectCriticality": defectCriticality[1]
  }],
  [{
    "comment": "Automatic Door Closer not fastened to Fire Door.",
    "rectification": "Inspect door and Automatic Door Closer. Repair all items as required. ",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Door does not close completely.",
    "rectification": "Inspect door and repair as required. ",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  },
  {
    "comment": "Tag painted over and doors permanently opened.",
    "rectification": "Inspect door, re-tag door and frame. Or;Replace entire assembly and frame if found not to comply with the relevant standard",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Automatic Door Closer not fastened to Fire Door.",
    "rectification": "Inspect door and Automatic Door Closer. Repair all items as required.",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[1]
  },
  {
    "comment": "Door does not close completely.",
    "rectification": "Inspect door and repair as required.",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  },
  {
    "comment": "Tag painted over and doors permanently opened.",
    "rectification": "Inspect door, re-tag door and frame. Or;Replace entire assembly and frame if found not to comply with the relevant standard",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Generator requires maintenance",
    "rectification": "Maintain the generator",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Wall wetting sprinklers are obstructed and require service",
    "rectification": "Remove obstruction",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }],
  [{
    "comment": "Signage has faded and requires replacement",
    "rectification": "Replace warning and operational signs",
    "defectCategory": defectCategory[2],
    "defectCriticality": defectCriticality[2]
  }]

];
@Injectable({
  providedIn: 'root'
})
export class FireSafetyScheduleService extends BaseApiService {
  constructor(http: HttpClient) {
    super(http);
  }
  changeUrl() {
    return 'FireSafetySchedule';
  }
  mappingStandard() {
    const data = new Map();
    for (let i = 0; i < fsmNames.length; i++) {
      data.set(fsmNames[i], mspNames[i]);
    }
    return data;
  }
  mappingComment() {
    const data = new Map();
    for (let i = 0; i < fsmNames.length; i++) {
      data.set(fsmNames[i], obsComments[i]);
    }
    return data;
  }
  public getBySiteId(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/getBySiteId?id=' + id);
  }
}

