import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MODE } from '@core/app.constants';
import { ObservationDataService } from '@core/service/observation-data.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditFireSafetyMappingComponent } from './create-edit-fire-safety-mapping/create-edit-fire-safety-mapping.component';
import { ImportObservationDataComponent } from './import-observation-data/import-observation-data.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-observation-database',
  templateUrl: './observation-database.component.html',
  styleUrls: ['./observation-database.component.scss'],
})
export class ObservationDatabaseComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Name',
      field: 'fireSafetyMeasure',
      sortable: true,
      minWidth: 100,
      class: 'fire-safety',
    },
    {
      header: 'Standard',
      field: 'standard',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this fire safety measure?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  list: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private toastHandlerService: ToastHandlerService,
    private observationData: ObservationDataService,
    public mtxDialog: MtxDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  create() {
    const dialogRef = this.mtxDialog.originalOpen(CreateEditFireSafetyMappingComponent, {
      width: '600px',
      data: { mode: MODE.CREATE },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.observationData.createFireSafetyMeasureMapping(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(
          `Fire Safety Measure Mapping ${PopUpMessages.CreateSuccess}`,
          ''
        );
        this.getData();
      });
  }
  edit(value: any) {
    const dialogRef = this.mtxDialog.originalOpen(CreateEditFireSafetyMappingComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.observationData.updateFireSafetyMeasureMapping(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(
          PopUpMessages.EditSuccess,
          ''
        );
        this.getData();
      });
  }

  getData() {
    this.isLoading = true;
    this.observationData.getAllFireSafetyMeasureMapping().subscribe(res => {
      this.list = res;
      this.isLoading = false;
    });
  }
  delete(value: any) {
    this.observationData.deleteFireSafetyMeasureMapping(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`You have deleted ${value.fireSafetyMeasure}!`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }

  showDetail(row: any) {
    this.router.navigate([`/observation-database/detail/${row.rowData.id}`]);
  }

  importExcel() {
    const dialog = this.mtxDialog.originalOpen(ImportObservationDataComponent, {
      width: '500px',
    });
    dialog.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.getData();
      }
    });
  }
}
