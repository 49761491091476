<page-header [title]="title" class="bg-blue-600"></page-header>
<mat-card>
    <mat-card-title>
        Fire Safety Audit Detail
    </mat-card-title>
    <div class="content">
        <div class="left-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Inspection Due Date: {{fireSafetyAudit?.inspectByDate | date: 'dd-MM-yyyy'}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Fire Assessor: {{fireSafetyAudit?.fireAssessorName}}
            </div>
        </div>
        <div class="middle-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Date of Inspection: {{fireSafetyAudit?.inspectDate | date: 'dd-MM-yyyy'}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                <button type="button" class="btn btn-info"(click)="generateReport()">Report Generation</button>
            </div>
        </div>
        <div class="right-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Certificate Due Date : {{fireSafetyAudit?.certDueDate | date: 'dd-MM-yyyy'}}
            </div>
        </div>
    </div>
</mat-card>
<app-observations #appObservations *ngIf="!isLoading" [data]="observation"></app-observations>
