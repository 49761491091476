export enum PERMISSIONS {
    CREATE_COMPANY = 'create:company',
    UPDATE_COMPANY = 'update:company',
    DELETE_COMPANY = 'delete:company',
    READ_COMPANY = 'read:company',
    CREATE_FIRESAFETYAUDIT = 'create:fireSafetyAudit',
    UPDATE_FIRESAFETYAUDIT = 'update:fireSafetyAudit',
    DELETE_FIRESAFETYAUDIT = 'delete:fireSafetyAudit',
    READ_FIRESAFETYAUDIT = 'read:fireSafetyAudit',
    CREATE_OBSERVATION = 'create:observation',
    UPDATE_OBSERVATION = 'update:observation',
    DELETE_OBSERVATION = 'delete:observation',
    READ_OBSERVATION = 'read:observation',
    UPDATE_OBSERVATIONDATA = 'update:observationData'
}