<div class="nav-container">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container">
            <div >
                <a routerLink="/">
                    <div class="navbar-brand logo"></div>
                </a>
            </div>           
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" [attr.aria-expanded]="!isCollapsed" aria-label="Toggle navigation"
                (click)="isCollapsed = !isCollapsed">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mr-auto">

                </ul>

                <ul class="navbar-nav d-none d-md-block">
                    <!-- Login button: show if NOT authenticated -->
                    <li class="nav-item" *ngIf="(auth.isAuthenticated$ | async) === false">
                        <button id="qsLoginBtn" class="btn btn-primary btn-margin" (click)="loginWithRedirect()">
                            Log in
                        </button>
                    </li>
                    <!-- / Login button -->

                    <!-- Fullsize dropdown: show if authenticated -->
                    <li class="nav-item dropdown" *ngIf="auth.user$ | async as user" ngbDropdown>
                        <a ngbDropdownToggle class="nav-link dropdown-toggle" id="profileDropDown"
                            data-toggle="dropdown">
                            <!-- Profile image should be set to the profile picture from the id token -->
                            <img [src]="user.picture" alt="Profile picture" class="nav-user-profile rounded-circle"
                                style="width: 75px;" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <!-- Show the user's full name from the id token here -->
                            <div class="dropdown-header">
                                {{ user.name }}
                            </div>
                            <a routerLink="/profile" class="dropdown-item dropdown-profile">
                                <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
                            </a>
                            <button (click)="logout()" class="btn btn-link dropdown-item" id="qsLogoutBtn">
                                <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
                            </button>
                        </div>
                    </li>
                    <!-- /Fullsize dropdown -->
                </ul>

                <!-- Responsive login button: show if NOT authenticated -->
                <ul class="navbar-nav d-md-none" *ngIf="(auth.isAuthenticated$ | async) === false">
                    <button class="btn btn-primary btn-block" id="qsLoginBtn" (click)="loginWithRedirect()">
                        Log in
                    </button>
                </ul>
                <!-- /Responsive login button -->

                <!-- Responsive profile dropdown: show if authenticated -->
                <ul class="navbar-nav d-md-none justify-content-between" *ngIf="auth.user$ | async as user"
                    style="min-height: 170px;">
                    <li class="nav-item">
                        <span class="user-info">
                            <!-- Profile image should be set to the profile picture from the id token -->
                            <img alt="Profile picture" class="nav-user-profile d-inline-block rounded-circle mr-3"
                                style="width: 75px;" [src]="user.picture" />
                            <!-- Show the user's full name from the id token here -->
                            <h6 class="d-inline-block">{{ user.name }}</h6>
                        </span>
                    </li>
                    <li>
                        <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
                        <a routerLink="/profile">Profile</a>
                    </li>

                    <li>
                        <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
                        <button class="btn btn-link p-0" id="qsLogoutBtn" (click)="logout()">
                            Log out
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>