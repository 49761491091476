import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODE } from '@core/app.constants';
import { defectCategory, defectCriticality } from '@core/service/fire-safety-schedule.service';

@Component({
  selector: 'app-create-edit-observation-mapping',
  templateUrl: './create-edit-observation-mapping.component.html',
  styleUrls: ['./create-edit-observation-mapping.component.scss']
})
export class CreateEditObservationMappingComponent implements OnInit {

  observationMappingForm: FormGroup;
  mode;
  MODE = MODE;
  defectCategory = defectCategory;
  defectCriticality = defectCriticality;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditObservationMappingComponent>,
    private fb: FormBuilder) {
    this.mode = this.data.mode;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.mode === MODE.EDIT) {
      this.observationMappingForm.patchValue(this.data.record);
    }
  }
  initForm() {
    this.observationMappingForm = this.fb.group({
      observationComment: ['', [Validators.required]],
      rectification: [''],
      defectCategory: [''],
      defectCriticality: [''],
    });
  }
  saveAndClose() {
    const formValue = this.observationMappingForm.value;
    formValue.FireSafetyMeasureMappingId = this.data.currentFireSafetyMappingId;

    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }

}
