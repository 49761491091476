import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
})
export class EulaComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
  ) { }
  ngOnInit(): void {
  }

}
