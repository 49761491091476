import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ObservationDataService } from '@core/service/observation-data.service';
import { MtxDialog } from '@ng-matero/extensions';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { IImportResult, ImportResult } from '@shared/utils/import-result';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'app-import-observation-data',
  templateUrl: './import-observation-data.component.html',
  styleUrls: ['./import-observation-data.component.scss'],
})
export class ImportObservationDataComponent implements OnInit {
  selectedFiles: FileList;
  currentFileUpload: File;
  loading = false;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private dialogRef: MatDialogRef<ImportObservationDataComponent>,
    private observationDataService: ObservationDataService,
    public dialog: MtxDialog
  ) {}

  ngOnInit(): void {}
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0);
  }

  importExcel() {
    if (!this.selectedFiles) {
      this.dialog.alert('Please choose an excel file (.xlsx)');
      return;
    }
    const formData = new FormData();
    formData.append('File', this.currentFileUpload);
    this.observationDataService.importFromExcel(formData).subscribe((res: IImportResult) => {
      var data = new ImportResult(res);
      if (!data.hasError && (!data.detailResult || !data.detailResult.hasError))
        Utils.OpenOkDialog(this.dialog, 'Observation database imported successfully!');
      else
        Utils.OpenOkDialog(this.dialog, data.getSummary(), 'Import Result');

      this.dialogRef.close('refresh');
    }).add(()=> this.loading = false);
  }
}
