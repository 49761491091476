import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ObservationDataService extends BaseApiService {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }
  changeUrl() {
    return 'ObservationData';
  }
  public getAllFireSafetyMeasureMapping(): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetAllFireSafetyMeasureMapping');
  }
  public importFromExcel(item: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/ImportFromExcel', item);
  }
  public deleteFireSafetyMeasureMapping(id: any): Observable<any> {
    return this.http.delete<any>(this.rootUrl + '/DeleteFireSafetyMeasureMapping', {
      params: new HttpParams().set('Id', id)
    });
  }
  public updateFireSafetyMeasureMapping(item: any): Observable<any> {
    return this.http.put<any>(this.rootUrl + '/UpdateFireSafetyMeasureMapping', item);
  }
  public createFireSafetyMeasureMapping(item: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/CreateFireSafetyMeasureMapping', item);
  }
  public getFireSafetyMeasureMappingById(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetFireSafetyMeasureMappingById?id=' + id);
  }
  public createObservationMapping(item: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/CreateObservationMapping', item);
  }
  public updateObservationMapping(item: any): Observable<any> {
    return this.http.put<any>(this.rootUrl + '/UpdateObservationMapping', item);
  }
  public deleteObservationMapping(id: any): Observable<any> {
    return this.http.delete<any>(this.rootUrl + '/DeleteObservationMapping', {
      params: new HttpParams().set('Id', id)
    });
  }
  public getObservationDataByFireSafetyMeasureId(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetObservationDataByFireSafetyMeasureId?id=' + id);
  }
  public getDropDownListFireSafetyMeasure(): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetDropDownListFireSafetyMeasure');
  }
  public getDropDownListObservation(): Observable<[{fireSafetyMeasure:string, standard:string, observationMappings:[] }]> {
    return this.http.get<any>(this.rootUrl + '/GetDropDownListObservation');
  }
}
