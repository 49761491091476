import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteService } from '@core/service/site.service';
import { MtxDialog } from '@ng-matero/extensions';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { IImportResult, ImportResult } from '@shared/utils/import-result';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'app-import-site-data',
  templateUrl: './import-site-data.component.html',
  styleUrls: ['./import-site-data.component.scss'],
})
export class ImportSiteDataComponent implements OnInit {
  selectedFiles: FileList;
  currentFileUpload: File;
  currentCompanyId: any;
  loading = false;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private dialogRef: MatDialogRef<ImportSiteDataComponent>,
    private siteService: SiteService,
    public dialog: MtxDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentCompanyId = this.data.currentCompanyId;
  }

  ngOnInit(): void {}
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0);
  }

  importExcel() {
    if (!this.selectedFiles) {
      this.dialog.alert('Choose a file excel!');
      return;
    }
    const formData = new FormData();
    formData.append('File', this.currentFileUpload);
    formData.append('CompanyId', this.currentCompanyId);

    this.siteService.importFromExcel(formData).subscribe((res: IImportResult) => {
      var data = new ImportResult(res);
      if (!data.hasError && (!data.detailResult || !data.detailResult.hasError))
        Utils.OpenOkDialog(this.dialog, 'Site data imported successfully!');
      else
        Utils.OpenOkDialog(this.dialog, data.getSummary(), 'Import Result');

      this.dialogRef.close('refresh');
    }).add(()=> this.loading = false);
  }
}
