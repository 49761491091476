<mat-card-title>
    {{mode === MODE.CREATE ? 'Create Site' : 'Edit Site'}}
</mat-card-title>
<form [formGroup]="siteForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Project Number</mat-label>
                <input matInput placeholder="Input project number" formControlName="internalSiteId" type="number"
                       required>
                <mat-error *ngIf="siteForm.get('internalSiteId')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Site name</mat-label>
                <input matInput placeholder="Input site name" formControlName="siteName">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Street</mat-label>
                <input matInput placeholder="Input street" formControlName="street" >
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput placeholder="Input city"
                       formControlName="city">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Post code</mat-label>
                <input matInput placeholder="Input post code"
                       formControlName="postCode"
                       maxlength="4"
                       (paste)="onlyNumberCheck($event, 'paste')"
                        (keypress)="onlyNumberCheck($event, 'keyPress')">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Fire Manager</mat-label>
                <mat-select formControlName="fireUserId">
                    <mat-option *ngFor="let user of fireManagers" [value]="user.id">
                      {{(user?.firstName ? user?.firstName : "") + " " +( user?.lastName ? user?.lastName : "") + " (" + user.email + ")"}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="siteForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>