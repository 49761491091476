<button mat-raised-button color="primary" mat-button (click)="create()" class="mb-2">
  Add Fire Safety Measure
</button>
<mtx-grid
  [data]="fireSafetySchedule"
  [columns]="columns"
  [loading]="isLoading"
  [columnResizable]="columnResizable"
  [multiSelectable]="multiSelectable"
  [rowSelectable]="rowSelectable"
  [hideRowSelectionCheckbox]="hideRowSelectionCheckbox"
  [rowHover]="rowHover"
  [rowStriped]="rowStriped"
  [showToolbar]="showToolbar"
  [toolbarTitle]="'List Fire Safety Schedule'"
  [columnHideable]="columnHideable"
  [columnMovable]="columnMovable"
  [expandable]="expandable"
  [pageOnFront]="showPaginator"
  [showPaginator]="showPaginator"
  [pageSizeOptions]="pageOptions"
  [pageIndex]="0"
  [pageSize]="pageSize"
  (sortChange)="changeSort($event)"
  (selectionChange)="changeSelect($event)"
  (page)="changePage($event)"
>
</mtx-grid>
