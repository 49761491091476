import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MODE } from '@core/app.constants';
import { ObservationDataService } from '@core/service/observation-data.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditObservationMappingComponent } from '../create-edit-observation-mapping/create-edit-observation-mapping.component';
import { PopUpMessages } from '@shared/utils/messages';


@Component({
  selector: 'app-observation-mapping',
  templateUrl: './observation-mapping.component.html',
  styleUrls: ['./observation-mapping.component.scss'],
})
export class ObservationMappingComponent implements OnInit {
  @Input() observationMappings: any[] = [];
  columns: MtxGridColumn[] = [
    {
      header: 'Observation Comment',
      field: 'observationComment',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Rectification',
      field: 'rectification',
      minWidth: 100,
    },
    {
      header: 'Defect Category',
      field: 'defectCategory',
      minWidth: 100,
    },
    {
      header: 'Defect Criticality',
      field: 'defectCriticality',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this observation data?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentFireSafetyMappingId: any;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private route: ActivatedRoute,
    public mtxDialog: MtxDialog,
    private observationDataService: ObservationDataService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.currentFireSafetyMappingId = this.route.snapshot.paramMap.get('id');
  }
  create() {
    const dialogRef = this.mtxDialog.originalOpen(CreateEditObservationMappingComponent, {
      width: '600px',
      data: { mode: MODE.CREATE, currentFireSafetyMappingId: this.currentFireSafetyMappingId },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.observationDataService.createObservationMapping(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(
          `Observation Mapping ${PopUpMessages.CreateSuccess}`,
          ''
        );
        this.getData();
      });
  }
  edit(value: any) {
    const dialogRef = this.mtxDialog.originalOpen(CreateEditObservationMappingComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.observationDataService.updateObservationMapping(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(
          PopUpMessages.EditSuccess,
          ''
        );
        this.getData();
      });
  }

  getData() {
    this.isLoading = true;
    this.observationDataService
      .getObservationDataByFireSafetyMeasureId(this.currentFireSafetyMappingId)
      .subscribe(res => {
        this.observationMappings = res;
        this.isLoading = false;
      });
  }
  delete(value: any) {
    this.observationDataService.deleteObservationMapping(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`${value.observationComment} ${PopUpMessages.DeleteSuccess}`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }
}
