import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Bookmark } from '@shared/utils/bookmark';
import { BookmarkService } from '@shared/services/bookmark.service';
import { BookmarkDialogComponent } from '../bookmark-dialog/bookmark-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  styleUrls: ['./bookmark-button.component.scss'],
})
export class BookmarkButtonComponent implements OnInit {
  @Input() iconType: string = 'star_outline';
  @Output() iconChange = new EventEmitter<string>();
  public bookmark: Bookmark | null = null;
  url: string;
  star: boolean;

  constructor(
    private dialog: MatDialog,
    private bookmarkService: BookmarkService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(() => {
      // Check if there is an existing bookmark with the same URL and update the iconType accordingly
      const bookmarks = this.bookmarkService.getBookmarks();
      let existingBookmark = null;
      for (const id in bookmarks) {
        if (bookmarks[id].url === this.router.url) {
          existingBookmark = bookmarks[id];
          this.iconType = 'star';
          break;
        }
      }
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(BookmarkDialogComponent);

    dialogRef.componentInstance.buttonSelected.subscribe((type: string) => {
      if (type === 'unstar') {
        this.iconType = 'star_outline';
      } else if (type === 'star') {
        this.iconType = 'star';
      } else {
        this.iconType = 'star';
      }

      this.iconChange.emit(this.iconType);
    });
  }
}
