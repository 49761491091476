<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<title>test</title>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Angsana New";
	panose-1:2 2 6 3 5 4 5 2 3 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:Corbel;
	panose-1:2 11 5 3 2 2 4 2 2 4;}
@font-face
	{font-family:"Arial Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"Frutiger 45 Light";}
@font-face
	{font-family:"Arial Narrow";
	panose-1:2 11 6 6 2 2 2 3 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
h1
	{mso-style-name:"Heading 1\,AMW Doc H1\,Section Heading\,No numbers\,H1\,Title1\,h1\,Head1\,Heading apps\,L1\,Level 1\,Appendix\,Appendix1\,Appendix2\,Appendix3\,no pg\,I1\,1st level\,l1\,Chapter title\,t�tulo1\,H11\,R1\,app heading 1\,ITT t1\,PA Chapter\,II+\,I\,Chapter Heading\,T1\,Heading 1 Char2";
	mso-style-link:"Heading 1 Char\,AMW Doc H1 Char\,Section Heading Char\,No numbers Char\,H1 Char\,Title1 Char\,h1 Char\,Head1 Char\,Heading apps Char\,L1 Char\,Level 1 Char\,Appendix Char\,Appendix1 Char\,Appendix2 Char\,Appendix3 Char\,no pg Char\,I1 Char\,1st level Char\,l1 Char\,I Char";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
h2
	{mso-style-name:"Heading 2\,AMW Doc H2\,body\,h2\,H2\,Section\,h2\.H2\,1\.1\,UNDERRUBRIK 1-2\,Para2\,h21\,h22\,h2 main heading\,2m\,h 2\,Subhead A\,Attribute Heading 2\,test\,l2\,list 2\,list 2\,heading 2TOC\,Head 2\,List level 2\,2\,Header 2\,B Sub\/Bold\,B Sub\/Bold1\,B Sub\/Bold2\,B Sub\/Bold11\,H-2";
	mso-style-link:"Heading 2 Char\,AMW Doc H2 Char\,body Char\,h2 Char\,H2 Char\,Section Char\,h2\.H2 Char\,1\.1 Char\,UNDERRUBRIK 1-2 Char\,Para2 Char\,h21 Char\,h22 Char\,h2 main heading Char\,2m Char\,h 2 Char\,Subhead A Char\,Attribute Heading 2 Char\,test Char\,l2 Char\,list 2 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
h3
	{mso-style-name:"Heading 3\,AMW Doc H3 \(a\)\,3\,H3\,H31\,C Sub-Sub\/Italic\,h3 sub heading\,Head 3\,Head 31\,Head 32\,C Sub-Sub\/Italic1\,Sub2Para\,h3\,\(Alt+3\)\,3m\,d\,Head 33\,C Sub-Sub\/Italic2\,Head 311\,Head 321\,C Sub-Sub\/Italic11\,h31\,Bold Head\,bh\,Level 1 - 2\,Level 1 - 1\,l3\,head3\,h3\.H3\,L3";
	mso-style-link:"Heading 3 Char\,AMW Doc H3 \(a\) Char\,3 Char\,H3 Char\,H31 Char\,C Sub-Sub\/Italic Char\,h3 sub heading Char\,Head 3 Char\,Head 31 Char\,Head 32 Char\,C Sub-Sub\/Italic1 Char\,Sub2Para Char\,h3 Char\,\(Alt+3\) Char\,3m Char\,d Char\,Head 33 Char\,C Sub-Sub\/Italic2 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:70.85pt;
	text-indent:-35.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:normal;}
h4
	{mso-style-name:"Heading 4\,Style 31\,h4\,4\,Char\,Heading4_Titre4\,H4\,\(Alt+4\)\,H41\,\(Alt+4\)1\,H42\,\(Alt+4\)2\,H43\,\(Alt+4\)3\,H44\,\(Alt+4\)4\,H45\,\(Alt+4\)5\,H411\,\(Alt+4\)11\,H421\,\(Alt+4\)21\,H431\,\(Alt+4\)31\,H46\,\(Alt+4\)6\,H412\,\(Alt+4\)12\,H422\,\(Alt+4\)22\,H432\,\(Alt+4\)32\,H47\,\(Alt+4\)7\,H48\,\(Alt+4\)8\,H49";
	mso-style-link:"Heading 4 Char\,Style 31 Char\,h4 Char\,4 Char\,Char Char\,Heading4_Titre4 Char\,H4 Char\,\(Alt+4\) Char\,H41 Char\,\(Alt+4\)1 Char\,H42 Char\,\(Alt+4\)2 Char\,H43 Char\,\(Alt+4\)3 Char\,H44 Char\,\(Alt+4\)4 Char\,H45 Char\,\(Alt+4\)5 Char\,H411 Char\,\(Alt+4\)11 Char\,H421 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:106.3pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:normal;}
h5
	{mso-style-name:"Heading 5\,5\,H5\,Level 3 - i\,Dot GS\,level5\,3rd sub-clause\,Para5\,h5\,h51\,h52\,L5\,After Paragraph\,after paragraph\,Heading 5 After Paragraph\,Heading 5 Char1\,Heading 5 StGeorge Char\,Para5 Char1\,h5 Char1\,h51 Char1\,h52 Char1\,L5 Char1\,H5 Char1\,Dot GS Char\,A\,\(A\)Tex";
	mso-style-link:"Heading 5 Char\,5 Char\,H5 Char\,Level 3 - i Char\,Dot GS Char1\,level5 Char\,3rd sub-clause Char\,Para5 Char\,h5 Char\,h51 Char\,h52 Char\,L5 Char\,After Paragraph Char\,after paragraph Char\,Heading 5 After Paragraph Char\,Heading 5 Char1 Char\,Para5 Char1 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.0cm;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:normal;}
h6
	{mso-style-name:"Heading 6\,6\,H6\,Legal Level 1\.\,dash GS\,level6\,a\.\,\(I\)\,Heading 6\(unused\)\,as\,not Kinhill\,Not Kinhill\,h6\,a\.1\,Sub5Para\,Heading 6LS\,Body Text 5\,sub-dash\,sd\,Level 6\,\(I\)a\,L1 PIP\,Name of Org\,Lev 6\,Square Bullet list\,Heading 6 Interstar\,heading 6\,a\,b\,rp_Heading 6";
	mso-style-link:"Heading 6 Char\,6 Char\,H6 Char\,Legal Level 1\. Char\,dash GS Char\,level6 Char\,a\. Char\,\(I\) Char\,Heading 6\(unused\) Char\,as Char\,not Kinhill Char\,Not Kinhill Char\,h6 Char\,a\.1 Char\,Sub5Para Char\,Heading 6LS Char\,Body Text 5 Char\,sub-dash Char\,sd Char\,\(I\)a Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.15pt;
	text-indent:-35.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{mso-style-name:"Heading 7\,H7\,Legal Level 1\.1\.\,square GS\,level1noheading\,h7\,ap\,i\.\,\(1\)\,Heading 7\(unused\)\,i\.1\,not Kinhill1\,7\,Tabelle\,L2 PIP\,rp_Heading 7\,SDL title\,st\,sdl title\,Subpara 4\,Para no numbering\,letter list\,lettered list\,L1 Heading 7\,ITT t7\,PA Appendix Major";
	mso-style-link:"Heading 7 Char\,H7 Char\,Legal Level 1\.1\. Char\,square GS Char\,level1noheading Char\,h7 Char\,ap Char\,i\. Char\,\(1\) Char\,Heading 7\(unused\) Char\,i\.1 Char\,not Kinhill1 Char\,7 Char\,Tabelle Char\,L2 PIP Char\,rp_Heading 7 Char\,SDL title Char\,st Char\,sdl title Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:212.6pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{mso-style-name:"Heading 8\,H8\,Legal Level 1\.1\.1\.\,level2\(a\)\,Heading 8\(unused\)\,Body Text 7\,ad\,h8\,Bullet 1\,8\,L3 PIP\,Lev 8\,Level 1\.1\.1\,Annex\,rp_Heading 8\,Appendix Level 2\,Appendix Level 21\,Appendix Level 22\,Appendix Level 211\,Appendix Level 23\,Appendix Level 212\,ft";
	mso-style-link:"Heading 8 Char\,H8 Char\,Legal Level 1\.1\.1\. Char\,level2\(a\) Char\,Heading 8\(unused\) Char\,Body Text 7 Char\,ad Char\,h8 Char\,Bullet 1 Char\,8 Char\,L3 PIP Char\,Lev 8 Char\,Level 1\.1\.1 Char\,Annex Char\,rp_Heading 8 Char\,Appendix Level 2 Char\,Appendix Level 21 Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:248.05pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{mso-style-name:"Heading 9\,H9\,Legal Level 1\.1\.1\.1\.\,level3\(i\)\,Heading 9\(unused\)\,Body Text 8\,aat\,Bullet 2\,AppendixBodyHead\,Heading 9 \(defunct\)\,Heading 9 Char Char Char Char Char\,Heading 9 Char Char Char Char Char Char\,9\,Lev 9\,Level \(a\)\,Annex1\,Appen 1\,h9\,rp_Heading 9\,App1";
	mso-style-link:"Heading 9 Char\,H9 Char\,Legal Level 1\.1\.1\.1\. Char\,level3\(i\) Char\,Heading 9\(unused\) Char\,Body Text 8 Char\,aat Char\,Bullet 2 Char\,AppendixBodyHead Char\,Heading 9 \(defunct\) Char\,Heading 9 Char Char Char Char Char Char1\,9 Char\,Lev 9 Char\,Level \(a\) Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:283.45pt;
	text-indent:-35.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
	{margin-top:12.0pt;
	margin-right:63.8pt;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc2, li.MsoToc2, div.MsoToc2
	{margin-top:6.0pt;
	margin-right:63.8pt;
	margin-bottom:0cm;
	margin-left:70.9pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc3, li.MsoToc3, div.MsoToc3
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.2pt;
	text-indent:-106.3pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc4, li.MsoToc4, div.MsoToc4
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:33.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc5, li.MsoToc5, div.MsoToc5
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:44.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc6, li.MsoToc6, div.MsoToc6
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:55.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc7, li.MsoToc7, div.MsoToc7
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:66.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc8, li.MsoToc8, div.MsoToc8
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:77.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoToc9, li.MsoToc9, div.MsoToc9
	{margin-top:12.0pt;
	margin-right:63.65pt;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	text-align:justify;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	text-align:justify;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;}
p.MsoCaption, li.MsoCaption, div.MsoCaption
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
span.MsoPageNumber
	{font-family:"Times New Roman",serif;}
p.MsoListBullet, li.MsoListBullet, div.MsoListBullet
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListBullet2, li.MsoListBullet2, div.MsoListBullet2
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:70.9pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListBullet3, li.MsoListBullet3, div.MsoListBullet3
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:106.35pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListBullet4, li.MsoListBullet4, div.MsoListBullet4
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:141.8pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListBullet5, li.MsoListBullet5, div.MsoListBullet5
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.25pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoBodyTextIndent, li.MsoBodyTextIndent, div.MsoBodyTextIndent
	{mso-style-link:"Body Text Indent Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:14.15pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
a:link, span.MsoHyperlink
	{font-family:"Times New Roman",serif;
	color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:purple;
	text-decoration:underline;}
p.MsoDocumentMap, li.MsoDocumentMap, div.MsoDocumentMap
	{mso-style-link:"Document Map Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	background:navy;
	font-size:10.0pt;
	font-family:"Tahoma",sans-serif;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Tahoma",sans-serif;}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.MsoTocHeading, li.MsoTocHeading, div.MsoTocHeading
	{margin:0cm;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;}
p.Indent1, li.Indent1, div.Indent1
	{mso-style-name:"Indent 1";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Indent2, li.Indent2, div.Indent2
	{mso-style-name:"Indent 2";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:70.9pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Indent4, li.Indent4, div.Indent4
	{mso-style-name:"Indent 4";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:141.8pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Indent3, li.Indent3, div.Indent3
	{mso-style-name:"Indent 3";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:106.3pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Indent5, li.Indent5, div.Indent5
	{mso-style-name:"Indent 5";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.25pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.CoverParties, li.CoverParties, div.CoverParties
	{mso-style-name:"Cover Parties";
	margin-top:18.0pt;
	margin-right:70.9pt;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;}
p.CoverTitle, li.CoverTitle, div.CoverTitle
	{mso-style-name:"Cover Title";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	border:none;
	padding:0cm;
	font-size:24.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.DocBackground, li.DocBackground, div.DocBackground
	{mso-style-name:"Doc Background";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DocParties, li.DocParties, div.DocParties
	{mso-style-name:"Doc Parties";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.DocTitle, li.DocTitle, div.DocTitle
	{mso-style-name:"Doc Title";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Schedule, li.Schedule, div.Schedule
	{mso-style-name:Schedule;
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	text-indent:0cm;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.SectionHead, li.SectionHead, div.SectionHead
	{mso-style-name:"Section Head";
	margin-top:30.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;}
p.Schedule1, li.Schedule1, div.Schedule1
	{mso-style-name:"Schedule 1";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Schedule2, li.Schedule2, div.Schedule2
	{mso-style-name:"Schedule 2";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Schedule3, li.Schedule3, div.Schedule3
	{mso-style-name:"Schedule 3";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:70.9pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Schedule4, li.Schedule4, div.Schedule4
	{mso-style-name:"Schedule 4";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:106.3pt;
	text-indent:-35.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Schedule5, li.Schedule5, div.Schedule5
	{mso-style-name:"Schedule 5";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.0cm;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Schedule6, li.Schedule6, div.Schedule6
	{mso-style-name:"Schedule 6";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.2pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.BodyTextIndentChar
	{mso-style-name:"Body Text Indent Char";
	mso-style-link:"Body Text Indent";
	font-family:"Arial",sans-serif;}
span.GadensLogo
	{mso-style-name:"Gadens Logo";
	font-family:"Arial",sans-serif;
	color:#008CA8;}
p.GadensLogoText, li.GadensLogoText, div.GadensLogoText
	{mso-style-name:"Gadens Logo Text";
	margin:0cm;
	background:white;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Arial",sans-serif;}
p.FooterGadens, li.FooterGadens, div.FooterGadens
	{mso-style-name:"Footer Gadens";
	margin:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;}
p.DetailsPage, li.DetailsPage, div.DetailsPage
	{mso-style-name:"Details Page";
	margin:0cm;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Attestation, li.Attestation, div.Attestation
	{mso-style-name:Attestation;
	margin:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.Subheading, li.Subheading, div.Subheading
	{mso-style-name:Subheading;
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Annexure, li.Annexure, div.Annexure
	{mso-style-name:Annexure;
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	text-indent:0cm;
	border:none;
	padding:0cm;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
span.Heading1Char
	{mso-style-name:"Heading 1 Char\,AMW Doc H1 Char\,Section Heading Char\,No numbers Char\,H1 Char\,Title1 Char\,h1 Char\,Head1 Char\,Heading apps Char\,L1 Char\,Level 1 Char\,Appendix Char\,Appendix1 Char\,Appendix2 Char\,Appendix3 Char\,no pg Char\,I1 Char\,1st level Char\,l1 Char\,I Char";
	mso-style-link:"Heading 1\,AMW Doc H1\,Section Heading\,No numbers\,H1\,Title1\,h1\,Head1\,Heading apps\,L1\,Level 1\,Appendix\,Appendix1\,Appendix2\,Appendix3\,no pg\,I1\,1st level\,l1\,Chapter title\,t�tulo1\,H11\,R1\,app heading 1\,ITT t1\,PA Chapter\,II+\,I\,Chapter Heading\,T1\,Heading 1 Char2";
	font-weight:bold;}
span.Heading2Char
	{mso-style-name:"Heading 2 Char\,AMW Doc H2 Char\,body Char\,h2 Char\,H2 Char\,Section Char\,h2\.H2 Char\,1\.1 Char\,UNDERRUBRIK 1-2 Char\,Para2 Char\,h21 Char\,h22 Char\,h2 main heading Char\,2m Char\,h 2 Char\,Subhead A Char\,Attribute Heading 2 Char\,test Char\,l2 Char\,list 2 Char";
	mso-style-link:"Heading 2\,AMW Doc H2\,body\,h2\,H2\,Section\,h2\.H2\,1\.1\,UNDERRUBRIK 1-2\,Para2\,h21\,h22\,h2 main heading\,2m\,h 2\,Subhead A\,Attribute Heading 2\,test\,l2\,list 2\,list 2\,heading 2TOC\,Head 2\,List level 2\,2\,Header 2\,B Sub\/Bold\,B Sub\/Bold1\,B Sub\/Bold2\,B Sub\/Bold11\,H-2";
	font-weight:bold;}
span.Heading3Char
	{mso-style-name:"Heading 3 Char\,AMW Doc H3 \(a\) Char\,3 Char\,H3 Char\,H31 Char\,C Sub-Sub\/Italic Char\,h3 sub heading Char\,Head 3 Char\,Head 31 Char\,Head 32 Char\,C Sub-Sub\/Italic1 Char\,Sub2Para Char\,h3 Char\,\(Alt+3\) Char\,3m Char\,d Char\,Head 33 Char\,C Sub-Sub\/Italic2 Char";
	mso-style-link:"Heading 3\,AMW Doc H3 \(a\)\,3\,H3\,H31\,C Sub-Sub\/Italic\,h3 sub heading\,Head 3\,Head 31\,Head 32\,C Sub-Sub\/Italic1\,Sub2Para\,h3\,\(Alt+3\)\,3m\,d\,Head 33\,C Sub-Sub\/Italic2\,Head 311\,Head 321\,C Sub-Sub\/Italic11\,h31\,Bold Head\,bh\,Level 1 - 2\,Level 1 - 1\,l3\,head3\,h3\.H3\,L3";}
span.Heading4Char
	{mso-style-name:"Heading 4 Char\,Style 31 Char\,h4 Char\,4 Char\,Char Char\,Heading4_Titre4 Char\,H4 Char\,\(Alt+4\) Char\,H41 Char\,\(Alt+4\)1 Char\,H42 Char\,\(Alt+4\)2 Char\,H43 Char\,\(Alt+4\)3 Char\,H44 Char\,\(Alt+4\)4 Char\,H45 Char\,\(Alt+4\)5 Char\,H411 Char\,\(Alt+4\)11 Char\,H421 Char";
	mso-style-link:"Heading 4\,Style 31\,h4\,4\,Char\,Heading4_Titre4\,H4\,\(Alt+4\)\,H41\,\(Alt+4\)1\,H42\,\(Alt+4\)2\,H43\,\(Alt+4\)3\,H44\,\(Alt+4\)4\,H45\,\(Alt+4\)5\,H411\,\(Alt+4\)11\,H421\,\(Alt+4\)21\,H431\,\(Alt+4\)31\,H46\,\(Alt+4\)6\,H412\,\(Alt+4\)12\,H422\,\(Alt+4\)22\,H432\,\(Alt+4\)32\,H47\,\(Alt+4\)7\,H48\,\(Alt+4\)8\,H49";}
span.Heading5Char
	{mso-style-name:"Heading 5 Char\,5 Char\,H5 Char\,Level 3 - i Char\,Dot GS Char1\,level5 Char\,3rd sub-clause Char\,Para5 Char\,h5 Char\,h51 Char\,h52 Char\,L5 Char\,After Paragraph Char\,after paragraph Char\,Heading 5 After Paragraph Char\,Heading 5 Char1 Char\,Para5 Char1 Char";
	mso-style-link:"Heading 5\,5\,H5\,Level 3 - i\,Dot GS\,level5\,3rd sub-clause\,Para5\,h5\,h51\,h52\,L5\,After Paragraph\,after paragraph\,Heading 5 After Paragraph\,Heading 5 Char1\,Heading 5 StGeorge Char\,Para5 Char1\,h5 Char1\,h51 Char1\,h52 Char1\,L5 Char1\,H5 Char1\,Dot GS Char\,A\,\(A\)Tex";}
span.Heading6Char
	{mso-style-name:"Heading 6 Char\,6 Char\,H6 Char\,Legal Level 1\. Char\,dash GS Char\,level6 Char\,a\. Char\,\(I\) Char\,Heading 6\(unused\) Char\,as Char\,not Kinhill Char\,Not Kinhill Char\,h6 Char\,a\.1 Char\,Sub5Para Char\,Heading 6LS Char\,Body Text 5 Char\,sub-dash Char\,sd Char\,\(I\)a Char";
	mso-style-link:"Heading 6\,6\,H6\,Legal Level 1\.\,dash GS\,level6\,a\.\,\(I\)\,Heading 6\(unused\)\,as\,not Kinhill\,Not Kinhill\,h6\,a\.1\,Sub5Para\,Heading 6LS\,Body Text 5\,sub-dash\,sd\,Level 6\,\(I\)a\,L1 PIP\,Name of Org\,Lev 6\,Square Bullet list\,Heading 6 Interstar\,heading 6\,a\,b\,rp_Heading 6";}
span.Heading7Char
	{mso-style-name:"Heading 7 Char\,H7 Char\,Legal Level 1\.1\. Char\,square GS Char\,level1noheading Char\,h7 Char\,ap Char\,i\. Char\,\(1\) Char\,Heading 7\(unused\) Char\,i\.1 Char\,not Kinhill1 Char\,7 Char\,Tabelle Char\,L2 PIP Char\,rp_Heading 7 Char\,SDL title Char\,st Char\,sdl title Char";
	mso-style-link:"Heading 7\,H7\,Legal Level 1\.1\.\,square GS\,level1noheading\,h7\,ap\,i\.\,\(1\)\,Heading 7\(unused\)\,i\.1\,not Kinhill1\,7\,Tabelle\,L2 PIP\,rp_Heading 7\,SDL title\,st\,sdl title\,Subpara 4\,Para no numbering\,letter list\,lettered list\,L1 Heading 7\,ITT t7\,PA Appendix Major";}
span.Heading8Char
	{mso-style-name:"Heading 8 Char\,H8 Char\,Legal Level 1\.1\.1\. Char\,level2\(a\) Char\,Heading 8\(unused\) Char\,Body Text 7 Char\,ad Char\,h8 Char\,Bullet 1 Char\,8 Char\,L3 PIP Char\,Lev 8 Char\,Level 1\.1\.1 Char\,Annex Char\,rp_Heading 8 Char\,Appendix Level 2 Char\,Appendix Level 21 Char";
	mso-style-link:"Heading 8\,H8\,Legal Level 1\.1\.1\.\,level2\(a\)\,Heading 8\(unused\)\,Body Text 7\,ad\,h8\,Bullet 1\,8\,L3 PIP\,Lev 8\,Level 1\.1\.1\,Annex\,rp_Heading 8\,Appendix Level 2\,Appendix Level 21\,Appendix Level 22\,Appendix Level 211\,Appendix Level 23\,Appendix Level 212\,ft";}
span.Heading9Char
	{mso-style-name:"Heading 9 Char\,H9 Char\,Legal Level 1\.1\.1\.1\. Char\,level3\(i\) Char\,Heading 9\(unused\) Char\,Body Text 8 Char\,aat Char\,Bullet 2 Char\,AppendixBodyHead Char\,Heading 9 \(defunct\) Char\,Heading 9 Char Char Char Char Char Char1\,9 Char\,Lev 9 Char\,Level \(a\) Char";
	mso-style-link:"Heading 9\,H9\,Legal Level 1\.1\.1\.1\.\,level3\(i\)\,Heading 9\(unused\)\,Body Text 8\,aat\,Bullet 2\,AppendixBodyHead\,Heading 9 \(defunct\)\,Heading 9 Char Char Char Char Char\,Heading 9 Char Char Char Char Char Char\,9\,Lev 9\,Level \(a\)\,Annex1\,Appen 1\,h9\,rp_Heading 9\,App1";}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
span.DocumentMapChar
	{mso-style-name:"Document Map Char";
	mso-style-link:"Document Map";
	font-family:"Tahoma",sans-serif;
	background:navy;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Tahoma",sans-serif;}
p.single, li.single, div.single
	{mso-style-name:single;
	margin:0cm;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.single1, li.single1, div.single1
	{mso-style-name:"single 1";
	margin:0cm;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.SchedAnnex, li.SchedAnnex, div.SchedAnnex
	{mso-style-name:"Sched\/Annex";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.SchedAnnex1, li.SchedAnnex1, div.SchedAnnex1
	{mso-style-name:"Sched\/Annex 1";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.SchedAnnex2, li.SchedAnnex2, div.SchedAnnex2
	{mso-style-name:"Sched\/Annex 2";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:35.45pt;
	text-indent:-35.45pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.SchedAnnex3, li.SchedAnnex3, div.SchedAnnex3
	{mso-style-name:"Sched\/Annex 3";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:70.9pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.SchedAnnex4, li.SchedAnnex4, div.SchedAnnex4
	{mso-style-name:"Sched\/Annex 4";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:106.3pt;
	text-indent:-35.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.SchedAnnex5, li.SchedAnnex5, div.SchedAnnex5
	{mso-style-name:"Sched\/Annex 5";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:5.0cm;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.SchedAnnex6, li.SchedAnnex6, div.SchedAnnex6
	{mso-style-name:"Sched\/Annex 6";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:177.2pt;
	text-indent:-35.45pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
p.BodyTextIndenta, li.BodyTextIndenta, div.BodyTextIndenta
	{mso-style-name:"Body Text Indent \(a\)";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:70.9pt;
	line-height:13.5pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-weight:bold;}
p.Xure1, li.Xure1, div.Xure1
	{mso-style-name:Xure_1;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:42.55pt;
	text-indent:-42.55pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.Xure2, li.Xure2, div.Xure2
	{mso-style-name:Xure_2;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:42.55pt;
	text-indent:-42.55pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Xure3, li.Xure3, div.Xure3
	{mso-style-name:Xure_3;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:3.0cm;
	text-indent:-42.5pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.Xure4, li.Xure4, div.Xure4
	{mso-style-name:Xure_4;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:127.6pt;
	text-indent:-42.55pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.Xure5, li.Xure5, div.Xure5
	{mso-style-name:Xure_5;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:6.0cm;
	text-indent:-42.5pt;
	line-height:115%;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
span.spanh6
	{mso-style-name:spanh6;}
p.BodyText6, li.BodyText6, div.BodyText6
	{mso-style-name:"Body Text 6";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:144.0pt;
	text-align:justify;
	font-size:9.0pt;
	font-family:"Frutiger 45 Light";}
p.IndentParaLevel1, li.IndentParaLevel1, div.IndentParaLevel1
	{mso-style-name:IndentParaLevel1;
	mso-style-link:"IndentParaLevel1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:1.0cm;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;}
span.IndentParaLevel1Char
	{mso-style-name:"IndentParaLevel1 Char";
	mso-style-link:IndentParaLevel1;
	font-family:"Times New Roman",serif;}
p.MELegal1, li.MELegal1, div.MELegal1
	{mso-style-name:"ME Legal 1";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:34.0pt;
	text-indent:-34.0pt;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	letter-spacing:-.5pt;
	text-decoration:underline;}
p.MELegal2, li.MELegal2, div.MELegal2
	{mso-style-name:"ME Legal 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:34.0pt;
	text-align:justify;
	text-indent:-34.0pt;
	font-size:9.0pt;
	font-family:"Arial Narrow",sans-serif;}
p.MELegal3, li.MELegal3, div.MELegal3
	{mso-style-name:"ME Legal 3";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:68.05pt;
	text-indent:-34.05pt;
	font-size:9.0pt;
	font-family:"Arial Narrow",sans-serif;}
p.MELegal4, li.MELegal4, div.MELegal4
	{mso-style-name:"ME Legal 4\,l4";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:102.05pt;
	text-indent:-34.0pt;
	font-size:9.0pt;
	font-family:"Arial Narrow",sans-serif;}
p.MELegal5, li.MELegal5, div.MELegal5
	{mso-style-name:"ME Legal 5\,l5";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:136.1pt;
	text-indent:-34.05pt;
	font-size:9.0pt;
	font-family:"Arial Narrow",sans-serif;}
p.MELegal6, li.MELegal6, div.MELegal6
	{mso-style-name:"ME Legal 6";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:6.0cm;
	text-indent:-34.0pt;
	font-size:9.0pt;
	font-family:"Arial Narrow",sans-serif;}
span.MELegal2Char
	{mso-style-name:"ME Legal 2 Char";
	font-family:"Arial Narrow",sans-serif;}
span.MELegal1Char
	{mso-style-name:"ME Legal 1 Char";
	font-family:"Arial Narrow",sans-serif;
	text-transform:uppercase;
	text-decoration:underline;}
p.Level2, li.Level2, div.Level2
	{mso-style-name:"Level 2";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-18.0pt;
	line-height:130%;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"Verdana",sans-serif;}
p.Level3, li.Level3, div.Level3
	{mso-style-name:"Level 3";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:54.0pt;
	text-align:justify;
	text-indent:-18.0pt;
	line-height:130%;
	text-autospace:none;
	font-size:10.0pt;
	font-family:"Verdana",sans-serif;}
p.Level4, li.Level4, div.Level4
	{mso-style-name:"Level 4";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.5pt;
	margin-left:106.3pt;
	text-align:justify;
	text-indent:-35.45pt;
	line-height:110%;
	font-size:10.5pt;
	font-family:"Arial",sans-serif;}
p.TableText, li.TableText, div.TableText
	{mso-style-name:TableText;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;}
p.08-Agreements-05-Clause-Indent, li.08-Agreements-05-Clause-Indent, div.08-Agreements-05-Clause-Indent
	{mso-style-name:"08 - Agreements - 05 - Clause - Indent";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
span.08-Agreements-00-00-NormalBoldUnderline
	{mso-style-name:"08 - Agreements - 00 - 00 - Normal Bold Underline";
	font-family:"Arial",sans-serif;
	font-weight:bold;
	text-decoration:underline;}
p.08-Agreements-01-Heading1, li.08-Agreements-01-Heading1, div.08-Agreements-01-Heading1
	{mso-style-name:"08 - Agreements - 01 - Heading 1";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.08-Agreements-02-Heading2, li.08-Agreements-02-Heading2, div.08-Agreements-02-Heading2
	{mso-style-name:"08 - Agreements - 02 - Heading 2";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.08-Agreements-03-Heading3, li.08-Agreements-03-Heading3, div.08-Agreements-03-Heading3
	{mso-style-name:"08 - Agreements - 03 - Heading 3";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	text-decoration:underline;}
p.08-Agreements-04-Clause, li.08-Agreements-04-Clause, div.08-Agreements-04-Clause
	{mso-style-name:"08 - Agreements - 04 - Clause";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-06-Clause-Sub, li.08-Agreements-06-Clause-Sub, div.08-Agreements-06-Clause-Sub
	{mso-style-name:"08 - Agreements - 06 - Clause - Sub";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-06-Clause-SubSub, li.08-Agreements-06-Clause-SubSub, div.08-Agreements-06-Clause-SubSub
	{mso-style-name:"08 - Agreements - 06 - Clause - Sub Sub";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-07-Clause-SubSubSub, li.08-Agreements-07-Clause-SubSubSub, div.08-Agreements-07-Clause-SubSubSub
	{mso-style-name:"08 - Agreements - 07 - Clause - Sub Sub Sub";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:72.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-08-Clause-SubSubSubSub, li.08-Agreements-08-Clause-SubSubSubSub, div.08-Agreements-08-Clause-SubSubSubSub
	{mso-style-name:"08 - Agreements - 08 - Clause - Sub Sub Sub Sub";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:106.95pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-09-Clause-SubSubSubSubSub, li.08-Agreements-09-Clause-SubSubSubSubSub, div.08-Agreements-09-Clause-SubSubSubSubSub
	{mso-style-name:"08 - Agreements - 09 - Clause - Sub Sub Sub Sub Sub";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:144.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2101-Appendix-Heading1, li.08-Agreements-2101-Appendix-Heading1, div.08-Agreements-2101-Appendix-Heading1
	{mso-style-name:"08 - Agreements - 2101 - Appendix -  Heading 1";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	text-transform:uppercase;
	font-weight:bold;}
p.08-Agreements-2102-Appendix-Heading2, li.08-Agreements-2102-Appendix-Heading2, div.08-Agreements-2102-Appendix-Heading2
	{mso-style-name:"08 - Agreements - 2102 - Appendix - Heading 2";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.08-Agreements-2103-Appendix-Heading3, li.08-Agreements-2103-Appendix-Heading3, div.08-Agreements-2103-Appendix-Heading3
	{mso-style-name:"08 - Agreements - 2103 - Appendix - Heading 3";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;
	text-decoration:underline;}
p.08-Agreements-2199-Appendix-Indent, li.08-Agreements-2199-Appendix-Indent, div.08-Agreements-2199-Appendix-Indent
	{mso-style-name:"08 - Agreements - 2199 - Appendix - Indent";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:0cm;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2200-Appendix-Lev1, li.08-Agreements-2200-Appendix-Lev1, div.08-Agreements-2200-Appendix-Lev1
	{mso-style-name:"08 - Agreements - 2200 - Appendix - Lev 1";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2201-Appendix-Lev2, li.08-Agreements-2201-Appendix-Lev2, div.08-Agreements-2201-Appendix-Lev2
	{mso-style-name:"08 - Agreements - 2201 - Appendix - Lev 2";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2202-Appendix-Lev3, li.08-Agreements-2202-Appendix-Lev3, div.08-Agreements-2202-Appendix-Lev3
	{mso-style-name:"08 - Agreements - 2202 - Appendix - Lev 3";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2203-Appendix-Lev4, li.08-Agreements-2203-Appendix-Lev4, div.08-Agreements-2203-Appendix-Lev4
	{mso-style-name:"08 - Agreements - 2203 - Appendix - Lev 4";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:72.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.08-Agreements-2204-Appendix-Lev5, li.08-Agreements-2204-Appendix-Lev5, div.08-Agreements-2204-Appendix-Lev5
	{mso-style-name:"08 - Agreements - 2204 - Appendix - Lev 5";
	margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:108.0pt;
	text-align:justify;
	text-indent:-36.0pt;
	font-size:11.0pt;
	font-family:"Arial",sans-serif;}
p.REDDocumentTitle, li.REDDocumentTitle, div.REDDocumentTitle
	{mso-style-name:"RED Document Title";
	margin:0cm;
	text-align:justify;
	text-indent:0cm;
	font-size:24.0pt;
	font-family:"Corbel",sans-serif;
	color:#C0504D;}
p.REDDocumentSubtitle, li.REDDocumentSubtitle, div.REDDocumentSubtitle
	{mso-style-name:"RED Document Subtitle";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.5pt;
	margin-left:0cm;
	text-align:justify;
	text-indent:0cm;
	font-size:20.0pt;
	font-family:"Corbel",sans-serif;
	color:white;}
p.REDDocumentSubtitleCxSpFirst, li.REDDocumentSubtitleCxSpFirst, div.REDDocumentSubtitleCxSpFirst
	{mso-style-name:"RED Document SubtitleCxSpFirst";
	margin:0cm;
	text-align:justify;
	text-indent:0cm;
	font-size:20.0pt;
	font-family:"Corbel",sans-serif;
	color:white;}
p.REDDocumentSubtitleCxSpMiddle, li.REDDocumentSubtitleCxSpMiddle, div.REDDocumentSubtitleCxSpMiddle
	{mso-style-name:"RED Document SubtitleCxSpMiddle";
	margin:0cm;
	text-align:justify;
	text-indent:0cm;
	font-size:20.0pt;
	font-family:"Corbel",sans-serif;
	color:white;}
p.REDDocumentSubtitleCxSpLast, li.REDDocumentSubtitleCxSpLast, div.REDDocumentSubtitleCxSpLast
	{mso-style-name:"RED Document SubtitleCxSpLast";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:10.5pt;
	margin-left:0cm;
	text-align:justify;
	text-indent:0cm;
	font-size:20.0pt;
	font-family:"Corbel",sans-serif;
	color:white;}
p.REDAnnexureHeading61, li.REDAnnexureHeading61, div.REDAnnexureHeading61
	{mso-style-name:"RED Annexure Heading 6 \(1\)";
	margin-top:6.5pt;
	margin-right:0cm;
	margin-bottom:10.5pt;
	margin-left:177.2pt;
	text-indent:-35.45pt;
	font-size:10.5pt;
	font-family:"Corbel",sans-serif;
	color:black;}
p.REDNormalBodyText, li.REDNormalBodyText, div.REDNormalBodyText
	{mso-style-name:"RED Normal Body Text";
	margin-top:10.5pt;
	margin-right:0cm;
	margin-bottom:10.5pt;
	margin-left:0cm;
	text-align:justify;
	font-size:10.5pt;
	font-family:"Corbel",sans-serif;}
.MsoChpDefault
	{font-size:10.0pt;
	font-family:"Arial",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:63.8pt 70.9pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-AU link=blue vlink=purple style='word-wrap:break-word'>

<div class=WordSection1>

<p class=REDDocumentTitle style='margin-left:0cm;text-indent:0cm'><a
name="_Toc114999471"></a><a name="_Toc114999477"></a><a name="_Toc114999483"></a><a
name="_Toc114999489"></a><a name="_Toc114999493"><span style='color:#007DFF'>Tandm
Compliance Pty Ltd</span></a></p>

<p class=REDDocumentSubtitle style='margin-left:0cm;text-indent:0cm'><span
style='color:windowtext'>End User Licence Agreement</span></p>

<p class=REDDocumentTitle style='margin-left:0cm;text-indent:0cm'><span
style='font-size:10.5pt;color:windowtext'>Last modified:3 December 2021</span></p>

<p class=SectionHead><span style='font-family:"Calibri",sans-serif'>Parties</span></p>

<p class=DocParties><a name=Parties2></a><span style='font-family:"Calibri",sans-serif'>1.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Tandm
Compliance Pty Ltd (Tandm) </span></b><span style='font-family:"Calibri",sans-serif'>ABN
47 652 350 055 of Level 10, 12 Creek Street, Brisbane, QLD 4000 (<b>Tandm or</b>
<b>Publisher</b>); and</span></p>

<p class=DocParties><span style='font-family:"Calibri",sans-serif'>2.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The client</span><b><span
style='font-family:"Calibri",sans-serif'> </span></b><span style='font-family:
"Calibri",sans-serif'>as identified in the purchase order/invoice for the Tandm
software (</span><b><span style='font-family:"Calibri",sans-serif'>Client</span></b><span
style='font-family:"Calibri",sans-serif'>).</span></p>

<p class=SectionHead><span style='font-family:"Calibri",sans-serif'>Tandm Disclaimer</span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>BY SELECTING
THE &quot;ACCEPT&quot; (OR SIMILAR) BUTTON BELOW THIS AGREEMENT OR BY
DOWNLOADING, INSTALLING, ACCESSING, COPYING OR OTHERWISE USING ALL OR ANY
PORTION OF THIS PRODUCT (&quot;PRODUCT&quot;), YOU AGREE TO BE LEGALLY BOUND BY
THIS AGREEMENT, EITHER PERSONALLY OR ON BEHALF OF YOUR COMPANY OR OTHER LEGAL
ENTITY FOR WHICH YOU ARE ACQUIRING THE PRODUCT. IF YOU DO NOT AGREE TO THE
TERMS OF THIS AGREEMENT OR DO NOT WISH TO BIND YOURSELF OR THE ENTITY YOU
REPRESENT: (A) DO NOT DOWNLOAD, INSTALL, ACCESS, COPY, UPLOAD, OR OTHERWISE USE
THE PRODUCT; AND (B) SELECT THE &quot;REJECT&quot; (OR SIMILAR) BUTTON BELOW
THIS AGREEMENT, WHICH WILL CANCEL THE DOWNLOAD OF THE PRODUCT.DOWNLOADING,
INSTALLING, ACCESSING, COPYING, UPLOADING, OR OTHERWISE USING THE PRODUCT,
EXCEPT AS PERMITTED BY THIS AGREEMENT, IS UNAUTHORIZED AND CONSTITUTES A
MATERIAL BREACH OF THIS AGREEMENT AND AN INFRINGEMENT OF THE COPYRIGHT AND
OTHER INTELLECTUAL PROPERTY RIGHTS IN SUCH PRODUCT AND MAY SUBJECT YOU TO
LIABLITY FOR DAMAGES AND CRIMINAL PENALTIES. </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>1. If you are
downloading, installing, accessing, copying, uploading, or otherwise using a
Product provided or authored by <b>Tandm Compliance Pty Ltd ABN 47 652 350 055
(Tandm)</b>:</span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(a) This
Agreement is solely between you and Tandm ("Publisher"); </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(b) You
create a direct relationship with Publisher under the terms of this Agreement.</span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(c) Your use
of the Product is governed by the terms of this Agreement. </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(d) Your
personal data (e.g., first name, last name, email address) will be made
available to the Publisher for fulfillment purposes; and </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(e) Personal
data collected through the Product is subject to the Publisher's privacy
policy.</span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>2. In
addition to the foregoing, the Publisher, is solely responsible for the
Product, including, without limitation, for: </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(a) Any and
all content, services or subscriptions provided through or in connection with
the Product; </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>b) Any and
all maintenance and support services, as specified in this Agreement (if any)
or as required under applicable laws, rules, regulations, and judicial and
governmental orders (individually and collectively, "Applicable Laws"); </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(c) Any
notices and consents required to be given to end users regarding data
collection, use, processing and/or storage (if any) by product under Applicable
Laws (including without limitation, privacy/data protection laws); </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(d) Any and
all warranties, whether express or implied by law, to the extent not
effectively disclaimed in this Agreement, or other claims, losses, liabilities,
damages, costs or expenses attributable to any failure to conform to any
warranty; and </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>(e) The
investigation, defence, settlement and discharge of any claims by you or any
third party in any way relating to the Product, including, but not limited to,
for (i) product liability, (ii) noncompliance with any Applicable Laws
(including, without limitation, consumer protection and privacy/data protection
laws), and (iii) infringement of third party's intellectual property rights. </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>3. TANDM WILL
NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER ARISING FROM OR RELATING TO YOUR USE
OR INABILITY TO USE THE PRODUCT. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN
BY TANDM SHALL CREATE A WARRANTY. SHOULD THE PRODUCT PROVE DEFECTIVE, YOU
ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. Some
states and jurisdictions do not allow the exclusion of implied warranties or
limitations on applicable statutory rights of a consumer, so the above limitations
and exclusions may not apply to you. </span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>4. UNDER NO
CIRCUMSTANCES WILL TANDM BE LIABLE TO YOU FOR ANY CLAIM FOR ANY DIRECT,
INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY DAMAGES, OR
ANY OTHER DAMAGES WHATSOEVER (INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS,
LOSS OF REVENUE, LOSS OF USE, LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER
COMMERCIAL OR ECONOMIC LOSS OF ANY KIND), NOR SHALL TANDM BE LIABLE FOR ANY
DAMAGES WHATSOEVER ARISING FROM OR RELATING TO YOUR USE OR INABILITY TO USE THE
PRODUCT, EVEN IF ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF WHETHER
THE LIMITED REMEDIES IN THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE. IN
ADDITION, THE SOLE AND EXCLUSIVE LIABILITY OF TANDM FOR ANY CLAIM UNDER THIS
AGREEMENT, WHETHER BASED ON CONTRACT, WARRANTY, NEGLIGENCE, TORT, OR OTHERWISE,
WILL NOT EXCEED THE AMOUNT PAID OR PAYABLE BY YOU (IF ANY) FOR SUCH PRODUCT.
Some states and jurisdictions do not allow the exclusion of limitation of
incidental or consequential damages, so the above limitation and exclusions may
not apply to you.</span></p>

<p class=MsoNormal><span style='font-family:"Calibri",sans-serif'>5. You
acknowledge that Tandm is a third-party beneficiary of the EULA and that upon
your acceptance of the terms of the EULA, Tandm will have the right to enforce
the EULA against you as a third-party beneficiary thereof. 6. Questions,
complaints or claims with respect to the Product should be directed solely to
the Publisher.</span></p>

<p class=SectionHead><span style='font-family:"Calibri",sans-serif'>Introduction</span></p>

<p class=DocBackground><span style='font-family:"Calibri",sans-serif'>A.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>This agreement
contains the terms under which Tandm will supply the Software to the Client and
will govern the Client's access to and use of the Software. </span></p>

<p class=DocBackground><span style='font-family:"Calibri",sans-serif'>B.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client agrees
to the terms in this agreement when the Client clicks the "I accept" tickbox.
In clicking the "I accept" tickbox the Client warrants that it has reviewed and
accepted the terms in this agreement, including Tandm's privacy policy located
at </span><a href="https://privacypolicy.tandm.ai/"><span style='font-family:
"Calibri",sans-serif'>https://privacypolicy.tandm.ai/</span></a><span
style='font-family:"Calibri",sans-serif;background:yellow'> </span></p>

<p class=DocBackground style='margin-left:36.0pt;text-indent:-36.0pt'><span
style='font-family:"Calibri",sans-serif'>C.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The representative
of the Client that is agreeing to the terms of this agreement represents and
warrants that it has the authority to enter into this agreement on behalf of
the Client.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc111004674"></a><a name="_Toc111007050"></a><a
name="_Ref111277852"></a><a name="_Toc114999467"></a><a name="_Ref167763706"></a><a
name="_Toc512508708"></a><a name="_Toc521499600">1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Definitions and
interpretation</span></a></h1>

</div>

<h2><a name="_Toc521499601"><span style='font-family:"Calibri",sans-serif'>1.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Definitions</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>In this agreement
the following definitions apply unless the context requires otherwise:</span></p>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Affiliate </span></b><span
style='font-family:"Calibri",sans-serif'>means:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a corporation that
is a related entity of a party as defined in section 9 of the <i>Corporations
Act 2001</i> (Cth); or</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any individual
who, or any corporation or other form of business organisation which, in any
country directly or indirectly (including through intermediaries), is
Controlled by, or is under common Control with, or Controls, a party.</span></h3>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Business Day </span></b><span style='font-family:"Calibri",sans-serif'>means
a day that is not a Saturday, Sunday or public holiday in the place where the
act is to be performed or a Notice issued.</span></p>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Business
Hours </span></b><span style='font-family:"Calibri",sans-serif'>means 9 am to 5
pm local Brisbane time on Business Days in Brisbane, in the state of
Queensland, Australia. </span></p>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Confidential
Information </span></b><span style='font-family:"Calibri",sans-serif'>means all
know-how, financial information and other proprietary information in whatever
form including unpatented inventions, trade secrets, formulae, discoveries,
works, improvements, innovations, ideas, concepts, graphs, drawings, designs
samples, devices, models and other materials of whatever description and
howsoever documented, recorded or disclosed, which a party claims as
confidential to itself or to a third party to whom it owes a duty of
confidentiality and which is within its control. </span></p>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Information or
material is not Confidential Information for the purposes of this agreement if
it:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>is in the public
domain prior to its disclosure to a party by the other party;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>enters the public
domain other than as a result of an unauthorised disclosure;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>is disclosed to
the recipient party by a Third Party who is lawfully entitled to disclose such
information or material to the recipient party on a non-confidential basis; or</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>is rightfully
known by the recipient party prior to the date of its disclosure by the other party,
as evidenced by the recipient's contemporaneous written records.</span></h3>

<p class=MsoBodyTextIndent style='margin-left:35.45pt'><span style='font-family:
"Calibri",sans-serif'>Information is not considered to be in the public domain
for the purposes of this agreement unless it is lawfully available to the
general public from a single source without restriction on its use or
disclosure.</span></p>

<p class=Indent1 style='page-break-after:avoid'><b><span style='font-family:
"Calibri",sans-serif'>Control</span></b><span style='font-family:"Calibri",sans-serif'>
means:</span></p>

<h3 style='page-break-after:avoid'><span style='font-family:"Calibri",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the ability to
cast or control the casting of at least 50% of the maximum number of votes that
might be cast at any general meeting (or equivalent) of an entity;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the holding of at
least 50% of the issued ordinary share capital, the equity, or other ownership
interest, in the entity, or the holding of the maximum ownership interest
permitted in the country where the entity exists; or</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the ability of a
person or persons to direct, or share equally in the direction of, the
composition of the board of directors (or equivalent) of the entity, or to
manage the entity pursuant to an agreement.</span></h3>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Controller </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Data Subject </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>EEA </span></b><span style='font-family:"Calibri",sans-serif'>means
the European Economic Area. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Effective Date </span></b><span style='font-family:"Calibri",sans-serif'>means
the date the Client establishes its account on the Software.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Fees </span></b><span style='font-family:"Calibri",sans-serif'>means
the fees for the Software as specified in the relevant submission.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Force Majeure Event </span></b><span style='font-family:
"Calibri",sans-serif'>means any event which is beyond a party's control and
that is preventing its performance under this agreement (including war, riot,
natural disaster, labour dispute, or law taking effect after the Effective
Date).</span></p>

<p class=MsoNormal style='text-indent:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>GDPR </span></b><span style='font-family:"Calibri",sans-serif'>means
the General Data Protection Regulation (EU) 2016/679.</span></p>

<p class=MsoNormal style='text-indent:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Government Agency</span></b><span style='font-family:
"Calibri",sans-serif'> means:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a government,
whether foreign, federal, state, territorial or local;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a department,
office or minister of a government acting in that capacity; or</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a commission,
delegate, instrumentality, agency, board or other governmental,
semi-governmental, judicial, administrative, monetary or fiscal authority
whether statutory or not.</span></h3>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Incident</span></b><span
style='font-family:"Calibri",sans-serif'> means any loss in functionality of the
Software.</span></p>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Insolvency
Event </span></b><span style='font-family:"Calibri",sans-serif'>means in
relation to a party, the occurrence of any one or more of these events in
relation to that party:</span></p>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span style='font-family:
"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the party ceases
or threatens to cease to carry on its business;</span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span style='font-family:
"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>the party
ceases to (or is unable to) pay its creditors (or any class of them) in the ordinary
course of business, or announces its intention to do so; </span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span style='font-family:
"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>a
receiver, receiver and manager, administrator, liquidator or similar officer is
appointed to that party or any of its assets;</span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span style='font-family:
"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>the party
enters into, or resolves to enter into, a scheme or arrangement, compromise or
composition with any class of creditors;</span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span lang=EN
style='font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>a
resolution is passed or an application to a court is taken for the winding up,
dissolution, official management or administration of that party; </span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span lang=EN
style='font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>the party
or the party's property or undertakings become subject to a personal insolvency
arrangement or a debt agreement under the <i>Bankruptcy Act 1966 </i>(Cth) or</span></h3>

<h3 style='margin-left:70.9pt;text-indent:-35.45pt'><span style='font-family:
"Calibri",sans-serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang=EN style='font-family:"Calibri",sans-serif'>anything
having a substantially similar effect to any of the events specified above
happens under the law of any applicable jurisdiction.</span></h3>

<p class=SchedAnnex2 style='text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>Intellectual
Property Rights </span><span style='font-family:"Calibri",sans-serif;
font-weight:normal'>means all industrial and intellectual property rights
throughout the world, whether registered or unregistered, including without
limitation all copyright, future copyright, patent, designs and trade marks,
circuit layout rights, and all rights and interests in or relating to domain
names, business names and company names, and all applications and rights in
relation to registration of such rights, but does not include Moral Rights.</span><span
style='font-family:"Calibri",sans-serif'> </span></p>

<p class=Indent1><b><span style='font-family:"Calibri",sans-serif'>Law </span></b><span
style='font-family:"Calibri",sans-serif;color:black'>means any applicable
statute, regulation, by law, ordinance or subordinate legislation in force from
time to time anywhere in Australia or such other jurisdictions in which the
Client is based or operates, whether made by any level or arm of government,
and includes the common law as applicable from time to time. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Moral Rights </span></b><span style='font-family:"Calibri",sans-serif'>means
the right of integrity of authorship, the right of attribution of authorship
and the right not to have authorship falsely attributed, more particularly as
conferred by the <i>Copyright Act 1968 </i>(Cth), and the rights of a similar
nature anywhere in the world.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Notice </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in clause </span><span
style='font-family:"Calibri",sans-serif'>17.1</span><span style='font-family:
"Calibri",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Operating Environment </span></b><span style='font-family:
"Calibri",sans-serif'>means the Client's equipment, software, database,
information technology or communication system or other communication and/or
information technology infrastructure required to access and use the Software. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Personal Data </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Personal Data Breach </span></b><span style='font-family:
"Calibri",sans-serif'>has the meaning given to that term in the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Personnel </span></b><span style='font-family:"Calibri",sans-serif'>means
the officers, employees, agents and contractors of a party.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Privacy Law </span></b><span style='font-family:"Calibri",sans-serif'>means
the <i>Privacy Act 1988 </i>(Cth) o</span><span style='font-family:"Calibri",sans-serif'>r
an applicable law on personal data including the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Processed Personal Data </span></b><span
style='font-family:"Calibri",sans-serif'>has the meaning given to that term in
clause </span><span
style='font-family:"Calibri",sans-serif'>8.1</span><span style='font-family:
"Calibri",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Processing </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in the GDPR and </span><b><span
style='font-family:"Calibri",sans-serif'>"Processes"</span></b><span
style='font-family:"Calibri",sans-serif'> has a similar meaning. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Processor</span></b><span style='font-family:"Calibri",sans-serif'>
has the meaning given to that term in the GDPR. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Resolution Institute </span></b><span style='font-family:
"Calibri",sans-serif'>means the Resolution Institute ABN 69 008 651 232.</span></p>

<p class=08-Agreements-05-Clause-Indent style='margin-top:12.0pt'><b><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif'>Software </span></b><span
style='font-size:10.0pt;font-family:"Calibri",sans-serif'>means Tandm's Auditing
and Compliance building services design software.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Standard Contractual Clauses </span></b><span
style='font-family:"Calibri",sans-serif'>means</span><b><span style='font-family:
"Calibri",sans-serif'> </span></b><span style='font-family:"Calibri",sans-serif'>the</span><b><span
style='font-family:"Calibri",sans-serif'> </span></b><i><span style='font-family:
"Calibri",sans-serif;color:black;background:white'>2010/87/: Commission
Decision of 5 February 2010 on standard contractual clauses for the transfer of
personal data to processors established in third countries under Directive
95/46/EC of the European Parliament and of the Council </span></i><span
style='font-family:"Calibri",sans-serif;color:black;background:white'>(notified
under document C (2010) 593).&nbsp;</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Sub-Processor </span></b><span style='font-family:"Calibri",sans-serif'>has
the meaning given to that term in clause </span><span
style='font-family:"Calibri",sans-serif'>8.7(a)</span><span style='font-family:
"Calibri",sans-serif'>.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Subscription Period </span></b><span style='font-family:
"Calibri",sans-serif'>means a 12 month period commencing on:</span></p>

<p class=08-Agreements-07-Clause-SubSubSub><span style='font-size:10.0pt;
font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Calibri",sans-serif'>the
Effective Date; and</span></p>

<p class=08-Agreements-07-Clause-SubSubSub><span style='font-size:10.0pt;
font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.0pt;font-family:"Calibri",sans-serif'>the
day immediately following the expiry of the immediately preceding Subscription
Period.</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Term </span></b><span style='font-family:"Calibri",sans-serif'>means<b>
</b>the period of time contemplated by clause </span><span
style='font-family:"Calibri",sans-serif'>2</span><span style='font-family:"Calibri",sans-serif'>.
</span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>Third Party </span></b><span style='font-family:"Calibri",sans-serif'>means
an entity that is not Tandm or the Client. </span></p>

<p class=MsoNormal style='margin-left:35.45pt'><b><span style='font-family:
"Calibri",sans-serif'>User </span></b><span style='font-family:"Calibri",sans-serif'>means
the Personnel authorised by the Client to access the Software. </span></p>

<h2><a name="_Toc521499602"></a><a name="_Toc517773946"></a><span
style='font-family:"Calibri",sans-serif'>1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Interpretation</span></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>In this agreement,
unless the context requires otherwise:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the singular
includes the plural and the plural includes the singular.Words importing a
gender include every other gender;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a provision given
by more than one person or party binds them jointly and severally.A provision
in favour of more than one person or party is for their joint and several
benefit.A reference to more than one person or party is to all or any of
those persons or parties;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to a
thing includes the whole or any part of that thing;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>if a word or
phrase is defined, its other grammatical forms have a corresponding meaning;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a covenant not to
do anything includes an obligation not to permit that thing to be done, and to
use reasonable endeavours to prevent that thing being done by a third party;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to a "<b>party</b>"
includes the party to this agreement and their executors, administrators,
successors (including any entity resulting from a permitted amalgamation),
permitted assigns and substitutes;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to a "<b>person</b>"
or a word denoting an individual, person, firm, partnership, association
(whether or not incorporated), corporation, authority, government, Government
Agency or any other body or entity (in each case whether or not having separate
legal personality), includes any of them;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to any
agreement or document is to that agreement or document as amended, novated,
replaced or supplemented from time to time;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>one clause does
not limit the effect of another;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to a
part, clause, annexure, exhibit, appendix or schedule is a reference to a part
of, clause of, an annexure, exhibit, appendix or schedule to this agreement and
a reference to this agreement includes any annexure, exhibit, appendix and
schedule;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(k)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to any
legislation or to any provision of any legislation includes any modification or
re-enactment of it, any legislative provision substituted for it and any
regulations and statutory instruments or orders issued under it;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(l)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to
conduct includes any omission, representation, statement or undertaking,
whether or not in writing;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(m)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>specifying
anything in this agreement after the words "<b>including</b>", "<b>includes</b>",
"<b>such</b> <b>as</b>", "<b>for</b> <b>example</b>" or similar expressions
does not limit what else might be included unless there is express wording to
the contrary;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(n)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a reference to any
thing (including any amount) is a reference to the whole or each part of it and
a reference to a group of persons is a reference to any one or more of them;
and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(o)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>if the day on
which:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>anything, other
than a payment, is to be done is not a Business Day, that thing shall be done
on the preceding Business Day; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a payment is to be
made is not a Business Day it shall be made on the next Business Day but if the
next Business Day falls in the next calendar month it shall be made on the
preceding Business Day; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>an act, other than
a payment or the giving of a communication, is required to be done on a
particular day and the act is done after 5 pm on that day, it will be deemed to
have been done on the following day.</span></h4>

<h2><a name="_Toc521499603"><span style='font-family:"Calibri",sans-serif'>1.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Headings</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>In
this agreement headings are for ease of reference only and do not affect the
meaning of this agreement and do not form part of a clause or schedule. </span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref455749105"></a><a name="_Toc472682283"></a><a
name="_Toc521499604"></a><a name="_Ref43283891">2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Term</span></a></h1>

</div>

<p class=MsoNormal style='margin-left:35.45pt'><span style='font-family:"Calibri",sans-serif'>This
agreement will commence on the Effective Date and continue for the Subscription
Period and thereafter for further Subscription Periods unless terminated
earlier in accordance with clause </span><span
style='font-family:"Calibri",sans-serif'>14</span><span style='font-family:
"Calibri",sans-serif'>. </span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Software</span></h1>

</div>

<h2><a name="_Toc521416582"></a><span style='font-family:"Calibri",sans-serif'>3.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Software</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm grants the
Client access to use the Software for the Term in accordance with this
agreement. </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The parties
acknowledge that the Client is solely responsible for establishing, operating
and maintaining, at its expense all computer hardware and software, equipment,
electronic systems, networks and web browsers necessary to use the Software. </span></h3>

<h2><a name="_Toc521499608"></a><a name="_Ref516849420"></a><a
name="_Toc521416584"></a><a name="_Toc517773952"></a><a name="_Toc517774971"></a><a
name="_Toc517786405"></a><a name="_Toc517773953"></a><a name="_Toc517774972"></a><a
name="_Toc517786406"></a><a name="_Toc517259088"></a><a name="_Toc517266897"></a><a
name="_Toc517267112"></a><a name="_Toc517267328"></a><a name="_Toc517259094"></a><a
name="_Toc517266903"></a><a name="_Toc517267118"></a><a name="_Toc517267334"></a><span
style='font-family:"Calibri",sans-serif'>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Changes to</span><span
style='font-family:"Calibri",sans-serif'> the </span><span style='font-family:
"Calibri",sans-serif'>Software</span></h2>

<h3><a name="_Ref516849423"><span style='font-family:"Calibri",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>During the Term Tandm
may change or update the configuration of the Software to reflect changes in
the Laws, technology, industry practices or in response to the Client's use of the
Software. </span></a></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Updates to the Software
pursuant to clause </span><span
style='font-family:"Calibri",sans-serif'>3.2(a)</span><span style='font-family:
"Calibri",sans-serif'> will not materially reduce the level of performance,
functionality, security or availability of the Software during the Term. </span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc521499609"></a><a name="_Toc517207523"></a><a
name="_Toc517207690"></a><a name="_Toc517207524"></a><a name="_Toc517207691"></a><a
name="_Toc517266931"></a><a name="_Toc517267146"></a><a name="_Toc517267362"></a><a
name="_Toc517266933"></a><a name="_Toc517267148"></a><a name="_Toc517267364"></a><a
name="_Toc517266936"></a><a name="_Toc517267151"></a><a name="_Toc517267367"></a><a
name="_Toc517266937"></a><a name="_Toc517267152"></a><a name="_Toc517267368"></a><a
name="_Toc517266938"></a><a name="_Toc517267153"></a><a name="_Toc517267369"></a><a
name="_Toc517266940"></a><a name="_Toc517267155"></a><a name="_Toc517267371"></a><a
name="_Toc517267166"></a><a name="_Toc517267382"></a>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Software access</span></h1>

</div>

<h2><a name="_Toc521499610"><span style='font-family:"Calibri",sans-serif'>4.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Users</span></a></h2>

<h3><span style='font-family:"Calibri",sans-serif'>Users will be able to access
the Software with their unique User account credentials.</span></h3>

<h2><a name="_Toc521499612"><span style='font-family:"Calibri",sans-serif'>4.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Compliance</span></a><span
style='font-family:"Calibri",sans-serif'> </span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Each User will be
bound by the terms and conditions contained in this agreement and the Tandm
policies which will include Tandm's Privacy Policy insofar as it applies to the
Software.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client
acknowledges that it is responsible for each User's compliance with the terms
of this agreement, the Tandm policies and any applicable Law. </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Each User must
agree to comply with the Tandm policies prior to accessing the Software. </span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc517266955"></a><a name="_Toc517267171"></a><a
name="_Toc517267387"></a><a name="_Toc517259102"></a><a name="_Toc517266957"></a><a
name="_Toc517267173"></a><a name="_Toc517267389"></a><a name="_Toc521416593"></a><a
name="_Toc521416597"></a><a name="_Toc521416599"></a><a name="_Toc517266962"></a><a
name="_Toc517267178"></a><a name="_Toc517267394"></a><a name="_Toc521416605"></a>5.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Users</span></h1>

</div>

<h2><span style='font-family:"Calibri",sans-serif'>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Software access</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>It is the Client's
responsibility to ensure that its Users remember and protect their Software passwords
and do not give to or otherwise allow their passwords to be accessed by any Third
Party or other person.The Client is responsible for all liability that may
occur as a result of it or any of its Users giving their User password
information to any Third Party or other person.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Software shall
only be used and accessed by the Users in the course and scope of their
employment with the Client.The Client must ensure that its Personnel do not
use the Software for or on behalf of another person or entity.</span></h3>

<h2><span style='font-family:"Calibri",sans-serif'>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Software use</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client and its
Users may use the Software to provide services to the Client's customers provided
that the Client's customers do not access or use the Software without obtaining
their own Software licence.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm is not
required to provide any technical support or other support to the Client's customers
and Tandm is not in any way liable to any of the Client's customers unless the
Client's customer has a separate agreement with Tandm which requires that Tandm
provide the Client's customer with such support.</span></h3>

<h2><a name="_Ref38616259"></a><a name="_Ref43221917"><span style='font-family:
"Calibri",sans-serif'>5.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Indemnity</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>The
Client will indemnify and hold the Tandm harmless from and against liabilities,
losses, costs, and expenses (including reasonable legal fees) incurred by Tandm
in connection with any misuse of the Software by any of the Client's Personnel in
breach of this agreement or any person which the Client allows to access the
Software through the Client's account.</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc521499620"></a><a name="_Toc517266970"></a><a
name="_Toc517267186"></a><a name="_Toc517267402"></a><a name="_Toc517266971"></a><a
name="_Toc517267187"></a><a name="_Toc517267403"></a><a name="_Toc517266973"></a><a
name="_Toc517267189"></a><a name="_Toc517267405"></a><a name="_Toc517266986"></a><a
name="_Toc517267202"></a><a name="_Toc517267418"></a>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Support</span><span
style='font-family:"Calibri",sans-serif'> </span></h1>

</div>

<h2><a name="_Ref517203915"></a><a name="_Toc517259108"></a><a
name="_Toc517259111"></a><a name="_Toc517207529"></a><a name="_Toc517207696"></a><a
name="_Toc517207530"></a><a name="_Toc517207697"></a><a name="_Toc517207531"></a><a
name="_Toc517207698"></a><span style='font-family:"Calibri",sans-serif'>6.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Exclusion</span></h2>

<p class=Indent2 style='margin-left:35.45pt'><span style='font-family:"Calibri",sans-serif'>Notwithstanding
that Tandm <a name="_Hlk46996060">may in its absolute discretion </a>provide a
basic level of support services where requested by the Client, this agreement
does not include any support of the Software and Tandm is under no obligation
to provide any support services.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref416193421"></a><a name="_Toc451330713"></a><a
name="_Ref517268690"></a><a name="_Ref517775763"></a><a name="_Toc521499623"></a><a
name="_Toc517207534"></a><a name="_Toc517207701"></a><a name="_Toc517207535"></a><a
name="_Toc517207702"></a><a name="_Toc517207536"></a><a name="_Toc517207703"></a><a
name="_Toc517207537"></a><a name="_Toc517207704"></a><a name="_Toc517207538"></a><a
name="_Toc517207705"></a><a name="_Toc521416611"></a><a name="_Toc521416613"></a><a
name="_Toc521416614"></a><a name="_Toc521416615"></a><a name="_Toc521416616"></a><a
name="_Toc521416617"></a><a name="_Toc521416618"></a><a name="_Toc521416619"></a><a
name="_Toc521416622"></a>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Security</span></h1>

</div>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client is
responsible for managing any vulnerabilities that may arise in connection with
this agreement, including but not limited to vulnerabilities:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>in its Operating
Environment; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>resulting from use
of the Software in a manner that is inconsistent with the terms of this
agreement;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the security of
its computer systems; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>employing all
necessary safeguards, security procedures and controls necessary to protect
against unauthorised access to the Software</span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client is
responsible for:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the security of
its computer systems and security of its access to and connection with the Software;
</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>employing all
necessary safeguards, security procedures and controls necessary to protect
against unauthorised access to the Software; and</span></h4>

<h4><a name="_Ref43221946"><span style='font-family:"Calibri",sans-serif'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any consequences
resulting from a failure to comply with this clause </span></a><span
style='font-family:"Calibri",sans-serif'>7</span><span style='font-family:"Calibri",sans-serif'>,
including any vulnerabilities arising from viruses, malware or other harmful
codes.</span></h4>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref399937607"></a><a name="_Toc433370774"></a><a
name="_Toc472682305"></a><a name="_Ref370920948"></a><a name="_Ref455749011"></a><a
name="_Toc472682296"></a><a name="_Toc521499625"></a><a name="_Ref43235865"></a><a
name="_Toc517773975"></a><a name="_Toc517774994"></a><a name="_Toc517786428"></a><a
name="_Toc517773976"></a><a name="_Toc517774995"></a><a name="_Toc517786429"></a><a
name="_Toc517773977"></a><a name="_Toc517774996"></a><a name="_Toc517786430"></a><a
name="_Toc517773978"></a><a name="_Toc517774997"></a><a name="_Toc517786431"></a><a
name="_Toc517773979"></a><a name="_Toc517774998"></a><a name="_Toc517786432"></a><a
name="_Toc517259120"></a><a name="_Toc517267000"></a><a name="_Toc517267216"></a><a
name="_Toc517267432"></a><a name="_Toc517259123"></a><a name="_Toc517267003"></a><a
name="_Toc517267219"></a><a name="_Toc517267435"></a><a name="_Toc517259124"></a><a
name="_Toc517267004"></a><a name="_Toc517267220"></a><a name="_Toc517267436"></a><a
name="_Toc517267007"></a><a name="_Toc517267223"></a><a name="_Toc517267439"></a><a
name="_Toc517267008"></a><a name="_Toc517267224"></a><a name="_Toc517267440"></a><a
name="_Toc517267016"></a><a name="_Toc517267232"></a><a name="_Toc517267448"></a><a
name="_Toc517267020"></a><a name="_Toc517267236"></a><a name="_Toc517267452"></a><a
name="_Toc517267021"></a><a name="_Toc517267237"></a><a name="_Toc517267453"></a><a
name="_Toc517267022"></a><a name="_Toc517267238"></a><a name="_Toc517267454"></a><a
name="_Toc517207543"></a><a name="_Toc517207710"></a><a name="_Toc517207544"></a><a
name="_Toc517207711"></a><a name="_Toc517207545"></a><a name="_Toc517207712"></a><a
name="_Toc517207546"></a><a name="_Toc517207713"></a><a name="_Toc517207547"></a><a
name="_Toc517207714"></a><a name="_Toc517207548"></a><a name="_Toc517207715"></a><a
name="_Toc517207549"></a><a name="_Toc517207716"></a><a name="_Toc517207550"></a><a
name="_Toc517207717"></a><a name="_Toc517207551"></a><a name="_Toc517207718"></a><a
name="_Toc517207552"></a><a name="_Toc517207719"></a><a name="_Toc517207553"></a><a
name="_Toc517207720"></a><a name="_Toc517207554"></a><a name="_Toc517207721"></a><a
name="_Toc517207555"></a><a name="_Toc517207722"></a><a name="_Toc517207556"></a><a
name="_Toc517207723"></a><a name="_Toc517207557"></a><a name="_Toc517207724"></a><a
name="_Toc517207558"></a><a name="_Toc517207725"></a><a name="_Toc517207559"></a><a
name="_Toc517207726"></a><a name="_Toc517207560"></a><a name="_Toc517207727"></a><a
name="_Toc517207561"></a><a name="_Toc517207728"></a><a name="_Toc517207562"></a><a
name="_Toc517207729"></a><a name="_Toc517207565"></a><a name="_Toc517207732"></a><a
name="_Toc517207566"></a><a name="_Toc517207733"></a><a name="_Toc517207567"></a><a
name="_Toc517207734"></a><a name="_Toc517207568"></a><a name="_Toc517207735"></a><a
name="_Toc517207569"></a><a name="_Toc517207736"></a><a name="_Toc517207570"></a><a
name="_Toc517207737"></a><a name="_Toc517207571"></a><a name="_Toc517207738"></a><a
name="_Toc517207572"></a><a name="_Toc517207739"></a><a name="_Toc517207573"></a><a
name="_Toc517207740"></a><a name="_Toc517207574"></a><a name="_Toc517207741"></a><a
name="_Toc517207575"></a><a name="_Toc517207742"></a><a name="_Toc517207576"></a><a
name="_Toc517207743"></a><a name="_Toc517207577"></a><a name="_Toc517207744"></a><a
name="_Toc517207578"></a><a name="_Toc517207745"></a><a name="_Toc517207579"></a><a
name="_Toc517207746"></a><a name="_Toc517207580"></a><a name="_Toc517207747"></a><a
name="_Toc517207581"></a><a name="_Toc517207748"></a><a name="_Toc517207582"></a><a
name="_Toc517207749"></a><a name="_Toc517207583"></a><a name="_Toc517207750"></a><a
name="_Toc517207584"></a><a name="_Toc517207751"></a><a name="_Toc517207585"></a><a
name="_Toc517207752"></a><a name="_Toc517207586"></a><a name="_Toc517207753"></a><a
name="_Toc517267024"></a><a name="_Toc517267240"></a><a name="_Toc517267456"></a><a
name="_Toc517267025"></a><a name="_Toc517267241"></a><a name="_Toc517267457"></a><a
name="_Toc517267026"></a><a name="_Toc517267242"></a><a name="_Toc517267458"></a><a
name="_Toc517267028"></a><a name="_Toc517267244"></a><a name="_Toc517267460"></a><a
name="_Toc517267030"></a><a name="_Toc517267246"></a><a name="_Toc517267462"></a><a
name="_Toc517267035"></a><a name="_Toc517267251"></a><a name="_Toc517267467"></a><a
name="_Toc517267036"></a><a name="_Toc517267252"></a><a name="_Toc517267468"></a><a
name="_Toc517267040"></a><a name="_Toc517267256"></a><a name="_Toc517267472"></a><a
name="_Toc517267041"></a><a name="_Toc517267257"></a><a name="_Toc517267473"></a><a
name="_Toc461196450"></a><a name="_Toc461196598"></a><a name="_Toc461196922"></a><a
name="_Toc461203408"></a><a name="_Toc461203842"></a><a name="_Toc461196451"></a><a
name="_Toc461196599"></a><a name="_Toc461196923"></a><a name="_Toc461203409"></a><a
name="_Toc461203843"></a><a name="_Toc461196452"></a><a name="_Toc461196600"></a><a
name="_Toc461196924"></a><a name="_Toc461203410"></a><a name="_Toc461203844"></a><a
name="_Toc461196453"></a><a name="_Toc461196601"></a><a name="_Toc461196925"></a><a
name="_Toc461203411"></a><a name="_Toc461203845"></a><a name="_Toc461196454"></a><a
name="_Toc461196602"></a><a name="_Toc461196926"></a><a name="_Toc461203412"></a><a
name="_Toc461203846"></a><a name="_Toc461196455"></a><a name="_Toc461196603"></a><a
name="_Toc461196927"></a><a name="_Toc461203413"></a><a name="_Toc461203847"></a><a
name="_Toc461196456"></a><a name="_Toc461196604"></a><a name="_Toc461196928"></a><a
name="_Toc461203414"></a><a name="_Toc461203848"></a><a name="_Toc461196457"></a><a
name="_Toc461196605"></a><a name="_Toc461196929"></a><a name="_Toc461203415"></a><a
name="_Toc461203849"></a><a name="_Toc461196458"></a><a name="_Toc461196606"></a><a
name="_Toc461196930"></a><a name="_Toc461203416"></a><a name="_Toc461203850"></a><a
name="_Toc461196459"></a><a name="_Toc461196607"></a><a name="_Toc461196931"></a><a
name="_Toc461203417"></a><a name="_Toc461203851"></a><a name="_Toc461203430"></a><a
name="_Toc461203945"></a><a name="_Toc461196566"></a><a name="_Toc461196714"></a><a
name="_Toc461197038"></a><a name="_Toc461203444"></a><a name="_Toc461203959"></a>8.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Privacy Law</span></h1>

</div>

<h2><a name="_Ref43245051"><span style='font-family:"Calibri",sans-serif'>8.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Privacy Law</span></a></h2>

<p class=MsoNormal style='margin-left:35.45pt'><span style='font-family:"Calibri",sans-serif'>Where
Tandm, its Affiliates or a Sub-Processor Processes Personal Data (<b>Processed
Personal Data</b>) in the course of providing the Software and any technical support
to the Client, the parties acknowledge that to the extent of the Privacy Law,
the Client will act as the Controller and Tandm, its Affiliates and its Sub-Processors
will act as a Processor in relation to the Processed Personal Data. </span></p>

<h2><a name="_Ref43239155"><span style='font-family:"Calibri",sans-serif'>8.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Processing instructions</span></a></h2>

<p class=MsoNormal style='margin-left:35.45pt'><span style='font-family:"Calibri",sans-serif'>Subject
to the remainder of this clause </span><span
style='font-family:"Calibri",sans-serif'>8</span><span style='font-family:"Calibri",sans-serif'>,
the Client:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>instructs Tandm to
take such steps in the Processing of Processed Personal Data on its behalf as
are reasonably necessary for Tandm to provide the Software and any technical
support and comply with the terms of this agreement; and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>authorises Tandm
to provide equivalent instructions to its Sub-Processors on behalf of the
Client. </span></h3>

<h2><a name="_Ref43245631"><span style='font-family:"Calibri",sans-serif'>8.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Processing details</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>The
purpose of Processing Personal Data by Tandm is the provision of the Software
and technical support.The types of Personal Data and categories of Data
Subjects are:</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Subject matter:</span></b><span
style='font-family:"Calibri",sans-serif'> The subject matter of the data
Processing in this clause </span><span
style='font-family:"Calibri",sans-serif'>8</span><span style='font-family:"Calibri",sans-serif'>
is the provision of the Software and technical support in compliance with this
agreement; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Duration of the
Processing: </span></b><span style='font-family:"Calibri",sans-serif'>The data
Processing shall occur for the duration of the Term; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Nature and
Purpose of the Processing:</span></b><span style='font-family:"Calibri",sans-serif'>
Tandm will generally only collect Personal Data from the Client, or its
representative or Personnel for the purpose of providing the Software, creating
User accounts, providing technical support, and verifying and authenticating
the payment of the Fees; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>The types of
Personal Data: </span></b><span style='font-family:"Calibri",sans-serif'>The
Processed Personal Data may include:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>information
provided to the Client by its customers; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>personal details,
for example first and last names, date of birth, contact information including
email address and telephone number;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>technical data
including website usage, devices and location of access; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Client information
such as high-level information relating to the Client's business and industry; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>data relating to
the contractual relationship between Tandm and the Client; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>financial
information such as bank account details; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>contract billing
information and payment information; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(viii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>feedback and
responses to surveys; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ix)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>compliance-related
data such as details of the computer and User accessing the Software and technical
support, this may include internet protocol addresses and hardware
identification; </span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>The Categories
of Data Subjects: </span></b><span style='font-family:"Calibri",sans-serif'>The
categories of Data Subjects:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>include Users, the
Client's Personnel, individuals who have a relationship with the Client and
individuals who have a relationship with the Client's customers; and </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>may include the
Personal Data of the Client's customers, contractors and software and hardware
providers; and</span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-family:"Calibri",sans-serif'>Personal Data
transfers: </span></b><span style='font-family:"Calibri",sans-serif'>The
Personal Data may be transferred to Tandm's Personnel, Affiliates and Sub-Processors
who may be based outside of the EEA and may be located in Australia. </span></h3>

<h2><span style='font-family:"Calibri",sans-serif'>8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Compliance with
the Privacy Law</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm shall comply
and ensure that each of its Affiliates and Sub-Processors complies, with the Privacy
Law in relation to its Processed Personal Data pursuant to this agreement.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client shall
ensure that, before any Processed Personal Data is disclosed by the Client to
the Processor, the Client has taken any steps necessary to ensure that, if the
Processor complies with clause </span><span
style='font-family:"Calibri",sans-serif'>8.5</span><span style='font-family:
"Calibri",sans-serif'>, the disclosure does not breach any Privacy Law. </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Without limiting
the foregoing, the Client is responsible:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>at all times for
the integrity, quality and legality of the Processed Personal Data provided by
the Client to Tandm. Tandm is under no duty to investigate the completeness,
accuracy or sufficiency of the Processed Personal Data provided to it by the
Client;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>for informing the Data
Subject that their Processed Personal Data will be transferred to and processed
by Tandm, and to direct them to Tandm's Privacy Policy available on the Tandm
website better.tandm.ai; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>to the extent
required by the Privacy Law, to obtain the consent of the Data Subjects for
their Processed Personal Data to be transferred to and processed by the
Processor. </span></h4>

<h2><a name="_Ref43238962"><span style='font-family:"Calibri",sans-serif'>8.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Client / Processor
Arrangements</span></a></h2>

<p class=MsoNormal style='margin-left:35.45pt;background:white'><span
style='font-family:"Calibri",sans-serif;color:black'>Tandm shall ensure that
the Processor:</span></p>

<h3><a name="_Ref43239668"><span style='font-family:"Calibri",sans-serif;
color:black'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>only<span
style='color:black'> Processes the Processed Personal Data:</span></span></a></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>on the
</span><span style='font-family:"Calibri",sans-serif'>documented<span
style='color:black'> instructions of the Client, which include the instructions
set out in clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.2</span><span
style='font-family:"Calibri",sans-serif;color:black'>; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>as
otherwise required by the Privacy Law in which case the </span><span
style='font-family:"Calibri",sans-serif'>Processor<span style='color:black'>
shall, to the extent permitted by the Privacy Law, inform the Client of such
legal requirement prior to Processing;</span></span></h4>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>ensures<span
style='color:black'> that all of its Personnel authorised to have access to (or
otherwise to process) the Processed Personal Data have committed themselves to
confidentiality on appropriate terms or are under an appropriate statutory
obligation of confidentiality;</span></span></h3>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>at all
times has in place technical and organisational measures to </span><span
style='font-family:"Calibri",sans-serif'>protect<span style='color:black'> the
Processed Personal Data against accidental or unlawful destruction, loss,
alteration, unauthorised disclosure or access which are appropriate to the
risks of varying likelihood and severity for the rights and freedoms of
individuals that are presented by the Processing, taking into account the state
of the art, the costs of implementation and the nature, scope, context and
purposes of the Processing (<strong><span style='font-family:"Calibri",sans-serif'>Appropriate</span></strong>),
including as and where Appropriate:</span></span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>measures for the
pseudonymisation and encryption of Processed Personal Data; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the ability to
ensure the ongoing confidentiality, integrity, availability and resilience of Processing
systems and services; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the ability to
restore the availability of and access to Processed Personal Data in a timely
manner in the event of a physical or technical incident; and </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>a process for
regularly testing, assessing and evaluating the effectiveness of technical and
organisational measures for ensuring the security of the Processing;</span></h4>

<h3><a name="_Ref43239357"><span style='font-family:"Calibri",sans-serif;
color:black'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>gives
the Client such co-operation, assistance and information as the Client may
reasonably request, and the Processor or Tandm may reasonably be able to
provide, to enable the Client to comply with its obligations pursuant to the Privacy
Law and co-operate with the competent authorities in relation to the Processed
Personal Data, including, where relevant given the nature of the Processor's Processing,
assisting the Client:</span></a></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>by </span><span
style='font-family:"Calibri",sans-serif'>taking<span style='color:black'>
appropriate technical and organisational measures, insofar as is possible, to
respond to requests from Data Subjects for access to or rectification, erasure
or portability, or restriction of or objection to Processing, of Processed
Personal Data (but Tandm shall not, and shall ensure that the Processor does
not, respond to any such request except with the Client's prior written consent);
and</span></span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>in </span><span
style='font-family:"Calibri",sans-serif'>ensuring<span style='color:black'>
compliance with the Client's security, data breach notification, impact
assessment and data protection or data privacy authority consultation
obligations pursuant to the Privacy Law, taking into account the information
available to Tandm and/or the Processor. Tandm may charge the Client on a time
and materials basis, at Tandm's standard applicable rates, for time spent in
providing assistance under this clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.5(d)</span><span
style='font-family:"Calibri",sans-serif;color:black'>;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>shall,
</span><span style='font-family:"Calibri",sans-serif'>in<span style='color:
black'> relation to Tandm only, at all times have in place (and keep the Client
informed in writing of the identity and contact details of) an individual who
is responsible for co-operating with the Client in relation to data protection
and data privacy matters in connection with this agreement;</span></span></h3>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>without<span
style='color:black'> undue delay gives written notice to the Client, with
reasonable details, if it becomes aware of, or comes to have reasonable grounds
to suspect, the occurrence of any Personal Data Breach or other material
incident prejudicing, or revealing a material weakness in, the security of the
Processed Personal Data while in its possession or under its control (<strong><span
style='font-family:"Calibri",sans-serif'>Data Security Incident</span></strong>);</span></span></h3>

<h3><a name="_Ref43239528"><span style='font-family:"Calibri",sans-serif;
color:black'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>in </span></a><span
style='font-family:"Calibri",sans-serif'>relation<span style='color:black'> to
any Data Security Incident:</span></span></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>takes
all reasonable steps to identify and correct the underlying cause of the </span><span
style='font-family:"Calibri",sans-serif'>Data<span style='color:black'>
Security Incident to eliminate or minimise the risk of its repetition and the
occurrence of similar Data Security Incidents;</span></span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>takes
such steps as the Client may reasonably request and the Processor or Tandm may
reasonably be able to take to assist the </span><span style='font-family:"Calibri",sans-serif'>Client<span
style='color:black'> in addressing the adverse consequences for the Client, and
complying with the Client's obligations under the Privacy Law in relation to,
the Data Security Incident; and</span></span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>reports
to the Client, promptly and at regular intervals, on the steps </span><span
style='font-family:"Calibri",sans-serif'>taken<span style='color:black'> pursuant
to this clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.5(g)</span><span
style='font-family:"Calibri",sans-serif;color:black'> and their results;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(h)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>makes
available to the Client all information reasonably requested by the Client, and
</span><span style='font-family:"Calibri",sans-serif'>permits<span
style='color:black'> and contributes to all reasonable audits, including
inspections, conducted by the Client (or auditors appointed by the Client or Tandm),
as reasonably necessary to demonstrate Tandm's compliance with this clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.5</span><span
style='font-family:"Calibri",sans-serif;color:black'>, provided that the Client
shall:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>ensure<span
style='color:black'> that such audits take place during Business Hours, on
reasonable notice;</span></span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>ensure
that appropriate confidentiality provisions, or other </span><span
style='font-family:"Calibri",sans-serif'>contractual<span style='color:black'>,
professional or statutory obligations of confidentiality, are agreed with any Third
Party involved in audit or inspection; and</span></span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>take
(and ensure that auditors take) reasonable endeavours to avoid causing any
damage, injury or disruption to Tandm;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>promptly
informs the Client in writing (but without any obligation to give legal advice)
if, in its opinion, to follow an instruction given by the Client as
contemplated by clause </span><span
style='font-family:"Calibri",sans-serif;color:black'>8.5(a)</span><span
style='font-family:"Calibri",sans-serif;color:black'> would give rise to a
breach of the Privacy Law; and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(j)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>at the
option of the Client (to be exercised by written notice from the </span><span
style='font-family:"Calibri",sans-serif'>Client<span style='color:black'> (in
the absence of which the Client is deemed to have chosen delete)), deletes or
returns to the Client, in a reasonable and appropriate format, all the
Processed Personal Data in its possession or under its control as soon as is
practicable on termination or expiration of the Term, and (in the case of
return) as soon as is practicable deletes all other copies of that Processed
Personal Data.</span></span></h3>

<h2><span style='font-family:"Calibri",sans-serif'>8.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>International data
transfers</span></h2>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>The
Standard Contractual Clauses will apply to the transfer of Processed Personal
Data to Tandm from the EEA.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>As part of the
Standard Contractual Clauses:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>Tandm
agrees to comply with the terms of the Standard Contractual Clauses, for the
purposes of which the Client and those of its Affiliates established in the EEA
will be regarded as the Data Exporter(s) (as that term is defined in the
Standard Contractual Clauses) and Tandm will be regarded as the Data Importer
(as that term is defined in the Standard Contractual Clauses);</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>the
governing law in clause 9 of the Standard Contractual Clauses shall be the law
of the Data Exporter;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(iii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>in the
event of inconsistencies between the provisions of the Standard Contractual
Clauses and this agreement, the Standard Contractual Clauses shall take
precedence;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(iv)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>in the
event that the Standard Contractual Clauses are amended, replaced or repealed
by the European Commission or under any Law, the parties shall work together in
good faith to enter into any updated version of the Standard Contractual
Clauses or negotiate in good faith a solution to enable a transfer of Processed
Personal Data to be conducted in compliance with the Law;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(v)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>the parties
agree that the certification of deletion of Processed Personal Data that is
described in clause 12(1) of the Standard Contractual Clauses shall be provided
by the Data Importer to the Data Exporter only upon the Data Exporter's
request; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(vi)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>for
the purposes of Appendix 1 of the Standard Contractual Clauses: </span></h4>

<h5><span style='font-family:"Calibri",sans-serif;color:black'>(A)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>categories
of data: the Personal Data transferred </span><span style='font-family:"Calibri",sans-serif'>concerns<span
style='color:black'> the categories of Processed Personal Data set out in
clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.3</span><span
style='font-family:"Calibri",sans-serif;color:black'>;</span></h5>

<h5><span style='font-family:"Calibri",sans-serif;color:black'>(B)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>Data Subjects:
the Personal Data transferred concerns the </span><span style='font-family:
"Calibri",sans-serif'>categories<span style='color:black'> of Data Subjects set
out in clause </span></span><span
style='font-family:"Calibri",sans-serif;color:black'>8.3</span><span
style='font-family:"Calibri",sans-serif;color:black'>;</span></h5>

<h5><span style='font-family:"Calibri",sans-serif;color:black'>(C)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>special<span
style='color:black'> categories of data: not applicable;</span></span></h5>

<h5><span style='font-family:"Calibri",sans-serif;color:black'>(D)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>the
processing operation as described in this clause </span><span
style='font-family:"Calibri",sans-serif;color:black'>8</span><span
style='font-family:"Calibri",sans-serif;color:black'>;</span></h5>

<h4><span style='font-family:"Calibri",sans-serif'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>for the purposes
of Appendix 2 of the Standard Contractual Clauses: technical and organisational
security measures implemented by the Data Importer are in accordance with
clause </span><span
style='font-family:"Calibri",sans-serif'>8.5</span><span style='font-family:
"Calibri",sans-serif'>.</span></h4>

<h2><span style='font-family:"Calibri",sans-serif'>8.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Sub-Processing</span></h2>

<h3><a name="_Ref43244915"><span style='font-family:"Calibri",sans-serif;
color:black'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>The
Client agrees that Tandm may engage other persons to process the Processed
Personal Data on its behalf (<b>Sub</b>-<b>Processors</b>) if:</span></a></h3>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>the
Sub-Processor is an Affiliate of Tandm or an existing Sub-Processor as at the
commencement of the Term; or</span></h4>

<h4><span style='font-family:"Calibri",sans-serif;color:black'>(ii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>Tandm
has given the Customer reasonable written notice of the engagement and taken
reasonable account of any comments or objections raised by the Client.</span></h4>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>Tandm
remains liable for the performance of its obligations under this clause </span><span
style='font-family:"Calibri",sans-serif;color:black'>8</span><span
style='font-family:"Calibri",sans-serif;color:black'> irrespective of the
engagement of any Sub-Processor.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif;color:black'>(c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif;color:black'>Tandm
shall ensure that each (if any) Sub-Processor is party to a written contract
binding on it with regard to the Client and imposing on its obligations which
are substantially similar to those imposed on Tandm pursuant to clause </span><span
style='font-family:"Calibri",sans-serif;color:black'>8.5</span><span
style='font-family:"Calibri",sans-serif;color:black'>.</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref43243873">9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Fees</span></a><span
style='font-family:"Calibri",sans-serif'> </span></h1>

</div>

<h2><a name="_Toc521499626"><span style='font-family:"Calibri",sans-serif'>9.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Fees</span></a></h2>

<h3><a name="_Ref448214712"><span style='font-family:"Calibri",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Without limiting
any other rights or remedies available to Tandm, if the Client fails to pay the
Fees as and when due, Tandm may suspend access to the Software.</span></a></h3>

<h2><a name="_Ref492622352"></a><a name="_Toc521499629"></a><a
name="_Toc521416627"></a><span style='font-family:"Calibri",sans-serif'>9.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tax</span></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>Unless
otherwise expressly stated, all amounts stated to be payable in this agreement
are exclusive of all goods and services taxes, value added taxes, consumption
taxes, sales tax or other similar taxes, not including income taxes (<b>Taxes</b>).</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref461194062"></a><a name="_Toc472682308"></a><a
name="_Ref455754914"></a><a name="_Ref491863222"></a><a name="_Ref491863438"></a><a
name="_Toc506284782"></a><a name="_Ref517023137"></a><a name="_Toc521499630"></a><a
name="_Toc461203451"></a><a name="_Toc461203966"></a>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Intellectual Property
Rights</span></h1>

</div>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm and its
Affiliates retains all ownership and use rights in the Software including
Intellectual Property Rights.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Software is
subject to protection under the copyright, confidentiality and where
applicable, other intellectual property laws of Australia and other applicable
jurisdictions.</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref517023147"></a><a name="_Ref517023945"></a><a
name="_Toc521499631">11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Confidentiality,
publications and media releases</span></a></h1>

</div>

<h2><a name="_Ref491863557"></a><a name="_Ref492045115"></a><a
name="_Toc492047070"></a><a name="_Toc492279521"></a><a name="_Toc521499632"><span
style='font-family:"Calibri",sans-serif'>11.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Obligation of
confidentiality</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><a name="_Ref492045358"></a><a
name="_Toc492047071"></a><a name="_Toc492279522"><span style='font-family:"Calibri",sans-serif'>The
parties agree to:</span></a></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>keep confidential
and not use or disclose, other than as permitted by this agreement, all
Confidential Information disclosed by on behalf of, or relating to the other
party, or any Affiliate of the other party;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>take reasonable
action necessary to maintain the confidential nature of the Confidential
Information, including keeping all records of the Confidential Information
under a reasonable degree of protection for Confidential Information of that
nature; and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>return all
documents and other materials in any medium in its possession, power or control
which contain or refer to any Confidential Information, on the earlier of
demand by the other party or the time the documents and other materials are no
longer required for the purposes of this agreement, except that one copy of
such materials may be retained solely by the party's legal department or
external lawyers for legal/archival purposes.</span></h3>

<h2><a name="_Toc521499633"><span style='font-family:"Calibri",sans-serif'>11.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Exceptions</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>The
obligation not to disclose in clause </span><span
style='font-family:"Calibri",sans-serif'>11.1</span><span style='font-family:
"Calibri",sans-serif'> does not apply to Confidential Information that is
required to be disclosed under applicable law, or under compulsion of law by a
court or Government Agency, as long as the party disclosing the other party's
Confidential Information:</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>co-operates (at
the other party's expense) with the other party's efforts to take reasonably
available legal measures to avoid or limit the extent of such disclosure; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>discloses the
minimum amount of Confidential Information required to satisfy the law or
rules;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>before disclosing
any information, gives reasonable amount of notice to the other party and takes
all reasonable steps (whether required by the other party or not) to maintain
such Confidential Information in confidence; and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>notifies the court
or Government Agency that it is the Confidential Information of the other
party. </span></h3>

<h2><a name="_Toc521499634"><span style='font-family:"Calibri",sans-serif'>11.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Media and stock market
releases</span></a></h2>

<h3><a name="_Ref491863580"><span style='font-family:"Calibri",sans-serif'>(a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>A party may not
make press or other announcements or releases relating to this agreement and
the matters the subject of this agreement without the prior approval of the
other party.Each party acknowledges that the other party has a proprietary
interest in its legal and business name and reputation.Therefore, each party
agrees not to make reference to or otherwise use the other party's name or
mention or describe this agreement or its relationship with the other party and
its Affiliates in any advertising, marketing and/or promotional materials or
other publications or materials (except as required or permitted by this agreement)
without first obtaining the prior written approval of the other party.</span></a></h3>

<h3><a name="_Ref491863291"><span style='font-family:"Calibri",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Notwithstanding
clause&nbsp;</span></a><span
style='font-family:"Calibri",sans-serif'>11.3(a)</span><span style='font-family:
"Calibri",sans-serif'>, if a party is required by law to disclose any
information relating to this agreement or the identity of the other party, it
may do so to the extent required by law, provided that it:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>gives notice to
the other party, including a copy of the proposed release containing the
information, as soon as practicable after it becomes aware of the need for the
release;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>minimises, to the
extent permitted by law, the extent of the disclosure; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>co-operates
reasonably with the other party, in seeking the benefit of any legally
available exemption from disclosure requirements.</span></h4>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref455754919"></a><a name="_Toc472682310"></a><a
name="_Ref472946277"></a><a name="_Ref517023163"></a><a name="_Toc521499636"></a><a
name="_Toc517207616"></a><a name="_Toc517207783"></a>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Warranties</span></h1>

</div>

<h2><a name="_Ref455754668"></a><a name="_Toc521499637"><span style='font-family:
"Calibri",sans-serif'>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Mutual warranties</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Each party
warrants that as at the Effective Date:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>it has the power
and authority to enter into and perform its obligations under this agreement
and that the execution of this agreement by it has been duly and validly
authorised by all necessary corporate action; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>its obligations
under this agreement are valid and binding and enforceable against it in
accordance with their terms;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>it has, and shall
have at all material times, the right to grant the licences under this
agreement on their terms and that those licences do not infringe the
Intellectual Property Rights of any Third Party;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>it will act
reasonably in relation to the administration and performance of this agreement;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>as far as it is
aware, no additional authorisations, consents or approvals are necessary or
required to enter into and give effect to this agreement;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>in its corporate
capacity, no litigation, arbitration, mediation, conciliation or administrative
proceedings are taking place or to the knowledge of its Personnel are pending
or threatened against it or any of its property which, if adversely determined,
is likely to have a material adverse effect on its obligations to perform under
this agreement.</span></h3>

<h2><a name="_Toc521499638"><span style='font-family:"Calibri",sans-serif'>12.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Client warranties</span></a></h2>

<h3><span style='font-family:"Calibri",sans-serif'>The Client warrants that it
will:</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>do all things
reasonably necessary to assist Tandm in fulfilling its obligations under this
agreement (including providing access to systems, services and Personnel) and
will not directly or indirectly hinder or interfere with the Client's
obligations under this agreement; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>provide sufficient
access to Client Personnel in order for Tandm to provide technical support; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>in using the Software:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>comply with all
applicable Laws;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>comply with all Tandm
policies regarding access and use of the Software;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>use reasonable
security measures when using the Software;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>co-operate with
any investigations by Tandm relating to bugs, security problems, unauthorised
use of the Software or suspected breaches of this agreement or applicable Law
or Tandm policy;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>provide Tandm with
all relevant User information;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(vi)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>be responsible for
its use of the Software and compliance with this agreement and all Tandm
policies;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(vii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>use commercially
reasonable efforts to prevent unauthorised access to, or use of, the Software;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(viii)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>promptly notify Tandm
of any known or suspected unauthorised use of the Client's Software account or
any other breach of security;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>not use the Software:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>for unlawful,
obscene, offensive or fraudulent content or activity;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>to copy,
distribute, modify, reverse engineer, decompile or attempt to obtain the source
code or algorithms of the Software; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>to create any
derivatives of the Software in contravention of this agreement; </span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>for the benefit of
a Third Party;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(v)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>in contravention
of any applicable Law;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>not reproduce,
copy, distribute, modify, sell, rent, lease, license, sublicense, resell or
transfer any part of the Software in contravention of this agreement; </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>not create any
security interest over the Software or this agreement without Tandm's prior
written consent;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>not allow or
authorise anyone to use the Software in a manner that violates the terms of
this agreement and the Tandm policies.</span></h3>

<h2><a name="_Toc521499639"></a><a name="_Toc517207620"></a><a
name="_Toc517207787"></a><span style='font-family:"Calibri",sans-serif'>12.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Limitation on
warranties</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Except for the
express warranties provided under this agreement and to the fullest extent
possible by Law, the technical support and the Software are provided &quot;as
is&quot; and all conditions, warranties, terms and obligations other than in
this agreement whether express or implied by statute, common law or otherwise
are excluded. </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm does not
warrant that the Software will be provided uninterrupted and error-free and Tandm
does not warrant that the Software will meet the individual requirements of the
Client.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client is
responsible for ensuring that it has in place and has the right to use any computer
hardware and software, equipment, electronic systems, networks and web browsers
necessary to enable the Client to use the Software in accordance with this agreement.�
</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Ref517023174"></a><a name="_Toc521499640">13.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Liability and indemnity</span></a></h1>

</div>

<h2><a name="_Toc521499641"><span style='font-family:"Calibri",sans-serif'>13.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Limitation of
liability</span></a></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Subject to clause </span><span
style='font-family:"Calibri",sans-serif'>13.1(b)</span><span style='font-family:
"Calibri",sans-serif'>, Tandm excludes all conditions and warranties which
would otherwise be implied in this agreement. </span></h3>

<h3><a name="_Ref517015806"><span style='font-family:"Calibri",sans-serif'>(b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>If any Law implies
in this agreement any condition or warranty and avoids or prohibits provisions
in an agreement which exclude, restrict or modify the application or liability
under that condition or warranty, the condition or warranty will be deemed to
be included in this agreement to the minimum extent required (<b>Non-excludable
Condition</b>).</span></a></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>To the maximum
extent permitted by Law, the liability of Tandm for any loss or damage however
caused (including by the negligence of Tandm) or breach of a Non-excludable
Condition is limited at the option of Tandm to:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>supplying the Software;
</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>paying the costs
to have the Software supplied again; or</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>payment of $1,000AUD.</span></h4>

<h2><a name="_Toc521499642"></a><a name="_Toc517207624"></a><a
name="_Toc517207791"></a><span style='font-family:"Calibri",sans-serif'>13.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Indemnity</span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client is liable
for, and indemnifies Tandm from and against, all loss or damage (including
legal costs on a solicitor and client basis) incurred or suffered by Tandm
however caused in connection with:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any breach of this
agreement by the Client;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any breach of a
warranty given by the Client under this agreement;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any act, error,
omission or negligence of the Client and its Personnel or any person purporting
to act on behalf of the Client; or</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iv)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>any alleged or
actual infringement of a third party's Intellectual Property Rights in
connection with the Client performing its obligations under this agreement or
in relation to any material. </span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Each indemnity
contained in this agreement:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>is a continuing
obligation; and</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>constitutes a
separate and independent obligation of the Client from its other obligations
under this agreement. </span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>It is not
necessary for Tandm to incur any expense or make any payment before enforcing
or making a claim under an indemnity. </span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc521499643"></a><a name="_Ref43284320">14.<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Termination</span></a><span
style='font-family:"Calibri",sans-serif'> </span></h1>

</div>

<h2><a name="_Toc479476471"></a><a name="_Ref376970262"></a><a
name="_Toc377029065"></a><a name="_Toc377033826"></a><a name="_Toc448211121"></a><a
name="_Toc448214391"></a><a name="_Toc448258166"></a><a name="_Toc521499644"><span
style='font-family:"Calibri",sans-serif'>14.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Termination for
insolvency</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>A party may
terminate this agreement immediately by giving written notice to the other
party if an Insolvency Event occurs in relation to the other party and such
event has continued for at least five Business Days. </span></p>

<h2><a name="_Toc521499645"><span style='font-family:"Calibri",sans-serif'>14.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Termination for
breach</span></a><span style='font-family:"Calibri",sans-serif'> </span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Either party may
terminate this agreement immediately by giving written notice to the other
party if the other party is in breach of any provision of this agreement,
provided that where the breach is capable of remedy, the party in breach has
failed to remedy the breach within 60 days of receipt of written notice from
the other party describing the breach and calling for it to be remedied.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm may
terminate this agreement immediately by giving written notice to the Client, if:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the Client fails
to pay the Fees as and when due;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the Client
breaches this agreement which is not capable of remedy;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(iii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the Client is in
breach of its obligations under any terms of use to Tandm relevant to the
Software.</span></h4>

<h2><span style='font-family:"Calibri",sans-serif'>14.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Termination for
end of period</span></h2>

<p class=MsoNormal style='margin-left:35.45pt'><span style='font-family:"Calibri",sans-serif'>Either
party may terminate this agreement by giving the other party at least one
month's notice prior to the end of a Subscription Period, in which case this
agreement terminates on expiry of the current Subscription Period.</span></p>

<h2><a name="_Toc377033830"></a><a name="_Toc448211125"></a><a
name="_Toc448214395"></a><a name="_Toc448258170"></a><a name="_Toc377029069"></a><a
name="_Toc521499646"></a><a name="_Ref517023252"><span style='font-family:"Calibri",sans-serif'>14.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Consequences of termination</span></a><span
style='font-family:"Calibri",sans-serif'> or expiration</span></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Upon
termination of this agreement for any reason or expiration of this agreement:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>the Client's right
to use the Software terminates and the Client will cease all use of the Software;
and </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>each party must
return to the other party all documents and other materials in any form in its
possession or under its control which contain or refer to any Confidential
Information of the other party.</span></h3>

<h2><a name="_Ref517023277"></a><a name="_Toc521499647"></a><a
name="_Toc521416653"></a><span style='font-family:"Calibri",sans-serif'>14.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Surviving clause</span><span
style='font-family:"Calibri",sans-serif'>s</span></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Termination of
this agreement for any reason does not affect any rights of the parties accrued
prior to termination.The provisions of paragraph C in the Introduction,
clauses </span><span
style='font-family:"Calibri",sans-serif'>5.3</span><span style='font-family:
"Calibri",sans-serif'> (Indemnity), </span><span
style='font-family:"Calibri",sans-serif'>7(b)(iii)</span><span
style='font-family:"Calibri",sans-serif'> (Security), </span><span
style='font-family:"Calibri",sans-serif'>9</span><span style='font-family:"Calibri",sans-serif'>
(Fees), </span><span
style='font-family:"Calibri",sans-serif'>10</span><span style='font-family:
"Calibri",sans-serif'> (Intellectual Property Rights), </span><span
style='font-family:"Calibri",sans-serif'>11</span><span style='font-family:
"Calibri",sans-serif'> (Confidentiality, publications and media releases), </span><span
style='font-family:"Calibri",sans-serif'>12</span><span style='font-family:
"Calibri",sans-serif'> (Warranties), </span><span
style='font-family:"Calibri",sans-serif'>13</span><span style='font-family:
"Calibri",sans-serif'> (Liability and indemnity), </span><span
style='font-family:"Calibri",sans-serif'>18.3</span><span style='font-family:
"Calibri",sans-serif'> (Waiver), </span><span
style='font-family:"Calibri",sans-serif'>18.9</span><span style='font-family:
"Calibri",sans-serif'> (Governing law and jurisdiction) of this agreement and
provisions relating to the consequences of termination including clause </span><span
style='font-family:"Calibri",sans-serif'>14.4</span><span style='font-family:
"Calibri",sans-serif'> (Consequences of termination or expiration) and this
clause </span><span
style='font-family:"Calibri",sans-serif'>14.5</span><span style='font-family:
"Calibri",sans-serif'> (Surviving clauses) survive termination or expiration of
this agreement.</span></p>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc521499648"></a><a name="_Ref455752576"></a><a
name="_Toc472682312">15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif;color:black;background:
white'>Force Majeure</span></a><span style='font-family:"Calibri",sans-serif;
color:black;background:white'> Event</span></h1>

</div>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>A party shall not
be liable for any delay or failure to perform its obligations under this
agreement (other than an obligation to pay Fees or other amounts) if such delay
is due to a Force Majeure Event. </span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>If a delay or
failure is caused or anticipated due to a Force Majeure Event, that party's
obligations will be suspended. If a delay or failure by a party to perform its
obligations due to Force Majeure Event exceeds 90 days, the other party may
immediately terminate this agreement on providing notice in writing to the
party.</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc472682313"></a><a name="_Ref472951411"></a><a
name="_Toc521499649"></a><a name="_Ref43222105">16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Dispute resolution</span></a><span
style='font-family:"Calibri",sans-serif'> </span></h1>

</div>

<h2><a name="_Toc377033832"></a><a name="_Toc448211127"></a><a
name="_Toc448214397"></a><a name="_Toc448258172"></a><a name="_Toc455660835"></a><a
name="_Toc521499650"><span style='font-family:"Calibri",sans-serif'>16.1<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Prohibition on
proceedings</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>A party must
not start court proceedings (except proceedings seeking interlocutory relief)
in respect of a dispute arising out of this agreement (<b>Dispute</b>) unless
it has complied with this clause </span><span
style='font-family:"Calibri",sans-serif'>16</span><span style='font-family:
"Calibri",sans-serif'>.</span><a name="_Ref13544502"></a></p>

<h2><a name="_Toc377033833"></a><a name="_Toc448211128"></a><a
name="_Ref448213063"></a><a name="_Toc448214398"></a><a name="_Toc448258173"></a><a
name="_Toc455660836"></a><a name="_Toc521499651"><span style='font-family:"Calibri",sans-serif'>16.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Notification</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>A party
claiming that a Dispute has arisen must notify the other party to the Dispute
giving details of the Dispute.</span></p>

<h2><a name="_Toc377033834"></a><a name="_Toc448211129"></a><a
name="_Toc448214399"></a><a name="_Toc448258174"></a><a name="_Toc455660837"></a><a
name="_Toc521499652"><span style='font-family:"Calibri",sans-serif'>16.3<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Attempt to resolve</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>During the 10
Business Day period after a notice is given under clause </span><span
style='font-family:"Calibri",sans-serif'>16.2</span><span style='font-family:
"Calibri",sans-serif'> (or longer period agreed in writing by the parties to
the Dispute) (<b>Initial Period</b>) each party must use its reasonable efforts
to resolve the Dispute, including referring the Dispute to senior management
and then to the relevant Chief Executive Officers.</span></p>

<h2><a name="_Toc377033835"></a><a name="_Toc448211130"></a><a
name="_Toc448214400"></a><a name="_Toc448258175"></a><a name="_Toc455660838"></a><a
name="_Toc521499653"><span style='font-family:"Calibri",sans-serif'>16.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Mediation</span></a></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>If the parties are
unable to resolve the Dispute within the Initial Period, the parties must refer
the Dispute for mediation.</span><span style='font-family:"Calibri",sans-serif'>
</span></h3>

<h3><a name="_Toc377033836"></a><a name="_Toc448211131"></a><a
name="_Toc448214401"></a><a name="_Toc448258176"></a><a name="_Toc455660839"><span
style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The mediator will
be a registered member of the Resolution Institute mutually agreed by the
parties or, in default of agreement within a further period of seven days,
appointed, at the request of either party, by the President for the time being
of the Resolution Institute.</span></a></h3>

<h3><a name="_Toc377033837"></a><a name="_Toc448211132"></a><a
name="_Toc448214402"></a><a name="_Toc448258177"></a><a name="_Toc455660840"><span
style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The mediation will
be conducted in Brisbane, Queensland Australia and in accordance with the
guidelines laid down by the appointed mediator having regard to the principles
of best practice in mediation generally. The parties will bear their own costs
of preparing for and participating in the mediation.</span></a><span
style='font-family:"Calibri",sans-serif'></span></h3>

<h3><a name="_Toc377033838"></a><a name="_Toc448211133"></a><a
name="_Toc448214403"></a><a name="_Toc448258178"></a><a name="_Toc455660841"><span
style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>If the mediation
does not conclude the Dispute within 10 Business Days of mediation and unless
the parties agree to extend the mediation, either party may start court
proceedings.</span></a></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc472682314"></a><a name="_Ref516926111"></a><a
name="_Toc521499654">17.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>Notices</span></a><span
style='font-family:"Calibri",sans-serif'> </span></h1>

</div>

<h2><a name="_Ref455753803"></a><a name="_Ref455754932"></a><a
name="_Toc472682315"></a><a name="_Toc492047075"></a><a name="_Toc492279526"></a><a
name="_Ref516918399"></a><a name="_Ref516923790"></a><a name="_Toc521499655"><span
style='font-family:"Calibri",sans-serif'>17.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Method</span></a></h2>

<p class=MsoBodyTextIndent style='margin-left:35.45pt'><span style='font-family:
"Calibri",sans-serif'>All notices, requests, demands, consents, approvals,
offers, agreements or other communications (<b>Notices</b>) given by a party
under or in connection with this agreement must be:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>in writing;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>signed by a person
duly authorised by the sending party;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>directed to the
recipient's address in the purchase order/invoice; and</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>hand delivered, sent
by prepaid post or transmitted by email to that address. </span></h3>

<h2><a name="_Toc492047076"></a><a name="_Toc492279527"></a><a
name="_Toc521499656"></a><a name="_Toc517207639"></a><a name="_Toc517207806"></a><span
style='font-family:"Calibri",sans-serif'>17.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Receipt</span></h2>

<p class=MsoBodyTextIndent style='margin-left:35.45pt'><span style='font-family:
"Calibri",sans-serif'>A Notice given in accordance with this clause </span><span
style='font-family:"Calibri",sans-serif'>17</span><span style='font-family:
"Calibri",sans-serif'> is taken as having been given and received:</span></p>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>if hand delivered
at or before 4.30pm on a Business Day, on delivery, otherwise at 9.30am on the
next Business Day;</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>if sent by prepaid
post:</span></h3>

<h4><span style='font-family:"Calibri",sans-serif'>(i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>within Australia,
on the second Business Day after posting;</span></h4>

<h4><span style='font-family:"Calibri",sans-serif'>(ii)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>to or from a place
outside Australia, on the seventh Business Day after the date of posting;</span></h4>

<h3><span style='font-family:"Calibri",sans-serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>if sent by email
transmission, at the time that would be the time of receipt under the <i>Electronic
Transmissions Act 1999 </i>(Cth).</span></h3>

<div style='border:none;border-bottom:solid windowtext 1.0pt;padding:0cm 0cm 4.0pt 0cm'>

<h1><a name="_Toc521499657">18.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span><span style='font-family:"Calibri",sans-serif'>General</span></a></h1>

</div>

<h2><a name="_Ref491863132"></a><a name="_Toc521499658"><span style='font-family:
"Calibri",sans-serif'>18.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Assignment</span></a></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The Client cannot
assign this agreement without the prior written consent of Tandm.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Tandm can assign
this agreement or transfer its rights and obligations under this agreement to a
transferee which accepts all the obligations of Tandm under this agreement and the
Client consents to such novation. </span></h3>

<h2><a name="_Toc521499659"><span style='font-family:"Calibri",sans-serif'>18.2<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Amendment</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>This agreement
may only be amended by agreement of the parties in writing.</span></p>

<h2><a name="_Ref517023205"></a><a name="_Toc521499660"><span style='font-family:
"Calibri",sans-serif'>18.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Waiver</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Waiver of a
breach or of any right of election arising from a breach of this agreement must
be in writing and signed by the party granting the waiver.A breach or any
right of election arising from a breach of this agreement is not waived by any
failure to or delay in the exercise, or partial exercise, of that right of
election or any other right.</span></p>

<h2><a name="_Toc521499661"><span style='font-family:"Calibri",sans-serif'>18.4<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Costs</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Each party is
responsible for its own costs (legal or otherwise) to negotiate and enter into
this agreement.</span></p>

<h2><a name="_Toc521499662"><span style='font-family:"Calibri",sans-serif'>18.5<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>No agency</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>This agreement
does not constitute any party the agent of another party or imply that the
parties intend constituting a partnership, joint venture or other form of
association in which any party may be liable for the acts or omissions of
another.</span></p>

<h2><a name="_Toc521499663"><span style='font-family:"Calibri",sans-serif'>18.6<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Further assurances</span></a></h2>

<p class=Indent1><span style='font-family:"Calibri",sans-serif'>Each party
agrees, at its own expense, on the request of another party, to do everything
reasonably necessary to give effect to this agreement and the transactions
contemplated by it, including the execution of documents by it, its current and
former employees and contractors.</span></p>

<h2><a name="_Toc521499664"><span style='font-family:"Calibri",sans-serif'>18.7<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Entire agreement</span></a></h2>

<p class=MsoBodyTextIndent style='margin-left:35.45pt'><span style='font-family:
"Calibri",sans-serif'>This agreement sets out the entire agreement between the
parties and supersedes any prior agreements or discussions or conduct by the
parties.</span></p>

<h2><a name="_Toc521499667"><span style='font-family:"Calibri",sans-serif'>18.8<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Severance</span></a></h2>

<h3 style='margin-left:35.45pt;text-indent:0cm'><span style='font-family:"Calibri",sans-serif'>Any
provision of this agreement which is illegal, void or unenforceable in a
jurisdiction will, as to that jurisdiction, be ineffective to the extent of the
illegality, voidability or unenforceability, but without invalidating the
remaining provisions of this agreement or affecting the validity or enforceability
of that provision in another jurisdiction.</span></h3>

<h2><a name="_Ref517023220"></a><a name="_Toc521499668"><span style='font-family:
"Calibri",sans-serif'>18.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>Governing law and
jurisdiction</span></a><span style='font-family:"Calibri",sans-serif'> </span></h2>

<h3><span style='font-family:"Calibri",sans-serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>This agreement is
governed by the laws of Queensland, Australia.</span></h3>

<h3><span style='font-family:"Calibri",sans-serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-family:"Calibri",sans-serif'>The parties submit
to the non&#8209;exclusive jurisdiction of the courts of Queensland, Australia
and courts competent to hear appeals from those courts</span><span
style='font-family:"Calibri",sans-serif'>.</span></h3>

<p class=MsoNormal style='margin-top:0cm'><span style='font-size:11.5pt;
font-family:"Calibri",sans-serif;color:#222222;background:white'>&nbsp;</span></p>

</div>

</body>

</html>
