import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MenuService } from '@core';
import { PERMISSIONS } from '@core/app.permissions';
import { PermissionStateService } from '@core/service/permission-state.service';
import { Menu } from '../../core/bootstrap/menu.service'
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  menu$ = this.menu.getAll();
  buildRoute = this.menu.buildRoute;
  PERMISSIONS = PERMISSIONS;

  constructor(private menu: MenuService,
    private permissionStateService: PermissionStateService) {

  }
  ngOnInit() {
    if(this.permissionStateService.hasPermission(PERMISSIONS.UPDATE_OBSERVATIONDATA)){
      const menu1: Menu = {
        route: "observation-database",
        name: "Observation Database",
        type: "link",
        icon: "note_add"
      }
      this.menu.add(menu1);
    }
    const menu2: Menu = {
      route: "offline-observations",
      name: "Offline Observations",
      type: "link",
      icon: "change_circle"
    }
    this.menu.add(menu2);
  }
}
