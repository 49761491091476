<button
  *ngIf="canCreate"
  mat-raised-button
  color="primary"
  mat-button
  (click)="create()"
  class="mb-2"
>
  Add Observation
</button>
<button mat-raised-button style="margin-left: 20px" mat-button (click)="takePhoto()" class="mb-2">
  Take Photo
</button>
<button
  mat-raised-button
  style="margin-left: 20px"
  (click)="openDialog()"
  class="mb-2"
  *ngIf="!data || data.length === 0"
>
  Import Previous Observations
</button>
<mtx-grid
  [data]="filteredData"
  [columns]="columns"
  [loading]="isLoading"
  [columnResizable]="columnResizable"
  [multiSelectable]="multiSelectable"
  [rowSelectable]="rowSelectable"
  [hideRowSelectionCheckbox]="hideRowSelectionCheckbox"
  [rowHover]="rowHover"
  [rowStriped]="rowStriped"
  [showToolbar]="showToolbar"
  [toolbarTemplate]="toolbarTpl"
  [toolbarTitle]="'List Observation'"
  [columnHideable]="columnHideable"
  [columnMovable]="columnMovable"
  [expandable]="expandable"
  [pageOnFront]="showPaginator"
  [showPaginator]="showPaginator"
  [pageSizeOptions]="pageOptions"
  [pageIndex]="0"
  [pageSize]="pageSize"
  (sortChange)="changeSort($event)"
  (selectionChange)="changeSelect($event)"
  (page)="changePage($event)"
  [cellTemplate]="{ numberOfPhotos: nOfPhotoTpl, obsStatus: obsStatusTpl }"
>
</mtx-grid>

<ng-template #toolbarTpl>
  <div mtx-grid-toolbar-content class="toolbar-flex">
    <div mtx-grid-toolbar-title>List Observation</div>
    <div class="search-box">
      <mat-icon>search</mat-icon>
      <input
        type="text"
        placeholder="Search Comment"
        aria-label="Search Comment"
        class="search-input"
        (input)="applyFilter($event.target.value)"
      />
    </div>
  </div>
</ng-template>

<ng-template #nOfPhotoTpl let-row let-index="index" let-col="colDef">
  <div mat-column-numberOfPhotos>
    <div *ngIf="!row.numberOfPhotos || row.numberOfPhotos === 0" class="center">--</div>
  </div>
  <button
    mat-button
    class="custom-button"
    (click)="viewPhotos(row)"
    *ngIf="row.numberOfPhotos && row.numberOfPhotos != 0"
  >
    <mat-icon class="custom-icon">filter</mat-icon>
    <span class="photos">{{ row.numberOfPhotos }}</span>
  </button>
</ng-template>

<ng-template #obsStatusTpl let-row let-index="index" let-col="colDef">
  <div class="flex">
    <mat-select
      (selectionChange)="onStatusChange(row, $event.value)"
      [(value)]="row.obsStatus"
      class="base-badge"
      [ngClass]="{
        'ongoing-badge': row.obsStatus === 'Ongoing',
        'unresolved-badge': row.obsStatus === 'Unresolved',
        'resolved-badge': row.obsStatus === 'Resolved',
        'new-badge': row.obsStatus === 'New',
        'default-badge': row.obsStatus === 'Unassigned' || row.obsStatus === ''
      }"
    >
      <mat-option
        *ngFor="let statData of statusEnumData"
        value="{{ statData.name }}"
        class="{{ statData.name.toLowerCase() }} status"
        >{{ statData.name }}</mat-option
      >
    </mat-select>
  </div>
</ng-template>
