import { Component, OnInit, Inject, Input } from '@angular/core';
import { ObservationService } from '@core/service/observation.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-photos',
  templateUrl: './view-photos.component.html',
  styleUrls: ['./view-photos.component.scss'],
})
export class ViewPhotosComponent implements OnInit {
  @Input() observation: any[] = [];
  public listPictureSaved: any[];
  public observationId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { observationId: string },
    private observationService: ObservationService
  ) {
    this.observationId = data.observationId;
  }

  ngOnInit(): void {
    this.observationService.getById(this.observationId).subscribe(res => {
      if (res) {
        this.listPictureSaved = res.photos;
      }
    });
  }

  async toDataURL(url) {
    const blob = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {},
    }).then(res => res.blob());
    return URL.createObjectURL(blob);
  }

  async download(item, filename) {
    const a = document.createElement('a');
    a.href = await this.toDataURL(item);
    a.download = !!filename ? filename : 'picture.jpg';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
