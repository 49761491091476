<mat-accordion>
  <mat-expansion-panel class="parametersPanel">
    <mat-expansion-panel-header>
      <mat-panel-title style="font-size: 16px">
        <mat-icon
          style="font-size: 18px; height: 18px; width: 18px; margin-right: 16px"
          aria-hidden="true"
        >
          star
        </mat-icon>
        Bookmarks
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="scrollable-container">
      <mat-nav-list>
        <p class="empty-state-message" *ngIf="links.length === 0">
          <span>Your List is Empty</span><br />
          Click on the star icon to add a bookmark.
        </p>
        <a
          mat-list-item
          role="navigation"
          [routerLink]="link.url"
          *ngFor="let link of links"
          [activated]="link.isActive"
          title="{{ link.name }}"
          (click)="resetBreadcrumb()"
          ><span class="list-item-text">{{ link.name }}</span></a
        >
      </mat-nav-list>
    </div>

    <!-- Remove 10px padding from ''::ng-deep .mat-expansion-panel-body' when the Manage bookmark link will be added -->
    <!-- <mat-action-row *ngIf="links.length > 0">
      <button mat-button color="primary">Manage Bookmarks</button>
    </mat-action-row> -->
  </mat-expansion-panel>
</mat-accordion>
