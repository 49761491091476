import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FIREROLE, MODE } from '@core/app.constants';
import { FireUserService } from '@core/service/fire-user.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'app-create-edit-safety-audit',
  templateUrl: './create-edit-safety-audit.component.html',
  styleUrls: ['./create-edit-safety-audit.component.scss'],
})
export class CreateEditSafetyAuditComponent implements OnInit {
  safetyAuditForm: FormGroup;
  mode;
  MODE = MODE;
  FIREROLE = FIREROLE;
  currentSiteId: any;
  fireAssessors: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditSafetyAuditComponent>,
    private fb: FormBuilder,
    private fireUserService: FireUserService) {
    this.mode = this.data.mode;
    this.currentSiteId = this.data.currentSiteId;
  }
  ngOnInit(): void {
    this.initForm();
    if (this.mode === MODE.EDIT) {
      this.safetyAuditForm.patchValue(this.data.record);
    }
    this.fireAssessors = this.fireUserService.getUserByRole(FIREROLE.FireAssessor);
  }
  initForm() {
    this.safetyAuditForm = this.fb.group({
      siteId: this.currentSiteId,
      inspectByDate: ['', [Validators.required]],
      inspectDate: ['', [Validators.required]],
      certDueDate: [],
      fireUserId: []
    });
  }
  saveAndClose() {
    const formValue = this.safetyAuditForm.value;
    if(formValue.inspectByDate){
      formValue.inspectByDate =  moment(formValue.inspectByDate).format("YYYY-MM-DD");
    }
    if(formValue.inspectDate){
      formValue.inspectDate =  moment(formValue.inspectDate).format("YYYY-MM-DD");
    }
    if(formValue.certDueDate){
      formValue.certDueDate =  moment(formValue.certDueDate).format("YYYY-MM-DD");
    }
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }
}
