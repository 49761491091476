import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastHandlerService {
  constructor(private toastr: ToastrService) {}

  showSuccess(message: string, title?: string) {
    this.toastr.success(message, title, {
      positionClass: 'toast-bottom-right',
			progressBar: true,
    });
  }

  warning(message?, title?){
    this.toastr.warning(message, title,
       { toastClass: 'ngx-toastr toast-half-width'}
      );
  }

  error(message?: string, title?: string){
    this.toastr.error(message, title);
  }

  //display a toast in the top middle, does not have a timeout - require user interaction to dismiss
	public stickyInfo(message: any) {
		this.toastr.info(message, "", {
			positionClass: "toast-top-center",
			progressBar: true,
			disableTimeOut: true,

			enableHtml: true,
			closeButton: true,
			tapToDismiss: false,
			onActivateTick: true
		});
	}
}
