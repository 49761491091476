import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODE } from '@core/app.constants';

@Component({
  selector: 'app-create-edit-fire-safety-mapping',
  templateUrl: './create-edit-fire-safety-mapping.component.html',
  styleUrls: ['./create-edit-fire-safety-mapping.component.scss']
})
export class CreateEditFireSafetyMappingComponent implements OnInit {
  fireSafetyMappingForm: FormGroup;
  mode;
  MODE = MODE;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditFireSafetyMappingComponent>,
    private fb: FormBuilder) {
    this.mode = this.data.mode;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.mode === MODE.EDIT) {
      this.fireSafetyMappingForm.patchValue(this.data.record);
    }
  }
  initForm() {
    this.fireSafetyMappingForm = this.fb.group({
      fireSafetyMeasure: ['', [Validators.required]],
      standard: ['']
    });
  }
  saveAndClose() {
    const formValue = this.fireSafetyMappingForm.value;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }
}
