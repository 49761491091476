import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MODE } from '@core/app.constants';
import { PERMISSIONS } from '@core/app.permissions';
import { ObservationService } from '@core/service/observation.service';
import { PermissionStateService } from '@core/service/permission-state.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { MatDialog } from '@angular/material/dialog';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { Page } from '@shared/utils/page';
import { ViewPhotosComponent } from './view-photos/view-photos.component';
import { ImportPreviousYearObservationsComponent } from './submit-observation/import-previous-year-observations/import-previous-year-observations.component';
import { FireSafetyAuditDetailDto, ObservationClient, ObservationDetailDto, ObservationDto, ObservationStatus } from '@shared/tc-api-model';
import { EnumHelpers, Utils } from '@shared/utils/utils';
import { first, map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss'],
})
export class ObservationsComponent implements OnInit {
  ObservationStatusEnum: ObservationStatus;

  private _data: ObservationDetailDto[];

  //using setter and getter so that filteredData can be set when data is bound
  @Input() set data(value: ObservationDetailDto[]) {
    this._data = value;
    this.filteredData = value;
  }

  get data(): ObservationDetailDto[] {
    return this._data;
  }

  /*
   * returns true if all observations have been ticked/checked
   */
  public AllChecked(): boolean {
    if (this._data && this._data.length) {
      if (typeof this._data[0].obsStatus === 'string')
        return this._data.every(
          p => (p.obsStatus as unknown as string) != ObservationStatus[ObservationStatus.Unassigned]
        );
      else return this._data.every(p => p.obsStatus !== ObservationStatus.Unassigned);
    }

    return true;
  }

  filteredData: ObservationDetailDto[] = this.data;

  columns: MtxGridColumn[] = [
    {
      header: 'Fire Safety Measure',
      field: 'fireSafetyName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Comment',
      field: 'comment',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Defect Category',
      field: 'defectCategoryName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Defect Criticality',
      field: 'defectCriticalityName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Rectification',
      field: 'rectificationName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Location',
      field: 'location',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Photos',
      field: 'numberOfPhotos',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Status',
      field: 'obsStatus',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this observation?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];

  isLoading = true;
  isDisable = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentFireSafetyAuditId: any;
  public observationId: any;
  importList = false;
  importedObsData: FireSafetyAuditDetailDto[] = [];
  statusEnumData: { name: string; value: number }[] = [];
  myControl = new FormControl('');
  locationOptions: string[];
  filteredOptions: Observable<string[]>;

  canCreate: boolean;
  canDeleteAndUpdate: boolean;
  constructor(
    private matDialog: MatDialog,
    private toastHandlerService: ToastHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private observationService: ObservationService,
    private observationClient: ObservationClient,
    public dialog: MtxDialog,
    private permissionStateService: PermissionStateService
  ) {
    this.statusEnumData = EnumHelpers.getNamesAndValues(ObservationStatus);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      this.currentFireSafetyAuditId = p.get('id');
    });

    this.isLoading = false;
    this.checkPermission();
    this.filteredData = this.data;
  }

  applyFilter(value: string) {
    const inputValue = value?.trim().toLowerCase();

    this.filteredData = this.data.filter(row => row.comment.toLowerCase().includes(inputValue));
  }

  edit(value: any) {
    if (value.isTakePhoto) {
      this.router.navigate([
        `fire-safety-audits/${this.currentFireSafetyAuditId}/submit-photo/edit/${value.id}`,
      ]);
    } else {
      this.router.navigate([
        `fire-safety-audits/${this.currentFireSafetyAuditId}/submit-observation/edit/${value.id}`,
      ]);
    }
  }

  create() {
    this.router.navigate([
      `fire-safety-audits/${this.currentFireSafetyAuditId}/submit-observation/create`,
    ]);
  }

  takePhoto() {
    this.router.navigate([
      `fire-safety-audits/${this.currentFireSafetyAuditId}/submit-photo/create`,
    ]);
  }

  viewPhotos(value: any) {
    this.observationId = value.id;
    const dialogRef = this.matDialog.open(ViewPhotosComponent, {
      data: { observationId: this.observationId },
    });
  }

  openDialog() {
    const dialogRef = this.matDialog.open(ImportPreviousYearObservationsComponent);
    dialogRef.componentInstance.fireSafetyAuditId = this.currentFireSafetyAuditId;

    dialogRef.afterClosed().subscribe((result: FireSafetyAuditDetailDto) => {
      if (result && result.id) {
        this.observationClient
          .copyObservationFromAuditId(result.id, this.currentFireSafetyAuditId)
          .pipe(first())
          .subscribe(result => {
            if (result && result.length) {
              this.data = result;
            } else {
              Utils.OpenOkDialog(this.dialog, 'Selected Audit have no Observation data.');
            }
          });
      }
    });
  }
  getData() {
    this.isLoading = true;
    this.observationService.getByFireSafetyAuditId(this.currentFireSafetyAuditId).subscribe(res => {
      this.data = res;
      this.isLoading = false;
    });
  }

  changePage(event: PageEvent) {}

  delete(value: any) {
    this.observationService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(
        `You have deleted ${value.fireSafetyName ?? 'an observation'}!`,
        ''
      );
      this.getData();
    });
  }

  checkPermission() {
    this.canCreate = this.permissionStateService.hasPermission(PERMISSIONS.CREATE_OBSERVATION);
    this.canDeleteAndUpdate = this.permissionStateService.hasPermission(
      PERMISSIONS.UPDATE_OBSERVATION
    );
    if (!this.canDeleteAndUpdate) {
      this.columns.pop();
    }
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }

  onStatusChange(row: ObservationDto, value: string) {
    if (!row) {
      console.error('Row is undefined');
      return;
    }

    let dto = new ObservationDto(row);
    this.observationClient
      .update(dto)
      .subscribe(data => {
        this.toastHandlerService.showSuccess('Status updated');
      })
      .add(() => this.getData());
  }

  getStatusNumber(e: ObservationDetailDto) {
    return ObservationStatus[e.obsStatus.toString()];
  }

  getStatusString(e: ObservationDetailDto) {
    return ObservationStatus[e.obsStatus ?? 0];
  }

  setChecked(row, value) {
    if (!row) {
      console.error('Row is undefined');
      return;
    }

    let dto = new ObservationDto(row);
    dto.checked = value;
    this.observationClient
      .update(dto)
      .subscribe(data => {
        this.toastHandlerService.showSuccess(`Observation ${value ? 'checked' : 'unchecked'}`);
      })
      .add(() => this.getData());
  }
}
