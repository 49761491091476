<mat-card-title>
    {{mode === MODE.CREATE ? 'Create ' : 'Edit'}}
</mat-card-title>
<form [formGroup]="companyForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Company name</mat-label>
                <input matInput placeholder="Input company name" formControlName="companyName"
                       required>
                <mat-error *ngIf="companyForm.get('companyName')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Street</mat-label>
                <input matInput placeholder="Input street" formControlName="street"
                       required>
                <mat-error *ngIf="companyForm.get('street')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput placeholder="Input city"
                       formControlName="city" required>
                <mat-error *ngIf="companyForm.get('city')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Post code</mat-label>
                <input matInput placeholder="Input post code"
                       formControlName="postCode"
                       maxlength="4"
                       (paste)="onlyNumberCheck($event, 'paste')"
                        (keypress)="onlyNumberCheck($event, 'keyPress')"
                        required>
                <mat-error *ngIf="companyForm.get('postCode')?.invalid">
                    {{'validations.postcode' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <!-- <div fxFlex="100">
            <mat-form-field>
                <mat-label>Contact</mat-label>
            
                <mat-select formControlName="companyContactId">
                    <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact.id">
                      {{contact?.firstName + " " + contact?.lastName + " (" + contact.email + ")"}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="companyForm.get('companyContactId')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div> -->
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="companyForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>