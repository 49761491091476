<ng-progress id="headerProgress" #progressBar></ng-progress>
<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle"
          (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <app-branding *ngIf="showBranding"></app-branding>
  <span fxFlex></span>

  <!-- <button mat-icon-button class="matero-toolbar-button">
    <mat-icon>search</mat-icon>
  </button> -->

  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <!-- <app-notification fxHide.lt-sm></app-notification> -->

  <!-- <app-translate></app-translate> -->
  <a routerLink="/offline-observations"><mat-icon *ngIf="hasQueue">change_circle</mat-icon></a>
  <div *ngIf="isOffline$ | async as isoff">
    <mat-icon class="offline-icon" *ngIf="isoff">signal_wifi_bad</mat-icon>
  </div>

  <app-user [tooltip]="versionString"></app-user>

  <!-- <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleSidenavNotice.emit()">
    <mat-icon>list</mat-icon>
  </button> -->
</mat-toolbar>
