import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoutesRoutingModule } from './routes-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { CompanycontactsComponent } from './companycontacts/companycontacts.component';
import { CreateEditCompanycontactComponent } from './companycontacts/create-edit-companycontact/create-edit-companycontact.component';
import { CompaniesComponent } from './companies/companies.component';
import { CreateEditCompanyComponent } from './companies/create-edit-company/create-edit-company.component';
import { SitesComponent } from './sites/sites.component';
import { CreateEditSiteComponent } from './sites/create-edit-site/create-edit-site.component';
import { CompanyDetailComponent } from './companies/company-detail/company-detail.component';
import { MinStandardPerfsComponent } from './min-standard-perfs/min-standard-perfs.component';
import { CreateEditMinStandardComponent } from './min-standard-perfs/create-edit-min-standard/create-edit-min-standard.component';
import { SiteDetailComponent } from './sites/site-detail/site-detail.component';
import { FireSafetyScheduleComponent } from './fire-safety-schedule/fire-safety-schedule.component';
import { CreateEditFireSafetyComponent } from './fire-safety-schedule/create-edit-fire-safety/create-edit-fire-safety.component';
import { FireSafetyAuditComponent } from './fire-safety-audit/fire-safety-audit.component';
import { CreateEditSafetyAuditComponent } from './fire-safety-audit/create-edit-safety-audit/create-edit-safety-audit.component';
import { FireSafetyAuditDetailComponent } from './fire-safety-audit/fire-safety-audit-detail/fire-safety-audit-detail.component';
import { ObservationsComponent } from './observations/observations.component';
import { SubmitObservationComponent } from './observations/submit-observation/submit-observation.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './observations/submit-observation/camera/camera.component';
import { FireUserComponent } from './fire-user/fire-user.component';
import { CreateEditFireUserComponent } from './fire-user/create-edit-fire-user/create-edit-fire-user.component';
import { DatePipe } from '@angular/common';
import { ObservationDatabaseComponent } from './observation-database/observation-database.component';
import { ImportObservationDataComponent } from './observation-database/import-observation-data/import-observation-data.component';
import { CreateEditFireSafetyMappingComponent } from './observation-database/create-edit-fire-safety-mapping/create-edit-fire-safety-mapping.component';
import { ObservationDatabaseDetailComponent } from './observation-database/observation-database-detail/observation-database-detail.component';
import { ObservationMappingComponent } from './observation-database/observation-mapping/observation-mapping.component';
import { CreateEditObservationMappingComponent } from './observation-database/create-edit-observation-mapping/create-edit-observation-mapping.component';
import { ImportSiteDataComponent } from './sites/import-site-data/import-site-data.component';
import { ImportCompanyDataComponent } from './companies/import-company-data/import-company-data.component';
import { ImportContactDataComponent } from './companycontacts/import-contact-data/import-contact-data.component';
import { EulaComponent } from './eula/eula.component';
import { EulaDocumentComponent } from './eula/eula-document/eula-document.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacyDocumentComponent } from './privacy/privacy-document/privacy-document.component';
import { ImportPreviousYearObservationsComponent } from './observations/submit-observation/import-previous-year-observations/import-previous-year-observations.component';
import { ViewPhotosComponent } from './observations/view-photos/view-photos.component';
import { ObservationsQueueComponent } from './observations/observations-queue/observations-queue.component';

const COMPONENTS = [
  DashboardComponent,
  LoginComponent,
  RegisterComponent,
  CompanycontactsComponent,
  CreateEditCompanycontactComponent,
  CompaniesComponent,
  CreateEditCompanyComponent,
  SitesComponent,
  CreateEditSiteComponent,
  CompanyDetailComponent,
  MinStandardPerfsComponent,
  CreateEditMinStandardComponent,
  SiteDetailComponent,
  FireSafetyScheduleComponent,
  CreateEditFireSafetyComponent,
  FireSafetyAuditComponent,
  CreateEditSafetyAuditComponent,
  FireSafetyAuditDetailComponent,
  ObservationsComponent,
  SubmitObservationComponent,
  CameraComponent,
  FireUserComponent,
  CreateEditFireUserComponent,
  ObservationDatabaseComponent,
  ImportObservationDataComponent,
  CreateEditFireSafetyMappingComponent,
  ObservationDatabaseDetailComponent,
  ObservationMappingComponent,
  CreateEditObservationMappingComponent,
  ImportSiteDataComponent,
  ImportCompanyDataComponent,
  ImportContactDataComponent,
  EulaComponent,
  EulaDocumentComponent,
  PrivacyComponent,
  PrivacyDocumentComponent,
];
const COMPONENTS_DYNAMIC = [];

@NgModule({
  imports: [SharedModule, RoutesRoutingModule, WebcamModule],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,
    ImportPreviousYearObservationsComponent,
    ObservationsQueueComponent,
    ViewPhotosComponent,
  ],
  entryComponents: COMPONENTS_DYNAMIC,
  providers: [DatePipe],
})
export class RoutesModule {}
