<div *ngIf="listPictureSaved && listPictureSaved.length > 0">
  <mat-content class="photos">
    <div *ngFor="let item of listPictureSaved; let i = index">
      <div class="photo-preview">
        <div class="photo-action-btns">
          <a
            href="{{ item }}"
            download="picture{{ i }}.jpg"
            class="download-btn"
            (click)="$event.preventDefault(); download(item, 'picture' + i + '.jpg')"
          >
            <span class="material-icons download-icon">download</span>
          </a>
        </div>
        <img ng class="taken-photo" src="{{ item }}" />
      </div>
    </div>
  </mat-content>
</div>
