export class TandmUtil {
  static dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: 'image/jpeg' });
  }

  static blobToFile(theBlob: Blob, fileName: string): File {
    const blob: any = theBlob;
    Object.assign(blob, { name: '', lastModified: '' });
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    var file = new File([blob], 'name', {
      type: blob.type,
    });
    return file;
  }
}
