import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODE } from '@core/app.constants';
import { FireSafetyScheduleService, fsmNames, mspNames } from '@core/service/fire-safety-schedule.service';
import { ObservationDataService } from '@core/service/observation-data.service';

@Component({
  selector: 'app-create-edit-fire-safety',
  templateUrl: './create-edit-fire-safety.component.html',
  styleUrls: ['./create-edit-fire-safety.component.scss']
})
export class CreateEditFireSafetyComponent implements OnInit {
  MODE = MODE;
  mode;
  currentSiteId: any;
  fireForm: FormGroup;
  mspOptions = [];
  mappingStandard = new Map();
  listFireSafety: [];
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateEditFireSafetyComponent>,
    private service: FireSafetyScheduleService,
    private observationDataService: ObservationDataService) {
    this.mode = this.data.mode;
    this.currentSiteId = this.data.currentSiteId;
  }

  ngOnInit(): void {
    this.getFireSafetyMeasureMapping();
    this.initForm();
    if (this.mode == MODE.EDIT) {
      this.fireForm.patchValue(this.data.record);
    }
    this.fireForm.get('fireSafetyName').valueChanges.subscribe(value => {
      const option = this.mappingStandard.get(value);
      if (option) {
        this.mspOptions = option;
      } else {
        this.mspOptions = [];
      }
      this.fireForm.patchValue({
        minStandardName: ''
      });
    })
  }

  initForm() {
    this.fireForm = this.fb.group({
      siteId: this.currentSiteId,
      id: this.data.id ? this.data.id : 0,
      fireSafetyName: ['', Validators.required],
      minStandardName: ['', Validators.required]
    });
  }

  getFireSafetyMeasureMapping(){
    this.observationDataService.getDropDownListFireSafetyMeasure().subscribe((res)=>{
      if(res){
        this.listFireSafety = res;
        for (let i = 0; i < res.length; i++) {
          this.mappingStandard.set(res[i].fireSafetyMeasure, res[i].standards);
        }
      }
    })
  }
  saveAndClose() {
    this.fireForm.markAllAsTouched();
    if (this.fireForm.valid) {
      this.dialogRef.close(this.fireForm.value);
    }
  }
}
