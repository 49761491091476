import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MODE } from '@core/app.constants';
import { FireUserService } from '@core/service/fire-user.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditFireUserComponent } from './create-edit-fire-user/create-edit-fire-user.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-fire-user',
  templateUrl: './fire-user.component.html',
  styleUrls: ['./fire-user.component.scss'],
})
export class FireUserComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'First Name',
      field: 'firstName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Last Name',
      field: 'lastName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Email',
      field: 'email',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Mobile',
      field: 'mobile',
      minWidth: 100,
    },
    {
      header: 'Role',
      field: 'roleName',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this fire user?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  list: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private toastHandlerService: ToastHandlerService,
    private fireUserService: FireUserService,
    public dialog: MtxDialog
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditFireUserComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireUserService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditFireUserComponent, {
      width: '600px',
      data: { mode: MODE.CREATE },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireUserService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(`Fire User ${PopUpMessages.CreateSuccess}`, '');
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.fireUserService.getUserByRole().subscribe(res => {
      this.list = res;
      this.isLoading = false;
    });
  }
  delete(value: any) {
    this.fireUserService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`${value.email} ${PopUpMessages.DeleteSuccess}`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }
}
