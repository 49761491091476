import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { MODE } from '@core/app.constants';
import { FireSafetyScheduleService } from '@core/service/fire-safety-schedule.service';
import { PermissionStateService } from '@core/service/permission-state.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditFireSafetyComponent } from './create-edit-fire-safety/create-edit-fire-safety.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-fire-safety-schedule',
  templateUrl: './fire-safety-schedule.component.html',
  styleUrls: ['./fire-safety-schedule.component.scss'],
})
export class FireSafetyScheduleComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Fire Safety Measure',
      field: 'fireSafetyName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: ' Minimum Standard of Performance',
      field: 'minStandardName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this fire safety schedule?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  @Input() fireSafetySchedule: any[] = [];
  isLoading = true;
  isDisable = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentSiteId: any;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private fireSafetyScheduleService: FireSafetyScheduleService,
    private cdr: ChangeDetectorRef,
    public dialog: MtxDialog,
    private permissionStateService: PermissionStateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.currentSiteId = this.route.snapshot.paramMap.get('id');
  }
  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditFireSafetyComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT, currentSiteId: this.currentSiteId },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireSafetyScheduleService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditFireSafetyComponent, {
      width: '600px',
      data: {
        mode: MODE.CREATE,
        currentSiteId: this.currentSiteId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireSafetyScheduleService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(
          `Fire Safety Schedule ${PopUpMessages.CreateSuccess}`,
          ''
        );
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.fireSafetyScheduleService.getBySiteId(this.currentSiteId).subscribe(
      res => {
        this.fireSafetySchedule = res;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }
  changePage(event: PageEvent) {}
  delete(value: any) {
    this.fireSafetyScheduleService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(
        `${value.fireSafetyName} ${PopUpMessages.EditSuccess}`,
        ''
      );
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }
}
