export class PopUpMessages {

	/**
	 * string of 'Edit saved successfully.'
	 **/
	static EditSuccess: string = "Edit saved successfully.";

	/**
	 * string of ' created successfully.' to be used with string templates
	 **/
	static CreateSuccess: string = "created successfully.";

	/**
	 * string of ' deleted.' to be used with string templates
	 **/
	static DeleteSuccess: string = "deleted.";

	/**
	 * string of ' queued.' to be used with string templates
	 **/
	static Queued: string = "queued.";

  static ObsUploadedWhenOnline = "The observation can be uploaded as soon as your device is back online.";

  static ObservationsUploaded = "All observations have been uploaded successfully.";

  static StatusChangedOffline = "You are currently offline";
}
