<html>

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=Generator content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Founders Grotesk Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Founders Grotesk Medium";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Corbel;
	panose-1:2 11 5 3 2 2 4 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.reddocumenttitle, li.reddocumenttitle, div.reddocumenttitle
	{mso-style-name:reddocumenttitle;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.reddocumentsubtitle, li.reddocumentsubtitle, div.reddocumentsubtitle
	{mso-style-name:reddocumentsubtitle;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.rednormalbodytext, li.rednormalbodytext, div.rednormalbodytext
	{mso-style-name:rednormalbodytext;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.redheading11, li.redheading11, div.redheading11
	{mso-style-name:redheading11;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.redheading211, li.redheading211, div.redheading211
	{mso-style-name:redheading211;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.rednormalbodytextindent, li.rednormalbodytextindent, div.rednormalbodytextindent
	{mso-style-name:rednormalbodytextindent;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.redheading3a, li.redheading3a, div.redheading3a
	{mso-style-name:redheading3a;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.rednormal, li.rednormal, div.rednormal
	{mso-style-name:rednormal;
	margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang=EN-AU style='word-wrap:break-word'>

<div class=WordSection1>

<p class=reddocumenttitle style='margin:0cm;text-align:justify'><span
style='font-size:24.0pt;font-family:"Corbel",sans-serif;color:#007DFF'>Tandm
Compliance Pty Ltd</span></p>

<p class=reddocumentsubtitle style='margin-top:0cm;margin-right:0cm;margin-bottom:
10.5pt;margin-left:0cm;text-align:justify'><span style='font-size:20.0pt;
font-family:"Corbel",sans-serif;color:#585856'>Privacy Policy</span></p>

<p class=rednormalbodytext style='margin-top:10.5pt;margin-right:0cm;
margin-bottom:10.5pt;margin-left:0cm;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Last
modified: 3 December 2021</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-family:"Corbel",sans-serif;color:black'>1.</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Preliminary</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>1.1</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>General</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>TANDM
COMPLIANCE Pty Ltd</span></b><span style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>&nbsp;ACN 652 350 055 (<b>"we", "us", "our"</b>) are committed to
protecting your privacy. We understand that users of our services and software
products are concerned about their privacy and the confidentiality and security
of any data that is provided.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>This
Privacy Policy sets out how we collect, use, store and disclose (<b>Process</b>)
all personal data, whether received through our website&nbsp;<i>&lt;www.tandm.ai&gt;</i>&nbsp;(<b>Website</b>),
our software products and solutions (<b>Software</b>) or through our general
interactions with you.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
only Process personal data in accordance with this Privacy Policy, and the laws
applicable in the jurisdiction in which we are Processing that data. If there
is any inconsistency between this Privacy Policy and an applicable law, the
applicable law will prevail to the extent of any inconsistency.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>1.2</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Contact&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Our
contact details are:</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Telephone:
61412 577 211</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Post: L10,
12 Creek St, Brisbane QLD 4000</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Privacy
Issues Email: privacy@tandm.ai</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-family:"Corbel",sans-serif;color:black'>2.</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Nature of Personal Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>In this
policy when we talk about &quot;personal data&quot; we are referring to any
information which can, directly or indirectly, be used to identify you. For
example, names, addresses, personal identification numbers, location data and
other online identifiers (such as email addresses).</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>2.1</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Anonymised</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Whenever
you visit our Website, our servers automatically record information for
statistical purposes about your usage of the Website such as:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(a)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the type
of browser used;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(b)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the
referring URL;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(c)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the IP
address;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(d)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the number
and type of pages viewed;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(e)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the date
and time of visits; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(f)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the exit
URL.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>This
information we collect about you is not personal data and, in any event,
remains anonymous. We do not link the anonymous information to any other
personal data and we will not link a record of your usage of our Website to you
personally.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>2.2</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Personal
Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>When using
our Software we ask you for certain personal data to provide you with the
products or services that you request from us. This may include, for example,
when you create a user account in order to enable you to access our Software
services. We may also collect personal data when you have made an enquiry or
request from us, or when you otherwise interact with us generally (such as by
telephone, in person or by email).</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>The nature
of the personal data that we collect includes:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(a)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>your
contact details (full name, email, telephone number and delivery and billing
address);</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(b)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the name
of your employer or place of business;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(c)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>login and
account information; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:70.9pt;text-align:justify;text-indent:-35.45pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>(d)</span><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>banking
information (if this is being provided by your personally and not by an
employer or organisation).</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Toc29390069"><b><span style='font-family:"Corbel",sans-serif;color:black'>3.</span></b></a><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Cookies and other</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>&nbsp;tracking technologies</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>To improve
your experience on our Website, we may use cookies and other tracking
technologies, such as pixel tags, web beacons, device IDs, or advertising IDs.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You may
refuse the use of cookies by selecting the appropriate settings on your
browser, however please note that if you do this, you may not be able to use
the full functionality of our Website.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Our
Website may use Google Analytics, a service which transmits website traffic
data to Google servers in the United States. Google Analytics does not identify
individual users or associate your IP address with any other data held by
Google. We use reports provided by Google Analytics to help us understand
website traffic and web page usage.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You can
customise or opt out of Google Analytics for display advertising using the Ads
Settings for your Google account.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Our
Website uses cookies and other tracking technologies to support Google
Analytics for:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Google
Marketing Platform integrations;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Demographic
and Interest Reporting;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Display
Advertising including Remarketing and Google Display Network Impression
Reporting to advertise our services online;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Optimizing
and personalizing ad services based on your past visits to our Website; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Reporting
the use of ad services and interactions with ad impressions and how they are
related to visits to our Website.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Cookies
and other tracking technologies may also be used to support analytics by other
third parties.</span></p>

<span style='font-size:10.5pt;line-height:107%;font-family:"Corbel",sans-serif;
color:black'><br clear=all style='page-break-before:always'>
</span>

<p class=MsoNormal><span style='font-size:10.5pt;line-height:107%;font-family:
"Corbel",sans-serif;color:black'>&nbsp;</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Toc29390072"><b><span style='font-family:"Corbel",sans-serif;color:black'>4.</span></b></a><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Purposes of collecting</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>, holding, using and
disclosing Personal Data</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.1</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Collect
from you only</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Where it
is reasonable or practical to do so we will only collect personal data about
you from you directly.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If we
collect personal data which we have not sought or requested about you, and if
we determine we are legally permitted to collect that information, we will deal
with that information in accordance with the terms of this Privacy Policy. In
all other circumstances, we will destroy or de-identify the relevant personal
data as soon as practicable, where it is lawful and reasonable to do so.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.2</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Purposes
of collecting Personal Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
only Process your personal data for the purposes of:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>enabling
us to provide you with our Software or any other products or services that you
have requested;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>creating
user accounts within our Software;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>providing
technical support or&nbsp;</span><span style='font-size:10.5pt;font-family:
"Corbel",sans-serif;color:black'>responding to your general queries and
enquires;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>d)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>our
internal research and statistical purposes;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>e)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>marketing
our Software and related products and services to you;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>f)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>enabling us to forward to you other information or material which
we genuinely believe may be of interest to you;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>g)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>verifying
and authenticating the payments to us, or&nbsp;</span><span style='font-size:
10.5pt;font-family:"Corbel",sans-serif;color:black'>enabling a payment
processor to process payments that you make to us;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>h)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>enabling
us to defend or prosecute a legal proceeding, or to comply with the order of
any court or regulatory authority; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>i)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>any other purpose specified in an agreement that we enter into with
you.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If we
Process your personal data for any other purpose other than that specified
above, such use will relate to the above purposes, and will be within what you
would reasonably expect us to use that personal data for at the time of
collection.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:0cm;text-align:justify;background:#F2F2F2'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We do not
sell personal data to any third parties or provide personal data to third
parties for any business purpose (unless that business purpose has been
specifically referred to in this Privacy Policy).</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Hlk54014742"></a><a name="_Toc29390076"></a><a name="_Toc29390073"></a><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.3</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Direct
marketing</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>From time
to time we may use your personal data to provide you with current information
about special offers you may find of interest, changes to our organisation, or
new products or services being offered by us or any company we are associated
with.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:0cm;text-align:justify;background:#F2F2F2'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You
consent to us using your personal data to contact you on an ongoing basis for
the purpose of us marketing our Software, related products and services to you,
howsoever such advertising may occur.</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.4</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Withdrawing</span></b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;<b>Consent</b></span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You may
withdraw your consent to our use of your personal data at any time by
contacting us using the contact information contained in this Privacy Policy.
However, this may affect or limit your ability to access our Website or the
services available through our Software.</span></p>

<p class=rednormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:35.45pt;text-align:justify'><span style='font-size:10.5pt;
font-family:"Corbel",sans-serif;color:black'>If you do not wish to receive marketing
information, you may at any time decline to receive such information. We will
take all reasonable steps to meet your request at the earliest possible
opportunity.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.5</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Holding
of Personal Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
take reasonable steps to protect personal data that we hold from:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>misuse,
interference and loss; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>unauthorized
access, modification or disclosure.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>In the
unlikely event that there is a serious data breach that is likely to result in
a risk to your rights and freedoms, we will without undue delay notify the
appropriate supervisory authority in your jurisdiction. Where there is a high
risk that a serious data breach will impact upon your rights and freedoms, we
will also notify you of that breach.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.6</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>International
Data Transfers</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Typically,
all personal data that you provide to us will be processed within Australia.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>However,
in order to provide customer support, perform back office functions, deliver
Software products and services to you, perform fraud prevention tasks we may
need to allow our staff or third party service providers (who may be located in
a location other than your country of residence) access to your personal data
and, in particular, your name, delivery address and billing information.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
always ensure that the recipient of your personal data offers an adequate level
of protection, for instance by entering into the appropriate back-to-back
agreements and, if required, containing the standard contractual clauses for
the transfer of personal data as approved by the European Commission. Such
transfers are necessary for us to perform our obligations and provide our
products and services to you.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:0cm;text-align:justify;background:#F2F2F2'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Despite
the above, by providing your personal data you are explicitly consenting to the
international transfer and processing of such data in accordance with this
Privacy Policy, in full and informed knowledge of the risks associated with
such transfers and processing.</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>4.7</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Unsolicited
information</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You may
withdraw your consent to the international transfer of your personal data at
any time by contacting us using the contact information contained in this
Privacy Policy. However, this may affect or limit our ability to provide you
with our Software and associated products or services.</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Toc29390074"><b><span style='font-family:"Corbel",sans-serif;color:black'>5.</span></b></a><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Destruction of Personal
Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
keep your personal data only for as long as we need it to fulfill our
contractual obligations to you, for our legitimate business purposes or as
required by applicable law. We will take reasonable steps to erase any personal
data that we hold about you where:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>we no
longer need the personal data for the purposes for which it was collected and
we are not otherwise entitled to retain such data; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>we are not
required or obligated by law, regulation or court order to retain the
information.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If certain
information has been provided to third parties in accordance with this Privacy
Policy, retention of that information will be subject to the policies of the
relevant third party.</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Toc29390080"></a><a name="_Ref29457367"></a><b><span style='font-family:
"Corbel",sans-serif;color:black'>6.</span></b><b><span style='font-size:7.0pt;
color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Access, correction and
portability</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.1</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Access
to&nbsp;Personal&nbsp;Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
provide you with access upon request to the personal data held by us in
relation to you except to the extent that:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>giving
access would be unlawful; or</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>denying
access is required or authorized by or under a relevant law, regulation or
court order.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>To request
access to your personal data, please contact us using the details provided in
this Privacy Policy.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Following
your request, we will contact you within one (1) month from the date of receipt
of your request and provide you with the personal data requested, unless we
have determined that some reason or obligation exists requiring us to withhold
access to the personal data.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.2</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Third
party disclosure</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We do not
sell your personal data to any third parties. Unless specifically referred to
in this Privacy Policy, we also do not disclose your personal data to any third
parties for other business purposes.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
disclose personal data to our affiliates that assist in us providing our
Software to you, assist us in providing our services generally, or that may
offer complimentary or related products and services. You may at any time
request that we provide a list of the relevant affiliates or that we do not
make such disclosures. However, this may limit or restrict our ability to make
our Software available to you.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You may at
any time request that we provide confirmation that your personal data has not
been sold to a third party, or request details of any third party to which
information has been sold or disclosed for business purposes.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.3</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Data
portability</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Insofar as
it does not adversely affect the rights and freedoms of others and where you
have communicated a request to us:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>where we
have employed an automated means to process your personal data, we will&nbsp;</span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>provide</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;you
with a copy of such personal data in a structured, commonly used and
machine-readable format; and</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>after&nbsp;</span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>receiving</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;your
request, where technically feasible, we will transmit your personal data&nbsp;</span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>directly</span><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>&nbsp;to
another data holder.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.4</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Commercially
sensitive information</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Where
providing you access to your personal data would reveal commercially sensitive
information, we are unable to give you access to that information. However, we
will provide you with the reasoning behind our determination.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.5</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Use
of intermediaries</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Where we
cannot directly provide you with access to your personal data we may, if it is
reasonable, engage the services of an intermediary to do so.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.6</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></b><b><span lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>Correction and erasure of Personal Data</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>It is your
responsibility to ensure that the information you provide to us is accurate,
and to update your personal data as necessary.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If for any
reason you are unable to correct any personal data held by us through your user
account in our Software, please contact us using the details provided in this
Privacy Policy.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If after
receiving your request we choose not to correct your information, we will
notify you within one month of the reason of our refusal and the mechanisms
available to you to object to our refusal.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If you
wish to have your personal data erased, please communicate your request to us
using the contact information provided above. The only circumstances in which
we will not take reasonable steps to erase your personal data are where we need
to keep it in accordance with another provision of this Privacy Policy, an
applicable law, or the order of a court or regulatory authority.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If we
correct or erase personal data about you that we previously disclosed to a
third party, we will take reasonable steps to notify the third party of the
correction or erasure and to request that they do the same.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.7</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Refusal
to correct or erase</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If at any
time we refuse or deny you access to your personal data, or refuse to correct
or erase your personal data, we will provide you with reasons we are unable to
satisfy your request.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.8</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Restriction
of processing</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You may
request that we limit or restrict the manner in which we Process &nbsp;your
personal data, in which case we will only Process your personal data: with your
consent; for the establishment, exercise or defence of legal claims against us;
or for the protection of the rights of another person.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.9</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;</span></b><b><span lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>Costs</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Access to,
erasure and rectification of your personal data, including any communications
related thereto, will generally be provided free of charge.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Where your
requests are manifestly unfounded or excessive in nature (particularly due to
repetitiveness) we may charge you a reasonable fee for providing the
information or communication, or taking the action requested. Where your
requests are manifestly unfounded or excessive in nature we may also refuse
your request.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>6.10</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Specific
objection rights</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If it
becomes necessary for us to process your personal data without your consent for
reasons of:</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>a)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>performing
a task in the public interest;</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>b)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>the
exercise of an official authority that has been vested in us; or</span></p>

<p class=redheading3a style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:71.45pt;text-align:justify;text-indent:-18.0pt'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>c)<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>where
it is in our legitimate interests or the legitimate interests of a third party
to do so;</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>you will
have the right to object to such use.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We will
notify you of such instances. If you object to our use of your personal data we
will refrain from using your personal data unless compelling legitimate grounds
exist for us to use that personal data, or for the establishment, exercise or
defence of legal claims.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><a
name="_Hlk54014790"><span style='font-size:10.5pt;font-family:"Corbel",sans-serif;
color:black'>If we have collected your personal data in accordance with this
Privacy Policy and elect to use such personal data for the purposes of direct
marketing (to which you consent), you have the right to object at any time to
such use. Upon receiving an objection we will, as soon as is reasonably
practical, cease using your personal data for the purposes of direct marketing.</span></a></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><a
name="_Toc29390081"><b><span style='font-family:"Corbel",sans-serif;color:black'>7.</span></b></a><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Complaints</span></b></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>7.1</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Making
a complaint</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>If you
believe that we have used or disclosed your personal data in a manner which is
contrary to this Privacy Policy or that otherwise breaches an applicable law,
then you should email us at peter.glodic@tandm.au.</span></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>You also
have the right to lodge a complaint with the relevant supervisory authority in
your jurisdiction. If you are unsure who your relevant supervisory authority
may be, please contact us so that we may provide you with assistance.</span></p>

<p class=redheading211 style='margin-top:6.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>7.2</span></b><b><span
lang=EN-US style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
lang=EN-US style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Our
response</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>Within 30
days of receipt of your complaint we will notify you in writing as to what
action we propose to take in relation to your complaint and will provide you
with details of what further action you can take if you are not satisfied with
our response.</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-family:"Corbel",sans-serif;color:black'>8.</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Variations</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>We reserve
the right to vary this Policy from time to time. Any variations made will be
updated on our Website. It is your responsibility to check our Policy
periodically to ensure you are aware of any changes made to it.</span></p>

<p class=redheading11 style='margin-top:10.5pt;margin-right:0cm;margin-bottom:
10.5pt;margin-left:35.45pt;text-align:justify;text-indent:-35.45pt'><b><span
style='font-family:"Corbel",sans-serif;color:black'>9.</span></b><b><span
style='font-size:7.0pt;color:black'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></b><b><span
style='font-family:"Corbel",sans-serif;color:black'>Further information</span></b></p>

<p class=rednormalbodytextindent style='margin-top:0cm;margin-right:0cm;
margin-bottom:10.0pt;margin-left:35.45pt;text-align:justify'><span
style='font-size:10.5pt;font-family:"Corbel",sans-serif;color:black'>For any
further information about this policy please contact us at&nbsp;<a name=Text1></a>privacy@tandm.ai.</span></p>

<p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>
