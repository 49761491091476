<mat-card-title>
    {{mode === MODE.CREATE ? 'Create ' : 'Edit'}}
</mat-card-title>
<form [formGroup]="contactForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput placeholder="Input first name" formControlName="firstName"
                       required>
                <mat-error *ngIf="contactForm.get('firstName')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput placeholder="Input last name" formControlName="lastName"
                       required>
                <mat-error *ngIf="contactForm.get('lastName')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Mobile</mat-label>
                <input matInput placeholder="Input mobile"
                       formControlName="mobile" required>
                <mat-icon matSuffix>smartphone</mat-icon>
                <mat-error *ngIf="contactForm.get('mobile')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Input email" formControlName="email" required>
                <mat-error *ngIf="contactForm.get('email')?.invalid">
                    {{getErrorMessage(contactForm) | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="contactForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>