import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CompanyService } from '@core/service/company.service';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {
  public title = "Company Profile";
  public subtitle = "";
  company: any;
  site: any[] = [];
  currentCompanyId: any;
  contact: any[] = [];
  isLoading = true;
  constructor(
    private readonly titleService: Title,
    private companyService: CompanyService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.currentCompanyId = id;
      this.getData().add(() => {
        if (this.company && this.company.companyName)
          this.titleService.setTitle(`${this.company.companyName} - ${this.title}`);
      });
    });
  }

  getData() {
    return this.companyService.getById(this.currentCompanyId).subscribe(
      res => {
      if (res) {
        this.company = res;
        if (res.contacts) {
          this.contact = res.contacts;
        }
        if (res.sites) {
          this.site = res.sites;
        }
        this.isLoading = false;
      }
    });
  }

}
