import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { FireSafetyScheduleService } from '@core/service/fire-safety-schedule.service';
import { SiteService } from '@core/service/site.service';

@Component({
  selector: 'app-site-detail',
  templateUrl: './site-detail.component.html',
  styleUrls: ['./site-detail.component.scss'],
})
export class SiteDetailComponent implements OnInit {
  public title = 'Site Profile';
  public subtitle = '';
  site: any;
  siteId: any;
  fireSafetySchedule: any[] = [];
  fireSafetyAudit: any[] = [];
  isLoading = true;

  constructor(
    private siteService: SiteService,
    private route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.siteId = id;
      this.getData().add(() => {
        if (this.site && this.site.siteName)
          this.titleService.setTitle(`${this.site.siteName} - ${this.title}`);
      });
    });
  }

  getData() {
    return this.siteService.getById(this.siteId).subscribe(res => {
      if (res) {
        this.site = res;
        if (res.fireSafetySchedules) {
          this.fireSafetySchedule = res.fireSafetySchedules;
        }
        if (res.fireSafetyAudits) {
          this.fireSafetyAudit = res.fireSafetyAudits;
        }
        this.isLoading = false;
      }
    });
  }
}
