// Build information, automatically generated by `ng-info`
const build = {
    version: "1.0.1",
    timestamp: "Mon Aug 26 2024 02:19:04 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "df9e18",
        fullHash: "df9e18b52105b1f09f557df1f91f302cfaaafe45"
    }
};

export default build;