<mat-card-title>
    {{mode === MODE.CREATE ? 'Add fire safety measure' : 'Edit fire safety measure'}}
</mat-card-title>
<form [formGroup]="fireForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Fire safety measure</mat-label>
                <input matInput [matAutocomplete]="autocomplete" formControlName='fireSafetyName' required>
                <mat-autocomplete #autocomplete="matAutocomplete">
                  <mat-option *ngFor="let state of listFireSafety" [value]="state.fireSafetyMeasure">
                    <span>{{ state.fireSafetyMeasure }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="fireForm.get('fireSafetyName')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Minimum standard of performance</mat-label>
                <input matInput [matAutocomplete]="autocomplete2" formControlName='minStandardName' required>
                <mat-autocomplete #autocomplete2="matAutocomplete">
                  <mat-option *ngFor="let state of mspOptions" [value]="state">
                    <span>{{ state }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="fireForm.get('fireSafetyName')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>    
        </div>
    </div>
   
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="fireForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>