import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { User } from '@core/authentication/interface';
import { DOCUMENT } from '@angular/common';
import { AuthService as Auth0Service} from '@auth0/auth0-angular';
@Component({
  selector: 'app-user',
  template: `
    <button
      class="matero-toolbar-button matero-avatar-button"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      <img class="matero-avatar" [src]="user?.picture" width="32" alt="avatar" [matTooltip]="tooltip" />
      <span class="matero-username" fxHide.lt-sm>{{ user?.nickname }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <!-- <button routerLink="/profile/overview" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'user.profile' | translate }}</span>
      </button>
      <button routerLink="/profile/settings" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>{{ 'user.settings' | translate }}</span>
      </button> -->
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'user.logout' | translate }}</span>
      </button>
    </mat-menu>
  `,
})
export class UserComponent implements OnInit {
  user: Partial<User>;
  @Input() tooltip = '';

  constructor(private router: Router,
    private cdr: ChangeDetectorRef,
    private auth0: Auth0Service,
    @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit(): void {
    this.auth0
      .user$
      .pipe(
        tap(user => (this.user = user)),
        debounceTime(10)
      )
      .subscribe(() => this.cdr.detectChanges());
  }

  logout() {
    this.auth0.logout({ returnTo: this.doc.location.origin });
  }
}
