import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eula-document',
  templateUrl: './Tandm_Compliance_EULA_Final.html',  
})
export class EulaDocumentComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {}
}
