<mat-card-title>
    {{mode === MODE.CREATE ? 'Create ' : 'Edit'}}
</mat-card-title>
<form [formGroup]="observationMappingForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Observation Comment</mat-label>
                <input matInput placeholder="Input observation comment" formControlName="observationComment"
                       required>
                <mat-error *ngIf="observationMappingForm.get('observationComment')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Rectification</mat-label>
                <input matInput placeholder="Input rectification" formControlName="rectification">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Defect Category</mat-label>
                <mat-select [formControl]="observationMappingForm.controls.defectCategory">
                    <mat-option *ngFor= "let defect of defectCategory" [value]="defect">{{defect}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Defect Criticality</mat-label>
                <mat-select [formControl]="observationMappingForm.controls.defectCriticality">
                    <mat-option *ngFor= "let defect of defectCriticality" [value]="defect">{{defect}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="observationMappingForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>
