import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODE } from '@core/app.constants';
import { MinStandradPertfService } from '@core/service/min-standrad-pertf.service';

@Component({
  selector: 'app-create-edit-min-standard',
  templateUrl: './create-edit-min-standard.component.html',
  styleUrls: ['./create-edit-min-standard.component.scss']
})
export class CreateEditMinStandardComponent implements OnInit {

  minStandardForm: FormGroup;
  mode;
  MODE = MODE;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditMinStandardComponent>,
    private fb: FormBuilder
  ) { 
    this.mode = this.data.mode;
  }

  ngOnInit(): void {
    this.initForm();
    if(this.mode === MODE.EDIT) {
      this.minStandardForm.patchValue(this.data.record);
    }
  }

  initForm() {
    this.minStandardForm =  this.fb.group({
      mspName: ['', [Validators.required]],
    });
  }
  saveAndClose() {
    const formValue = this.minStandardForm.value;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }
}
