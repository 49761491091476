import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bookmark } from '@shared/utils/bookmark';
import { BookmarkService } from '@shared/services/bookmark.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bookmark-dialog',
  templateUrl: './bookmark-dialog.component.html',
  styleUrls: ['./bookmark-dialog.component.scss'],
})
export class BookmarkDialogComponent implements OnInit {
  @Output() buttonSelected = new EventEmitter<string>();
  public bookmark: Bookmark | null = null;
  name: string;
  url: string;
  star: boolean;
  dialogTitle: string = 'Add Bookmark';
  bookmarkLimitFull: boolean = false;
  mainBtnText: String = 'Add';

  constructor(
    private titleService: Title,
    public dialogRef: MatDialogRef<BookmarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { bookmark: Bookmark | null },
    private bookmarkService: BookmarkService,
    private router: Router
  ) {
    this.url = this.router.url;
    // Set the name to the current page title
    this.name = this.titleService.getTitle();
  }

  ngOnInit(): void {
    const bookmark: Bookmark = {
      id: this.bookmark ? this.bookmark.id : Date.now(),
      name: this.name,
      url: this.url,
      star: this.star,
    };

    // Check if there is an existing bookmark with the same URL
    const bookmarks = this.bookmarkService.getBookmarks();
    let bookmarkCount = Object.keys(bookmarks).length;

    let existingBookmark = null;
    for (const id in bookmarks) {
      if (bookmarks[id].url === bookmark.url) {
        existingBookmark = bookmarks[id];
        this.name = existingBookmark.name;
        this.url = existingBookmark.url;
        this.star = existingBookmark.star;
        this.bookmarkLimitFull = false;
        this.dialogTitle = 'Edit Bookmark';
        this.mainBtnText = 'Save';
        break;
      } else {
        //Limit the number of bookmarks to ten
        if (bookmarkCount === 10) {
          this.bookmarkLimitFull = true;
          this.dialogTitle = 'Oops! Your Bookmark List is Full.';
        } else {
          this.bookmarkLimitFull = false;
          this.dialogTitle = 'Add Bookmark';
          this.mainBtnText = 'Add';
        }
      }
    }
  }

  onSave(): void {
    const bookmark: Bookmark = {
      id: this.bookmark ? this.bookmark.id : Date.now(),
      name: this.name,
      url: this.url,
      star: this.star,
    };

    // Check if there is an existing bookmark with the same URL and update it, otherwise add the new bookmark
    const bookmarks = this.bookmarkService.getBookmarks();
    let existingBookmark = null;
    for (const id in bookmarks) {
      if (bookmarks[id].url === bookmark.url) {
        existingBookmark = bookmarks[id];
        break;
      }
    }
    if (existingBookmark) {
      existingBookmark.name = bookmark.name;
      this.bookmarkService.updateBookmark(existingBookmark);
      this.dialogRef.close(existingBookmark);
    } else {
      bookmarks[bookmark.id] = bookmark;
      bookmark.star = true;
      this.bookmarkService.addBookmark(bookmark);
      this.dialogRef.close(bookmark);
      this.buttonSelected.emit('star');
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onRemove(): void {
    // Check if there is an existing bookmark with the same URL and remove it
    const bookmarks = this.bookmarkService.getBookmarks();
    for (const id in bookmarks) {
      if (bookmarks[id].url === this.router.url) {
        let removedBookmark = bookmarks[id];
        removedBookmark.star = false;

        this.bookmarkService.removeBookmark(removedBookmark);
        this.buttonSelected.emit('unstar');
        break;
      }
    }
    this.dialogRef.close(null);
  }
}
