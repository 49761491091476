
import config from '../../auth_config.dev.json';
import versionFile from '../version.json';
import build from "../build";

const { domain, clientId, audience, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  audience?: string;
  apiUri: string;
  errorPath: string;
};

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
    redirectUri: window.location.origin,
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`${apiUri}/*`],
  },
  apiUri: apiUri,
  baseUrl: '',
  useHash: false,
  hmr: false,
};

const { version, buildDate, sourceHash } = versionFile as {
  version: string;
  buildDate: string;
  sourceHash: string;
};

export const appVersion = {
  version: versionFile.version,
  buildDateString: versionFile.buildDate,
  sourceHash: versionFile.sourceHash
}

export const buildInfo = {
  version: build.version,
  buildDateString: build.timestamp,
  sourceHash: build.git.hash,
  branch: build.git.branch
}
