import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { FireSafetyAuditService } from '@core/service/fire-safety-audit.service';
import { Location } from '@angular/common';
import {
  defectCategory,
  defectCriticality,
  FireSafetyScheduleService,
} from '@core/service/fire-safety-schedule.service';
import { ObservationDataService } from '@core/service/observation-data.service';
import { ObservationService } from '@core/service/observation.service';
import { MtxDialog } from '@ng-matero/extensions';
import { WebcamImage } from 'ngx-webcam';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { from, of, Subject } from 'rxjs';
import { PopUpMessages } from '@shared/utils/messages';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OfflineStatusService } from '@core/service/offline-status.service';
import { catchError, first, switchMap } from 'rxjs/operators';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { TandmUtil } from '@shared/utils/tandm-util';
import { ResultState } from 'app/shared/models/enums'
import { FileParameter, ObservationClient } from '@shared/tc-api-model';
import { CustomStorageError } from '@shared/models/CustomErrors';

@Component({
  selector: 'app-submit-observation',
  templateUrl: './submit-observation.component.html',
  styleUrls: ['./submit-observation.component.scss'],
})
export class SubmitObservationComponent implements OnInit, AfterViewInit {
  dialogRef: MatDialogRef<any>;
  @ViewChild('dialogTemplate') dialogTemplate: any;

  private unsubscribe = new Subject<void>();
  public listMeasureButtons: any[];
  public listObservationComment = [];
  public listPicturesTaken: any[];
  private listPictureSend: any[];
  public listPictureSaved: any[] = [];
  private currentFireSafetyAuditId: any;
  public observationForm: FormGroup;
  private mappingComment = new Map();
  public isOtherButtonSelected = false;
  public isDisplayCamera: boolean;
  public selectedFireSafetyMeasureId: any;
  public defectCategory: string;
  public defectCriticality: string;
  public rectification: string;
  public obsComments = [];
  defectCategories = defectCategory;
  defectCriticalities = defectCriticality;

  private _checked;
  private _obsStatus;

  columns: number;

  gridByBreakpoint = {
    xl: 4,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1
  }

  fixedCols = 4;
  fixedRowHeight = 100;
  ratioGutter = '1';
  fitListHeight = '400px';
  ratio = '4:1';

  isLoadingVisible = false;

  public isEdit: boolean;
  public isTakePhoto: boolean;
  public observationId: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly titleService: Title,
    private fireSafetyAuditService: FireSafetyAuditService,
    private route: ActivatedRoute,
    private fireSafetyScheduleService: FireSafetyScheduleService,
    private fb: FormBuilder,
    private observationService: ObservationService,
    private observationClient: ObservationClient,
    public dialog: MtxDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    private matDialog: MatDialog,
    private observationDataService: ObservationDataService,
    private offlineStatusService: OfflineStatusService,
    private localStorageService: LocalStorageService,
    private toastHandlerService: ToastHandlerService
  ) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.columns = this.gridByBreakpoint.xs;
        }
        if (result.breakpoints[Breakpoints.Small]) {
          this.columns = this.gridByBreakpoint.sm;
        }
        if (result.breakpoints[Breakpoints.Medium]) {
          this.columns = this.gridByBreakpoint.md;
        }
        if (result.breakpoints[Breakpoints.Large]) {
          this.columns = this.gridByBreakpoint.lg;
        }
        if (result.breakpoints[Breakpoints.XLarge]) {
          this.columns = this.gridByBreakpoint.xl;
        }
      }
    });

    this.observationForm = this.fb.group({
      observationComment: this.fb.control(''),
      location: this.fb.control(''),
      defectCategory: this.fb.control(''),
      defectCriticality: this.fb.control(''),
      rectification: this.fb.control(''),
    });
  }

  ngOnInit() {
    this.isTakePhoto = this.router.url.includes('submit-photo');
    this.isEdit = this.route.snapshot.paramMap.get('mode') === 'create' ? false : true;
    this.observationId = this.route.snapshot.paramMap.get('observationId');

    if (this.isEdit) {
      this.listObservationComment = [];
      this.observationService.getById(this.observationId).subscribe(res => {
        if (res) {
          this.listPictureSaved = res.photos;
          this.selectedFireSafetyMeasureId = res.fireSafetyMeasureId;
          this.observationForm.patchValue({
            observationComment: res.comment,
            location: res.location,
            defectCategory: res.defectCategoryName,
            defectCriticality: res.defectCriticalityName,
            rectification: res.rectificationName,
          });

          this.obsComments =
          !!(this.mappingComment.get(res.fireSafetyName.toLowerCase()))
            ? this.mappingComment.get(res.fireSafetyName.toLowerCase())
            : [];

          if (res.fireSafetyName && this.obsComments) {
            for (let i = 0; i < this.obsComments.length; i++) {
              this.listObservationComment.push(this.obsComments[i].observationComment);
            }
          }

          this._checked = res.checked;
          this._obsStatus = res.obsStatus;
        }
      });
    }
    this.isDisplayCamera = false;
    this.listPicturesTaken = [];
    this.listPictureSend = [];
    this.getObservationMapping();
    const otherButton = {
      fireSafetyName: 'Other',
      id: null,
    };
    this.currentFireSafetyAuditId = parseInt(this.route.snapshot.paramMap.get('fireSafetyAuditId'));
    this.fireSafetyAuditService
      .getFireSafetyAuditButtonsById(this.currentFireSafetyAuditId)
      .subscribe(res => {
        if (res) {
          this.listMeasureButtons = res;
          this.listMeasureButtons.push(otherButton);
        }
      });
  }
  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  openDialog() {
    const dialogRef = this.matDialog.open(this.dialogTemplate);

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        dialogRef.close();
        this.location.back();
      }
    });
  }

  turnOnCamera() {
    this.isDisplayCamera = !this.isDisplayCamera;
  }

  removePicture(index: number) {
    this.listPicturesTaken.splice(index, 1);
    this.listPictureSend.splice(index, 1);
  }



  async toDataURL(url) {
    const blob = await fetch(url, {
      method:"GET",
      mode: 'cors',
      headers: {}
  }).then(res => res.blob());
    return URL.createObjectURL(blob);
  }

  async download(item, filename) {

    const a = document.createElement("a");
    a.href = await this.toDataURL(item);
    a.download = !!filename ? filename : "picture.jpg";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  handleImage(webcamImage: WebcamImage) {
    const file = TandmUtil.blobToFile(TandmUtil.dataURItoBlob(webcamImage.imageAsDataUrl), 'file');
    this.listPicturesTaken.push(webcamImage);
    this.listPictureSend.push(file);
  }

  handleChosenImage(image: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const data = event.target.result;
      this.listPicturesTaken.push({ imageAsDataUrl: data });
    };
    reader.readAsDataURL(image);
    this.listPictureSend.push(image);
  }

  selecteButton(item: any) {
    this.listObservationComment = [];
    //NOTE: is this intended behaviour? can only change fire safety measure in create mode
    //if so why show the other fire safety measure buttons, could hide them
    if (!this.isEdit) {
      this.selectedFireSafetyMeasureId = item.id;
      this.observationForm.patchValue({
        observationComment: '',
        defectCriticality: '',
        defectCategory: '',
        rectification: '',
      });
      if (item.fireSafetyName === 'Other') {
        this.isOtherButtonSelected = true;
      } else {
        this.isOtherButtonSelected = false;
        this.obsComments =
          !!(this.mappingComment.get(item.fireSafetyName.toLowerCase()))
            ? this.mappingComment.get(item.fireSafetyName.toLowerCase())
            : [];
        for (let i = 0; i < this.obsComments.length; i++) {
          this.listObservationComment.push(this.obsComments[i].observationComment);
        }
      }
    }
  }
  selectComment(index: any) {
    this.defectCriticality = this.obsComments[index].defectCriticality;
    this.defectCategory = this.obsComments[index].defectCategory;
    this.rectification = this.obsComments[index].rectification;
    this.observationForm.patchValue({
      defectCategory: this.defectCategory,
      defectCriticality: this.defectCriticality,
      rectification: this.rectification,
    });
  }
  submit() {
    if (this.isEdit) {
      const body = {
        id: this.observationId,
        comment: this.getComment,
        location: this.getLocation,
        fireSafetyAuditId: this.currentFireSafetyAuditId,
        fireSafetyMeasureId: this.selectedFireSafetyMeasureId,
        defectCategoryName: this.getDefectCategory,
        defectCriticalityName: this.getDefectCriticality,
        rectificationName: this.getRectification,
        checked: this._checked,
        obsStatus: this._obsStatus
      };

      this.isLoadingVisible = true;
      this.observationService
        .update(body)
        .pipe(switchMap(
          () => {
            //add photo to observable chain if there are new photos to upload
            if(this.listPicturesTaken && this.listPicturesTaken.length) {
              let filesParams: FileParameter[] = this.listPicturesTaken.map((w:WebcamImage,i) => {
                return {data:TandmUtil.dataURItoBlob(w.imageAsDataUrl), fileName: 'file'};
              });

              return this.observationClient.addPhoto(this.observationId, filesParams);
            } else
              return of({});
          }
        ))
        .subscribe(
          () => {
            this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
            this.gotoAuditDetail();
          }
        )
        .add(() => (this.isLoadingVisible = false));
    } else {
      const formData = new FormData();
      formData.append('comment', this.getComment);
      formData.append('location', this.getLocation);
      formData.append('fireSafetyAuditId', this.currentFireSafetyAuditId);
      formData.append('defectCategoryName', this.getDefectCategory);
      formData.append('defectCriticalityName', this.getDefectCriticality);
      formData.append('rectificationName', this.getRectification);
      if (this.isTakePhoto) {
        formData.append('isTakePhoto', 'true');
      }
      if (this.selectedFireSafetyMeasureId) {
        formData.append('fireSafetyMeasureId', this.selectedFireSafetyMeasureId);
      }
      this.listPictureSend.forEach(item => {
        formData.append('files', item);
      });

      let $submitOrQueue = this.offlineStatusService.latestStatusIsOffline.pipe(
        first(),
        switchMap(isOffline => {
          if (isOffline) {
            let fireSafetyItem = this.listMeasureButtons?.find(
              p => p.id === this.selectedFireSafetyMeasureId
            );
            if (fireSafetyItem) {
              formData.append('fireSafetyName', fireSafetyItem.fireSafetyName);
            }

            this.localStorageService.pushObservationFormData(formData, this.listPicturesTaken);
            return from([ResultState.Queued]);
          } else {
            return this.observationService.create(formData);
          }
        }),
        //using catchError here to intercept DOM error
        //the global error-interceptor.ts only handles http errors (API comms)
        catchError(err => {
          if (err instanceof CustomStorageError) {
            this.toastHandlerService.warning(err.message);
          } else {
            this.toastHandlerService.error(err);
          }
          return err;
        })
      );

      this.isLoadingVisible = true;
      $submitOrQueue
        .subscribe(
          (result) => {
            if(result === ResultState.Queued){
              this.toastHandlerService.showSuccess(`Observation ${PopUpMessages.Queued}. ${PopUpMessages.ObsUploadedWhenOnline}`, '');
            } else {
              this.toastHandlerService.showSuccess(`Observation ${PopUpMessages.CreateSuccess}`, '');
            }

            this.gotoAuditDetail();
          },
          error => (this.isLoadingVisible = false)
        )
        .add(() => (this.isLoadingVisible = false));
    }
  }

  gotoAuditDetail() {
    this.router.navigate([`/fire-safety-audits/detail/${this.currentFireSafetyAuditId}`]);
  }
  get getComment() {
    return this.observationForm.get('observationComment').value;
  }

  get getLocation() {
    return this.observationForm.get('location').value;
  }
  get getDefectCategory() {
    return this.observationForm.get('defectCategory').value;
  }
  get getDefectCriticality() {
    return this.observationForm.get('defectCriticality').value;
  }
  get getRectification() {
    return this.observationForm.get('rectification').value;
  }

  getObservationMapping() {
    this.observationDataService.getDropDownListObservation().subscribe(res => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.mappingComment.set(res[i].fireSafetyMeasure.toLowerCase(), res[i].observationMappings);
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
