import { Injectable } from '@angular/core';
import { CustomStorageError } from '@shared/models/CustomErrors';
import { ObservationQueueDto } from '@shared/models/ObservationQueueDto';
import { ApiException, CreateObservationDto, ICreateObservationDto } from '@shared/tc-api-model';
import { BreadCrumb } from '@shared/utils/breadcrumb';
import { TandmUtil } from '@shared/utils/tandm-util';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}
  breadcrumbsStorageKey: string = 'breadcrumbsItems';
  storeItem(item: BreadCrumb) {
    let items: BreadCrumb[];

    if (localStorage.getItem(this.breadcrumbsStorageKey) === null) {
      items = [];
      items.push(item);
      this.setItem(this.breadcrumbsStorageKey, JSON.stringify(items));
    } else {
      items = JSON.parse(localStorage.getItem(this.breadcrumbsStorageKey));

      const isItemsChild = items.some(child => {
        return `${child.url}` === `${item.url}`;
      });

      //Avoid adding an existing link to the bradcrumbs

      if (!isItemsChild) {
        items.push(item);
        this.setItem(this.breadcrumbsStorageKey, JSON.stringify(items));
      } else if (`${items[items.length - 1].url}` !== `${item.url}`) {
        //avoid on refresh that last link is removed
        let itemIndex = items.findIndex(x => x.url === `${item.url}`); //removing tailing items
        items.splice(itemIndex + 1, items.length);
        this.setItem(this.breadcrumbsStorageKey, JSON.stringify(items));
      }
    }
  }

  getItemsFromStorage() {
    let items: BreadCrumb[];

    if (localStorage.getItem(this.breadcrumbsStorageKey) === null) {
      items = [];
    } else {
      items = JSON.parse(localStorage.getItem(this.breadcrumbsStorageKey));
    }
    return items;
  }

  clearItems() {
    let items = JSON.parse(localStorage.getItem(this.breadcrumbsStorageKey));
    items = [];
    this.setItem(this.breadcrumbsStorageKey, JSON.stringify(items));
  }

  obsKey = 'observationStorage';

  getObservationList() {
    let items: ObservationQueueDto[];

    if (localStorage.getItem(this.obsKey) === null) {
      items = [];
    } else {
      items = JSON.parse(localStorage.getItem(this.obsKey));
    }
    return items;
  }

  pushObservationFormData(data: FormData, filesAsDataUri: any[]) {
    let obj = this.formDataToObject(data);
    let dto = CreateObservationDto.fromJS(obj);

    let queueDto = new ObservationQueueDto(dto);
    //for display only so that the observation queue page don't have to retrieve observations to show this value
    queueDto.fireSafetyName = obj.fireSafetyName;

    queueDto.queuedDate = new Date();
    queueDto.retry = 0;
    //NOTE - exception for files, as when in formData they are already in 'File' format which isn't supported to stringify
    queueDto.files = filesAsDataUri;

    this.pushObservation(queueDto);
  }

  pushObservation(dto: ObservationQueueDto) {
    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      dto.queueId = this.getNewQueueId(lst);
      lst = [dto];
      this.setItem(this.obsKey, JSON.stringify(lst));
    } else {
      lst = JSON.parse(lstObj);

      const exist = lst.some(child => {
        return this.compareObsv(child, dto);
      });

      if (!exist) {
        dto.queueId = this.getNewQueueId(lst);
        console.log(JSON.stringify(dto));
        lst.push(dto);
        this.setItem(this.obsKey, JSON.stringify(lst));
      }
    }
  }

  private getNewQueueId(lst: ObservationQueueDto[]) {
    if (!lst || !lst.length) return 0;

    return this.getMaxMinObsByQueueId(lst, true).queueId + 1;
  }

  private getMaxMinObsByQueueId(lst: ObservationQueueDto[], max: boolean) {
    if (lst && lst.length && lst.every(p => p.retry > 2)) return null;

    if (!lst || lst.length <= 0) return null;

    let latestObs = lst.reduce((prev, current) => {
      if (max) {
        return prev.queueId > current.queueId ? prev : current;
      } else {
        if (current.retry > 2 && prev.retry > 2)
          return prev.queueId < current.queueId ? prev : current;
        if (current.retry > 2) return prev;
        return prev.queueId < current.queueId ? prev : current;
      }
    });

    return latestObs;
  }

  getObservationByQueueId(queueId: number): ObservationQueueDto {
    if (queueId < 0) return;

    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      return null;
    }

    lst = JSON.parse(lstObj);
    let obs = lst.find(p => p.queueId === queueId);

    return obs;
  }

  deleteObservation(record: ObservationQueueDto): ObservationQueueDto[] {
    if (record.queueId != null && typeof record.queueId != 'undefined')
      return this.deleteObservationByQueueId(record.queueId);

    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      return [];
    }

    lst = JSON.parse(lstObj);
    let index = lst.findIndex(p => this.compareObsv(p, record));

    if (index >= 0) {
      let removed = lst.splice(index, 1);
      this.setItem(this.obsKey, JSON.stringify(lst));

      return lst;
    } else {
      throw new Error('Unable to find record to delete');
    }
  }

  deleteObservationByQueueId(queueId: number): ObservationQueueDto[] {
    if (queueId < 0) return;

    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      return [];
    }

    lst = JSON.parse(lstObj);
    let index = lst.findIndex(p => p.queueId === queueId);
    let removed = lst.splice(index, 1);

    this.setItem(this.obsKey, JSON.stringify(lst));

    return lst;
  }

  getObservationsEligibleToUpload(): ObservationQueueDto[] {
    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      return [];
    }

    lst = JSON.parse(lstObj);
    let obsToUpload = lst.filter(p => p.retry < 3);
    return obsToUpload;
  }

  getNextObservationToUpload(): ObservationQueueDto {
    let lst = this.getObservationsEligibleToUpload();
    let obsToUpload = this.getMaxMinObsByQueueId(lst, false);
    return obsToUpload;
  }

  hasObservationInQueue(): boolean {
    let lst: ObservationQueueDto[];
    let lstObj = localStorage.getItem(this.obsKey);
    if (lstObj === null) {
      return false;
    }

    lst = JSON.parse(lstObj);
    if (lst && lst.length) return true;
    return false;
  }

  private compareObsv(dto: CreateObservationDto, child: CreateObservationDto): boolean {
    let same =
      child.comment === dto.comment &&
      child.defectCategoryName === dto.defectCategoryName &&
      child.defectCriticalityName === dto.defectCriticalityName &&
      child.fireSafetyAuditId === dto.fireSafetyAuditId &&
      child.fireSafetyMeasureId === dto.fireSafetyMeasureId &&
      child.location === dto.location &&
      child.rectificationName === dto.rectificationName;
    return same;
  }

  private formDataToObject(formData: FormData): any {
    let object = {};
    formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if (!Reflect.has(object, key)) {
        object[key] = value;
        return;
      }
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]];
      }
      object[key].push(value);
    });

    return object;
  }

  setItem(key:string, value:string){
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      throw new CustomStorageError('Offline storage quota exceeded, please upload the existing offline data to free up space.');
    }
  }
}

