import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ContactService } from '@core/service/contact.service';
import { MtxDialog } from '@ng-matero/extensions';
import { IImportResult, ImportResult } from '@shared/utils/import-result';
import { Utils } from '@shared/utils/utils';

@Component({
  selector: 'app-import-contact-data',
  templateUrl: './import-contact-data.component.html',
  styleUrls: ['./import-contact-data.component.scss']
})
export class ImportContactDataComponent implements OnInit {

  selectedFiles: FileList;
  currentFileUpload: File;
  currentCompanyId: any;
  @ViewChild(MatProgressBar) matProgress: MatProgressBar;
  loading = false;


  constructor(private dialogRef: MatDialogRef<ImportContactDataComponent>,
    private contactService: ContactService,
    public dialog: MtxDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentCompanyId = this.data.currentCompanyId;
  }

  ngOnInit(): void {
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0);
  }

  importExcel() {
    if (!this.selectedFiles) {
      this.dialog.alert('Please choose an excel file (.xlsx)');
      return;
    }
    const formData = new FormData();
    formData.append('File', this.currentFileUpload);
    formData.append('CompanyId', this.currentCompanyId);

    this.loading = true;
    this.contactService.importFromExcel(formData).subscribe((res: IImportResult) => {
      var data = new ImportResult(res);
      if (!data.hasError)
        Utils.OpenOkDialog(this.dialog, 'Contact data imported successfully!');
      else
        Utils.OpenOkDialog(this.dialog, data.getSummary(), 'Import Result');

      this.dialogRef.close("refresh");
    }).add(()=> this.loading = false);
  }

}

