<div class="dialog-header">
  <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  <div class="sm-screen">
    <button mat-icon-button color="primary" (click)="onCancel()" aria-label="Cancel">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content *ngIf="bookmarkLimitFull" class="info-message">
  <p>Your bookmark list has reached its limit and it's not possible to add new bookmarks.</p>
  <p>
    To make space for new bookmarks, you can start by removing the ones you no longer need. Simply
    click on the star icon of a bookmarked page and select the remove option.
  </p>
</div>
<div mat-dialog-actions *ngIf="bookmarkLimitFull">
  <button
    mat-stroked-button
    color="primary"
    (click)="onCancel()"
    class="cancel-btn"
    aria-label="Cancel"
  >
    Cancel
  </button>
</div>
<div mat-dialog-content *ngIf="!bookmarkLimitFull">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="name" />
  </mat-form-field>
</div>
<div mat-dialog-actions *ngIf="!bookmarkLimitFull">
  <button
    mat-stroked-button
    color="primary"
    (click)="onRemove()"
    title="Remove bookmark"
    aria-label="Remove bookmark"
  >
    Remove
  </button>
  <div>
    <div class="lg-screen">
      <button
        mat-stroked-button
        color="primary"
        (click)="onCancel()"
        class="cancel-btn"
        aria-label="Cancel"
      >
        Cancel
      </button>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="onSave()"
      class="primary-btn"
      title="Add new bookmark"
      aria-label="Add new bookmark"
    >
      {{ mainBtnText }}
    </button>
  </div>
</div>
