<mat-card-title>
    {{mode === MODE.CREATE ? 'Start audit' : 'Edit audit'}}
</mat-card-title>
<form [formGroup]="safetyAuditForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Inspection Due Date</mat-label>
                <input matInput [matDatepicker]="picker1" placeholder="Choose a date"
                required formControlName="inspectByDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="safetyAuditForm.get('inspectByDate')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Date of Inspection</mat-label>
                <input matInput [matDatepicker]="picker2" placeholder="Choose a date"
                required formControlName="inspectDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="safetyAuditForm.get('inspectDate')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Certificate Due Date</mat-label>
                <input matInput [matDatepicker]="picker3" placeholder="Choose a date"
                 formControlName="certDueDate">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error *ngIf="safetyAuditForm.get('certDueDate')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Fire Assessor</mat-label>
                <mat-select formControlName="fireUserId">
                    <mat-option *ngFor="let user of fireAssessors | async" [value]="user.id">
                        {{(user?.firstName ? user?.firstName : "") + " " +( user?.lastName ? user?.lastName : "") + " (" + user.email + ")"}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="safetyAuditForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>