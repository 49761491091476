import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FIREROLE, MODE } from '@core/app.constants';
import { FireUserService } from '@core/service/fire-user.service';
import { numberOnlyValidator } from '@shared/utils/validation.util';
@Component({
  selector: 'app-create-edit-site',
  templateUrl: './create-edit-site.component.html',
  styleUrls: ['./create-edit-site.component.scss']
})
export class CreateEditSiteComponent implements OnInit {

  siteForm: FormGroup;
  mode;
  MODE = MODE;
  FIREROLE = FIREROLE;
  currentCompanyId: any;
  fireManagers: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditSiteComponent>,
    private fb: FormBuilder,
    private fireUserService: FireUserService
  ) {
    this.mode = this.data.mode;
    this.currentCompanyId = this.data.currentCompId;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.mode === MODE.EDIT) {
      this.siteForm.patchValue(this.data.record);
    }
     this.fireUserService.getUserByRole(FIREROLE.FireManager).subscribe(res => {
      if (res) {
        this.fireManagers = res;
      }
    });
  }

  initForm() {
    this.siteForm = this.fb.group({
      internalSiteId: ['', [Validators.required]],
      siteName: [''],
      street: [''],
      city: [''],
      postCode: ['', [Validators.minLength(4)]],
      fireUserId: []
    });
  }
  saveAndClose() {
    const formValue = this.siteForm.value;
    formValue.companySiteId = this.currentCompanyId;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }

  onlyNumberCheck(event, type: 'paste' | 'keyPress') {
    if (type === 'paste') {
      const clipboardData = event.clipboardData || window['clipboardData'];
      if (clipboardData) {
        const value = clipboardData.getData('Text');
        if (!numberOnlyValidator(value)) {
          event.preventDefault();
        }
      }
    }
    if (type === 'keyPress') {
      if (!numberOnlyValidator(event.key)) {
        event.preventDefault();
      }
    }
  }

}
