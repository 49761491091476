import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class FireSafetyAuditService extends BaseApiService {
  constructor(http: HttpClient) {
    super(http);
  }
  changeUrl() {
    return 'FireSafetyAudit';
  }
  public getBySiteId(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/getBySiteId?id=' + id);
  }

  public getFireSafetyAuditButtonsById(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/getFireSafetyMeasure?id=' + id);
  }
  public generateReport(id: any) {
    var url = `${this.rootUrl}/generateReport?auditId=${id}`;
    return this.http.get(url, { observe: 'response', responseType: "blob"});
  }
}
