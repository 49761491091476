import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { FireSafetyAuditClient, FireSafetyAuditDetailDto, FireSafetyAuditDto } from '@shared/tc-api-model';

@Component({
  selector: 'app-import-previous-year-observations',
  templateUrl: './import-previous-year-observations.component.html',
  styleUrls: ['./import-previous-year-observations.component.scss'],
})
export class ImportPreviousYearObservationsComponent implements OnInit {
  items: FireSafetyAuditDto[] = [];
  selectedItem: FireSafetyAuditDto;
  @Input() fireSafetyAuditId: string;

  constructor(
    public dialogRef: MatDialogRef<ImportPreviousYearObservationsComponent>,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private fireSafetyAuditClient: FireSafetyAuditClient,
    private http: HttpClient
  ) {

  }

  ngOnInit(): void {
    this.fireSafetyAuditClient.getLastYear(+this.fireSafetyAuditId, undefined).subscribe(data => {
      this.items = data;
    });}

  formatDate(item: FireSafetyAuditDto) {
    const dateObj = new Date(item.inspectDate);
    const formattedDate = this.datePipe.transform(dateObj, 'dd-MM-yyyy');
    return formattedDate;
  }

  close() {
    this.dialogRef.close(null);
  }

  import() {
    this.dialogRef.close(this.selectedItem);
  }
}
