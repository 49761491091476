import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { AdminLayoutComponent } from '../theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '../theme/auth-layout/auth-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { AuthGuard } from '@core';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyDetailComponent } from './companies/company-detail/company-detail.component';
import { MinStandardPerfsComponent } from './min-standard-perfs/min-standard-perfs.component';
import { SiteDetailComponent } from './sites/site-detail/site-detail.component';
import { FireSafetyAuditDetailComponent } from './fire-safety-audit/fire-safety-audit-detail/fire-safety-audit-detail.component';
import { SubmitObservationComponent } from './observations/submit-observation/submit-observation.component';
import { FireUserComponent } from './fire-user/fire-user.component';
import { ObservationDatabaseComponent } from './observation-database/observation-database.component';
import { ObservationDatabaseDetailComponent } from './observation-database/observation-database-detail/observation-database-detail.component';
import { EulaComponent } from './eula/eula.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ObservationsQueueComponent } from './observations/observations-queue/observations-queue.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: { breadcrumb: null},
    canActivateChild: [AuthGuard],
    children: [
      //{ path: '', redirectTo: 'companies', pathMatch: 'full' },
      { path: '', data: {title: 'Companies', breadcrumb: ''}, component: CompaniesComponent },
      { path: 'companies', data: {title: 'Companies', breadcrumb: ''}, component: CompaniesComponent },
      { path: 'companies/detail/:id', data: {title: 'Company Detail', breadcrumb: 'Company Detail'}, component: CompanyDetailComponent },
      { path: 'sites/detail/:id', data: {title: 'Site Detail',  breadcrumb: 'Site Detail'}, component: SiteDetailComponent },
      { path: 'fire-safety-audits/detail/:id', data: {title: 'Fire Safety Audit Detail',  breadcrumb: 'Fire Safety Audit Detail'}, component: FireSafetyAuditDetailComponent },
      { path: 'min-standards-of-performance', component: MinStandardPerfsComponent },
      { path: 'fire-safety-audits/:fireSafetyAuditId/submit-observation/:mode/:observationId', data: {title: 'Edit Observation', breadcrumb: 'Edit Observation'}, component: SubmitObservationComponent},

      { path: 'offline-observations',
        data: {title: 'Offline Observation', breadcrumb: 'Offline Observation'}, component: ObservationsQueueComponent
      },
      { path: 'fire-safety-audits/:fireSafetyAuditId/submit-observation/:mode',  data: {title: 'Create Fire Safety Observation'}, component: SubmitObservationComponent},
      { path: 'fire-safety-audits/:fireSafetyAuditId/submit-photo/:mode', data: {title: 'Create Fire Safety Observation'}, component: SubmitObservationComponent},
      { path: 'fire-safety-audits/:fireSafetyAuditId/submit-photo/:mode/:observationId', data: {title: 'Edit Observation', breadcrumb: 'Edit Observation'}, component: SubmitObservationComponent},
      { path: 'staff', data: {title: 'Staff'}, component: FireUserComponent },
      { path: 'observation-database', data: {title: 'Observation Database'}, component: ObservationDatabaseComponent },
      { path: 'observation-database/detail/:id', data: {title: 'Observation Database Detail', breadcrumb: 'Observation Database Detail'},  component: ObservationDatabaseDetailComponent },
      {
        path: 'sessions',
        loadChildren: () => import('./sessions/sessions.module').then(m => m.SessionsModule),
      }
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', data: { title: 'Login'},  component: LoginComponent },
      { path: 'register', data: { title: 'Register'}, component: RegisterComponent },
    ],
  },
  { path: 'eula', data: { title: 'EULA'}, component: EulaComponent },
  { path: 'privacy-policy', data: {title: 'Privacy Policy'}, component: PrivacyComponent },
  { path: '**', redirectTo: 'companies' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
