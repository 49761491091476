import { Component, OnInit } from '@angular/core';
import { MODE } from '@core/app.constants';
import { MinStandradPertfService } from '@core/service/min-standrad-pertf.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { CreateEditMinStandardComponent } from './create-edit-min-standard/create-edit-min-standard.component';

@Component({
  selector: 'app-min-standard-perfs',
  templateUrl: './min-standard-perfs.component.html',
  styleUrls: ['./min-standard-perfs.component.scss'],
})
export class MinStandardPerfsComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Name',
      field: 'mspName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this minimum standard of performance?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];

  list: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = false;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private minStandradPertfService: MinStandradPertfService,
    public dialog: MtxDialog
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditMinStandardComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.minStandradPertfService.update(data))
      )
      .subscribe(data => {
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditMinStandardComponent, {
      width: '600px',
      data: { mode: MODE.CREATE },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.minStandradPertfService.create(data))
      )
      .subscribe(() => {
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.minStandradPertfService.getAll().subscribe(res => {
      this.list = res;
      this.isLoading = false;
    });
  }
  delete(value: any) {
    this.minStandradPertfService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`You have deleted ${value.mspName}!`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }
}
