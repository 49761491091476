import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends BaseApiService {

    constructor(
        http: HttpClient
    ) {
        super(http);
    }
    changeUrl() {
        return 'Company';
    }
    public importFromExcel(item: any): Observable<any> {
        return this.http.post<any>(this.rootUrl + '/ImportFromExcel', item);
    }

}