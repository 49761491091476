<mat-card-title>
    {{mode === MODE.CREATE ? 'Create ' : 'Edit'}}
</mat-card-title>
<form [formGroup]="fireSafetyMappingForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Fire Safety Measure</mat-label>
                <input matInput placeholder="Input fire safety measure" formControlName="fireSafetyMeasure"
                       required>
                <mat-error *ngIf="fireSafetyMappingForm.get('fireSafetyMeasure')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Standard</mat-label>
                <input matInput placeholder="Input standard" formControlName="standard">
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn"mat-button
        [disabled]="fireSafetyMappingForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>