import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MODE } from '@core/app.constants';
import { PERMISSIONS } from '@core/app.permissions';
import { CompanyService } from '@core/service/company.service';
import { FireSafetyScheduleService } from '@core/service/fire-safety-schedule.service';
import { PermissionStateService } from '@core/service/permission-state.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { link } from 'fs';
import { Page } from '@shared/utils/page';
import { filter, switchMap } from 'rxjs/operators';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditCompanyComponent } from './create-edit-company/create-edit-company.component';
import { ImportCompanyDataComponent } from './import-company-data/import-company-data.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;
  columns: MtxGridColumn[] = [
    {
      header: 'Name',
      field: 'companyName',
      sortable: true,
      minWidth: 100,
      class: 'company-name',
    },
    {
      header: 'Street',
      field: 'street',
      minWidth: 100,
    },
    {
      header: 'City',
      field: 'city',
      minWidth: 100,
    },
    {
      header: 'PostCode',
      field: 'postCode',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this company?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  list: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  canCreate: boolean;
  canDeleteAndUpdate: boolean;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private companyService: CompanyService,
    public dialog: MtxDialog,
    private permissionStateService: PermissionStateService,
    private router: Router,
    private toastHandlerService: ToastHandlerService
  ) {}

  ngOnInit() {
    this.getData();
    this.checkPermission();
  }

  checkPermission() {
    this.canCreate = this.permissionStateService.hasPermission(PERMISSIONS.CREATE_COMPANY);
    this.canDeleteAndUpdate = this.permissionStateService.hasPermission(PERMISSIONS.UPDATE_COMPANY);
    if (!this.canDeleteAndUpdate) {
      this.columns.pop();
    }
  }

  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditCompanyComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.companyService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditCompanyComponent, {
      width: '600px',
      data: { mode: MODE.CREATE },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.companyService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(`Company ${PopUpMessages.CreateSuccess}` , '');
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.companyService.getAll().subscribe(res => {
      this.list = res;
      this.isLoading = false;
    });
  }
  delete(value: any) {
    this.companyService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`${value.companyName} ${PopUpMessages.DeleteSuccess}`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  enableRowExpandable() {
    this.columns[0].showExpand = this.expandable;
  }

  showDetail(row: any) {
    this.router.navigate([`/companies/detail/${row.rowData.id}`]);
  }

  importExcel() {
    const dialog = this.dialog.originalOpen(ImportCompanyDataComponent, {
      width: '500px',
    });
    dialog.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.getData();
      }
    });
  }
}
