import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MODE } from '@core/app.constants';
import { numberOnlyValidator } from '@shared/utils/validation.util';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-edit-company',
  templateUrl: './create-edit-company.component.html',
  styleUrls: ['./create-edit-company.component.scss']
})
export class CreateEditCompanyComponent implements OnInit {

  companyForm: FormGroup;
  searchContact: FormControl = new FormControl('');
  mode;
  filteredContacts;
  MODE = MODE;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditCompanyComponent>,
    private fb: FormBuilder,
  ) { 
    this.mode = this.data.mode;
  }

  ngOnInit(): void {
    this.initForm();
    if(this.mode === MODE.EDIT) {
      this.companyForm.patchValue(this.data.record);
    }
  }

  initForm() {
    this.companyForm =  this.fb.group({
      companyName: ['', [Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postCode: ['', [Validators.required, Validators.minLength(4)]]
    });
  }
  saveAndClose() {
    const formValue = this.companyForm.value;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }

  onlyNumberCheck(event, type: 'paste' | 'keyPress') {
    if (type === 'paste') {
      const clipboardData = event.clipboardData || window['clipboardData'];
      if (clipboardData) {
        const value = clipboardData.getData('Text');
        if (!numberOnlyValidator(value)) {
          event.preventDefault();
        }
      }
    }
    if (type === 'keyPress') {
      if (!numberOnlyValidator(event.key)) {
        event.preventDefault();
      }
    }
  }
}
