import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService {
  protected baseUrl = environment.apiUri;
  protected get rootUrl() {
    return this.baseUrl + '/api/' + this.changeUrl();
  }
  protected http: HttpClient;
  constructor(http: HttpClient) {
    this.http = http;
  }

  abstract changeUrl();

  protected getUrl(url: string) {
    return this.rootUrl + '/' + url;
  }

  public getAll(): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetAll');
  }

  public getById(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/Get?id=' + id);
  }

  public delete(id: any): Observable<any> {
    return this.http.delete<any>(this.rootUrl + '/Delete', {
      params: new HttpParams().set('Id', id)
    });
  }

  public update(item: any): Observable<any> {
    return this.http.put<any>(this.rootUrl + '/Update', item);
  }

  public create(item: any): Observable<any> {
    return this.http.post<any>(this.rootUrl + '/Create', item);
  }

}
