<div class="all_dialog">
    <div class="header_h">
        <div class="h_drag_and_drop">
            <h4>Import observation database</h4>
        </div>
    </div>
    <div style="padding-top: 10px; border-top: 1px solid #eee;">
        <input type="file" class="form-group" id="file" (change)="selectFile($event)"
        accept=".xlsx">
    </div>
    <mat-progress-bar mode="indeterminate" id="matProgress" *ngIf="loading"></mat-progress-bar>
    <div style="margin-top: 30px;">
        <button mat-flat-button color="primary" class="btn_accept" [disabled]="loading" (click)="importExcel()">Import</button>
        <button mat-raised-button style="margin-left: 10px;" mat-dialog-close>Cancel</button>
    </div>
</div>
