<mat-card-title>
    {{mode === MODE.CREATE ? 'Create ' : 'Edit'}}
</mat-card-title>
<form [formGroup]="fireUserForm" class="form-field-full" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutGap="8px grid" class="mt-4">
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput placeholder="Input first name" formControlName="firstName">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput placeholder="Input last name" formControlName="lastName">
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Mobile</mat-label>
                <input matInput placeholder="Input mobile" formControlName="mobile">
                <mat-icon matSuffix>smartphone</mat-icon>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput placeholder="Input email" formControlName="email" required>
                <mat-error *ngIf="fireUserForm.get('email')?.invalid">
                    {{getErrorMessage(fireUserForm) | translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100">
            <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select formControlName="role" required>
                    <mat-option *ngFor="let role of roles" [value]="role.value">
                      {{role.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="fireUserForm.get('roleId')?.invalid">
                    {{'validations.required' | translate}}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div align="end" class="mat-dialog-actions" mat-dialog-actions="">
        <button class="btn btn-default cancel-banks" mat-raised-button
                mat-dialog-close>Cancel</button>
        <button mat-raised-button color="primary" class="saving-btn" mat-button
                [disabled]="fireUserForm.invalid" (click)="saveAndClose()">Save</button>
    </div>
</form>