<div *ngIf="!isError" style="text-align:center">
    <div>
        <webcam class="camera" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"></webcam>
        <br />

    </div>
</div>

<div *ngIf="isError" style="text-align:center;">
    <div *ngFor="let error of errors">
        <div style="margin-bottom: 10px; color: red;">{{ error.message }}</div>
    </div>
</div>

<div class="btn-section">
    <button class="mr-2"
            *ngIf="!isError"
            mat-raised-button
            color="green"
            (click)="triggerSnapshot()"
            >Take Picture</button>
    <button for="file-upload" mat-raised-button
            color="green"
            mat-button (click)="choseFile.click()">
        Upload Photo
    </button>
    <input hidden #choseFile type="file" (change)="fileBrowseHandler($event)">
</div>