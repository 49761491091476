<!-- <app-observations [observation]="observations"></app-observations> -->
<mtx-grid [data]="observations"
          [columns]="columns"

          [columnResizable]="false"
          [multiSelectable]="false"
          [rowSelectable]="true"
          [hideRowSelectionCheckbox]="true"
          [rowHover]="true"
          [rowStriped]="true"
          [showColumnMenuButton]="false"
          [showToolbar]="true"
          [toolbarTitle]="'Offline Observations'"
          [columnHideable]="false"
          [columnMovable]="false"
          [expandable]="true"
          [pageOnFront]="true"
          [showPaginator]="true"
          [pageSizeOptions]="pageOptions"
          [pageIndex]="0"
          [pageSize]="pageSize"
          [expansionTemplate]="expansionTpl"
          >
</mtx-grid>
<ng-template #expansionTpl let-row>
  {{row.lastErrorMsg ? 'Last Error Message:' : ''}}{{row.lastErrorMsg}}
  Retries: {{row.retry}}

</ng-template>
<!-- [loading]="isLoading" -->
<!-- (sortChange)="changeSort($event)"
          (selectionChange)="changeSelect($event)"
          (page)="changePage($event)" -->
