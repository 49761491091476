import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { MODE } from '@core/app.constants';
import { ContactService } from '@core/service/contact.service';
import { PermissionStateService } from '@core/service/permission-state.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { Page } from '@shared/utils/page';
import { CreateEditCompanycontactComponent } from './create-edit-companycontact/create-edit-companycontact.component';
import { ImportContactDataComponent } from './import-contact-data/import-contact-data.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-companycontacts',
  templateUrl: './companycontacts.component.html',
  styleUrls: ['./companycontacts.component.scss'],
})
export class CompanycontactsComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'First Name',
      field: 'firstName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Last Name',
      field: 'lastName',
      minWidth: 100,
    },
    {
      header: 'Mobile',
      field: 'mobile',
      minWidth: 180,
    },
    {
      header: 'Email',
      field: 'email',
      minWidth: 180,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this contact?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  @Input() companyContact: any[] = [];

  isLoading = true;
  isDisable = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentCompanyId: any;
  constructor(
    private contactService: ContactService,
    private cdr: ChangeDetectorRef,
    public dialog: MtxDialog,
    private permissionStateService: PermissionStateService,
    private route: ActivatedRoute,
    private toastHandlerService: ToastHandlerService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(p => {
      this.currentCompanyId = p.get('id');
    });
    this.isLoading = false;
    this.isDisable = this.companyContact.length === 0 ? false : true;
  }

  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditCompanycontactComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.contactService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditCompanycontactComponent, {
      width: '600px',
      data: {
        mode: MODE.CREATE,
        currentCompId: this.currentCompanyId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.contactService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(`Company Contact ${PopUpMessages.CreateSuccess}`, '');
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.contactService.getByCompanyId(this.currentCompanyId).subscribe(
      res => {
        this.companyContact = res;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );
  }
  changePage(event: PageEvent) {}
  delete(value: any) {
    this.contactService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(
        `${value.firstName} ${value.lastName} ${PopUpMessages.DeleteSuccess}`,
        ''
      );
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  importExcel() {
    const dialog = this.dialog.originalOpen(ImportContactDataComponent, {
      width: '500px',
      data: {
        currentCompanyId: this.currentCompanyId,
      },
    });
    dialog.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        this.getData();
      }
    });
  }
}
