import { Component, OnInit } from '@angular/core';
import { ObservationService } from '@core/service/observation.service';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { MtxGridColumn } from '@ng-matero/extensions/data-grid';
import { ObservationQueueDto } from '@shared/models/ObservationQueueDto';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { FireSafetyAuditDetailDto, FireSafetyAuditDto, ObservationClient } from '@shared/tc-api-model';
import { Page } from '@shared/utils/page';

@Component({
  selector: 'app-observations-queue',
  templateUrl: './observations-queue.component.html',
  styleUrls: ['./observations-queue.component.scss'],
})
export class ObservationsQueueComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Fire Safety Measure',
      field: 'fireSafetyName',
      sortable: true,
      showExpand: true,
      minWidth: 100,
    },
    {
      header: 'Comment',
      field: 'comment',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Defect Category',
      field: 'defectCategoryName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Defect Criticality',
      field: 'defectCriticalityName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Rectification',
      field: 'rectificationName',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Location',
      field: 'location',
      sortable: true,
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Delete this observation from the queue?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
        {
          type: 'icon',
          icon: 'publish',
          tooltip: 'Upload',
          click: record => this.upload(record)
        },
      ],
    },
  ];

  constructor(private localStorageService: LocalStorageService,
    private toast: ToastHandlerService,
    private observationService: ObservationService) {}

  observations: ObservationQueueDto[];

  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;

  ngOnInit(): void {
    this.observations = this.localStorageService.getObservationList();
  }

  delete(record: ObservationQueueDto) {
    this.observations = this.localStorageService.deleteObservation(record);
    return;
  }

  upload(record: ObservationQueueDto){
    let dto = new ObservationQueueDto(record);
    let formData = dto.toFormData();
    this.observationService.create(formData).subscribe(data => {
      this.observations = this.localStorageService.deleteObservation(record);
      this.toast.showSuccess("Observation Uploaded",``);
    },
    error => {
      //pop and reinsert with updated retry data
      this.localStorageService.deleteObservation(dto);
      dto.lastAttemptDate = new Date();
      dto.lastErrorMsg = error;
      dto.retry++;
      this.localStorageService.pushObservation(dto);
    }
    );
  }
}
