import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfflineStatusService } from '@core/service/offline-status.service';
import { appVersion } from '@env/environment';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { Observable, Subject } from 'rxjs';
import * as screenfull from 'screenfull';

@Component({
  selector: 'app-header',
  host: {
    class: 'matero-header',
  },
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Input() showToggle = true;
  @Input() showBranding = false;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Output() toggleSidenavNotice = new EventEmitter<void>();


  private get screenfull(): screenfull.Screenfull {
    return screenfull as screenfull.Screenfull;
  }

  isOffline$: Subject<boolean>;
  versionString = appVersion.version;
  hasQueue = false;

  constructor(
    private offlineStatusService: OfflineStatusService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService) {
    this.isOffline$ = offlineStatusService._statusIsOfflineChanged;

    this.route.params.subscribe( data => {
      this.hasQueue = this.localStorageService.hasObservationInQueue();

    });
  }

  ngOnInit() {
  }

  toggleFullscreen() {
    if (this.screenfull.isEnabled) {
      this.screenfull.toggle();
    }
  }
}
