export interface IImportResult {
  Name:string;
  Imported:number;
  Total:number;
  ErrorMsg:string;
  ValidationErrors: [{Record: number, Message:string, ItemName: string}];
}

export class ImportResult implements IImportResult{

  constructor(data: IImportResult){
    this.Name = data.Name;
    this.Imported = data.Imported;
    this.Total = data.Total;
    this.ErrorMsg = data.ErrorMsg;
    this.ValidationErrors = data.ValidationErrors;
  }

  Name:string;
  Imported:number;
  Total:number;
  ErrorMsg:string;
  ValidationErrors: [{Record: number, Message:string, ItemName: string}];

  public get hasError():boolean {
    return !!this.ErrorMsg || (!this.ValidationErrors || !!this.ValidationErrors.length);
  }

  detailResult: ImportResult;

  //note: newlines in the description is important! don't format/tidy
  getSummary(): string {
    var data = this;
    return `${data.Imported} record(s) out of ${data.Total} imported.
    ` + `
    ` +
    (!!data.ValidationErrors && data.ValidationErrors.length ?
      data.ValidationErrors.map(p => `Row ${p.Record} '${p.ItemName}' skipped: ${p.Message}`).join(`
      `)
      : '') +
      `${!!data.detailResult ?
      `
      ` +
      this.getDetailSummary(data.detailResult)
      : ''}`;
  }

  private getDetailSummary(data: ImportResult): string {
    return `${data.Name ? data.Name : "Detail Item"} Result:
    ` + `
    ` +
    `${data.Imported} record(s) out of ${data.Total} imported.
    `+
    (!!data.ValidationErrors && data.ValidationErrors.length ? `
    ` +
      data.ValidationErrors.map(p => `Row ${p.Record} '${p.ItemName}' skipped: ${p.Message}`).join(`
      `)
      : '');
  }
}
