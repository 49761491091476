<mat-card>
  <button mat-flat-button class="back-button" title="Back to previous page" (click)="openDialog()">
    <mat-icon [ngStyle]="{ color: 'black' }" style="font-size: 20px">arrow_back</mat-icon>
    <span> Back </span>
  </button>
  <mat-card-title>
   {{ isEdit ? 'Edit ' : 'Create'}}
  </mat-card-title>
  <section *ngIf="!isTakePhoto">
    <mat-grid-list role="list" cols="{{columns}}" [rowHeight]="ratio" gutterSize="4px">
      <mat-grid-tile *ngFor="let item of listMeasureButtons"
        [style.background]="item.id === selectedFireSafetyMeasureId ? '#2196F3' : 'lightblue'"
        role="listitem"
        (click)="selecteButton(item)"
      >
        <figure class="mat-figure">
        {{item.fireSafetyName}}
        </figure>
      </mat-grid-tile>
    </mat-grid-list>
  </section>

  <section class="saved-photo" *ngIf="isEdit">
    <div *ngFor="let item of listPictureSaved; let i = index">
      <div class="photo-preview">
        <div class="photo-action-btns">
          <a href="{{ item }}" download="picture{{ i }}.jpg" class="download-btn" (click)="$event.preventDefault();download(item, 'picture'+i+'.jpg')">
            Download<span class="material-icons">download</span>
          </a>
        </div>
        <img ng class="taken-photo" src="{{ item }}" />
      </div>
    </div>
  </section>
  <div  class="image-camera-section">
    <div *ngIf="listPicturesTaken && listPicturesTaken.length" class="image-area">
      <ng-container *ngFor="let item of listPicturesTaken; let i = index">
        <div class="photo-preview">
          <div class="photo-action-btns">
            <span class="material-icons" (click)="removePicture(i)" title="Remove">close</span>
            <a
              href="{{ item.imageAsDataUrl }}"
              download="picture{{ i }}.jpg"
              class="download-btn"
              title="Download this photo to avoid losing it in case of poor network coverage."
            >
              Download<span class="material-icons">download</span>
            </a>
          </div>
          <img ng class="taken-photo" [src]="item.imageAsDataUrl" alt="" />
        </div>
      </ng-container>
    </div>
    <div class="image-area" *ngIf="!listPicturesTaken.length">
      <span>{{isEdit ? "No new image" :"No image selected"}}</span>
    </div>
    <div class="camera-area">
      <div *ngIf="!isDisplayCamera" (click)="turnOnCamera()">
        <img src="\assets\icons\camera-icon.svg" alt="">
      </div>
      <app-camera
        *ngIf="isDisplayCamera"
        (pictureTaken)="handleImage($event)"
        (pictureChosen)="handleChosenImage($event)"></app-camera>
    </div>
  </div>

  <div class="observation-comment-field">
    <div class="field">
      <mat-form-field *ngIf="!isOtherButtonSelected">
        <mat-label>Observation comment</mat-label>
        <input matInput [matAutocomplete]="autocomplete"
               [formControl]="observationForm.controls.observationComment" [required]="!isTakePhoto">
        <mat-autocomplete #autocomplete="matAutocomplete">
          <mat-option *ngFor="let state of listObservationComment, let i = index" [value]="state" (click)="selectComment(i)">
            <span>{{ state }}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="observationForm?.get('observationComment')?.invalid">
          {{'validations.required' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="isOtherButtonSelected" appearance="standard">
        <mat-label>Observation comment</mat-label>
        <input matInput placeholder="Observation Comment"
               [formControl]="observationForm.controls.observationComment"  [required]="!isTakePhoto">
        <mat-error *ngIf="observationForm?.get('observationComment')?.invalid">
          {{'validations.required' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="field" *ngIf="!isTakePhoto">
    <mat-form-field>
      <mat-label>Defect category</mat-label>
      <mat-select [formControl]="observationForm.controls.defectCategory">
        <mat-option *ngFor= "let defect of defectCategories" [value]="defect">{{defect}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="field" *ngIf="!isTakePhoto">
    <mat-form-field>
      <mat-label>Defect criticality</mat-label>
      <mat-select [formControl]="observationForm.controls.defectCriticality">
        <mat-option *ngFor= "let defect of defectCriticalities" [value]="defect">{{defect}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
  <div class="field" *ngIf="!isTakePhoto">
    <mat-form-field *ngIf="!isOtherButtonSelected">
      <mat-label>Rectification</mat-label>
      <input matInput [formControl]="observationForm.controls.rectification ">
    </mat-form-field>
    <mat-form-field *ngIf="isOtherButtonSelected" appearance="standard">
      <mat-label>Rectification</mat-label>
      <input matInput placeholder="Rectification"[formControl]="observationForm.controls.rectification">
    </mat-form-field>
  </div>
  <div class="field">
    <mat-form-field appearance="standard">
      <mat-label>Location</mat-label>
      <input matInput placeholder="Location" [formControl]="observationForm.controls.location">
    </mat-form-field>
  </div>
  <div class="submit-button">
    <button mat-raised-button color="primary" [disabled]="observationForm.invalid || isLoadingVisible" mat-button (click)="submit()" class="mb-2" *ngIf="!isTakePhoto">Submit
      Observation
			<mat-icon *ngIf="isLoadingVisible"><mat-spinner color="primary" diameter="20">
			</mat-spinner></mat-icon></button>
    <button mat-raised-button color="primary" [disabled]="observationForm.invalid || isLoadingVisible" mat-button (click)="submit()" class="mb-2" *ngIf="isTakePhoto">Submit Photo
			<mat-icon *ngIf="isLoadingVisible"><mat-spinner color="primary" diameter="20">
			</mat-spinner></mat-icon>
    </button>
  </div>
</mat-card>

<ng-template #dialogTemplate>
  <h2 mat-dialog-title>Are you sure you want to leave this page?</h2>
  <mat-dialog-content> Any unsaved changes will be lost. </mat-dialog-content>
  <mat-dialog-actions>
    <button class="dialogBtn" mat-stroked-button color="primary" [mat-dialog-close]="'ok'">
      Leave
    </button>
    <button class="dialogBtn" mat-raised-button color="primary" mat-dialog-close>Stay</button>
  </mat-dialog-actions>
</ng-template>
