import { CreateObservationDto } from "@shared/tc-api-model";
import { TandmUtil } from "@shared/utils/tandm-util";

export class ObservationQueueDto extends CreateObservationDto {
  constructor(data: CreateObservationDto) {
    super(data);
    //Object.setPrototypeOf(this, CreateObservationDto.prototype);
  }
  public queueId: number;
  public fireSafetyName: string;
  public retry: number;
  public queuedDate: Date;
  public lastErrorMsg: string;
  public lastAttemptDate: Date;

  toJSON(data?: any) {
    data = super.toJSON(data);
    data['queueId'] = this.queueId;
    data['fireSafetyName'] = this.fireSafetyName;
    data['retry'] = this.retry;
    data['queuedDate'] = this.queuedDate;
    data['lastErrorMsg'] = this.lastErrorMsg;
    data['lastAttemptDate'] = this.lastAttemptDate;
    return data;
  }

  toFormData() {
    const formData = new FormData();
    formData.append('comment', this.comment);
    formData.append('location', this.location);
    formData.append('fireSafetyAuditId', this.fireSafetyAuditId?.toString());
    formData.append('defectCategoryName', this.defectCategoryName);
    formData.append('defectCriticalityName', this.defectCriticalityName);
    formData.append('rectificationName', this.rectificationName);
    if (this.isTakePhoto) {
      formData.append('isTakePhoto', 'true');
    }
    if (this.fireSafetyMeasureId) {
      formData.append('fireSafetyMeasureId', this.fireSafetyMeasureId?.toString());
    }
    this.files.forEach(item => {
      const file = TandmUtil.blobToFile(
        TandmUtil.dataURItoBlob((item as any).imageAsDataUrl || (item as any)._imageAsDataUrl),
        'file'
      );
      formData.append('files', file);
    });

    return formData;
  }
}
