import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ObservationService extends BaseApiService {
  public observationId = new BehaviorSubject(null);

  constructor(
    http: HttpClient
  ) {
    super(http);
  }
  changeUrl() {
    return 'Observation';
  }
  public getByFireSafetyAuditId(id: any): Observable<any> {
    return this.http.get<any>(this.rootUrl + '/GetByFireSafetyAuditId?id=' + id);
  }

 
}
