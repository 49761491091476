<page-header [title]="title" class="bg-blue-600"></page-header>
<mat-card>
    <mat-card-title>
        Site Detail
    </mat-card-title>
    <div class="content">
        <div class="left-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Project Number: {{site?.internalSiteId}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Site Name: {{site?.siteName}}
            </div>
        </div>
        <div class="middle-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Street: {{site?.street}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                City: {{site?.city}}
            </div>
        </div>
        <div class="right-col">
            <div class="mtx-grid-toolbar-title detail-text">
                Post Code: {{site?.postCode}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Fire Manager: {{site?.fireManagerName}}
            </div>
        </div>
    </div>
</mat-card>
<app-fire-safety-schedule *ngIf="!isLoading" [fireSafetySchedule]="fireSafetySchedule"></app-fire-safety-schedule>
<app-fire-safety-audit  *ngIf="!isLoading" [fireSafetyAudit]="fireSafetyAudit"></app-fire-safety-audit>
