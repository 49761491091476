import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-document',
  templateUrl: './Tandm_Compliance_Privacy_Policy_Final.html',
})
export class PrivacyDocumentComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
