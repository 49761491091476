import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Bookmark } from '@shared/utils/bookmark';
import { BookmarkService } from '@shared/services/bookmark.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Component({
  selector: 'app-bookmarks-dropdown',
  templateUrl: './bookmarks-dropdown.component.html',
  styleUrls: ['./bookmarks-dropdown.component.scss'],
})
export class BookmarksDropdownComponent implements OnInit, OnDestroy {
  links: { name: string; url: string; isActive: boolean }[] = [];
  public bookmark: Bookmark | null = null;
  bookmarks: Bookmark[] = [];
  name: string;
  url: string;

  private bookmarksChangedSubscription: Subscription;

  constructor(
    private bookmarkService: BookmarkService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    // Get the bookmarks from local storage using the bookmarkService
    this.bookmarks = this.bookmarkService.getBookmarks();
    this.links = this.bookmarks.map((bookmark: Bookmark) => ({
      name: bookmark.name,
      url: bookmark.url,
      isActive: false,
      id: bookmark.id,
    }));

    // Subscribe to bookmarksChanged event emitter
    this.bookmarksChangedSubscription = this.bookmarkService.bookmarksChanged.subscribe(
      (bookmarks: Bookmark[]) => {
        this.bookmarks = bookmarks;
        this.links = this.bookmarks.map((bookmark: Bookmark) => ({
          name: bookmark.name,
          url: bookmark.url,
          isActive: false,
          id: bookmark.id,
        }));
      }
    );
  }

  resetBreadcrumb() {
    this.localStorageService.clearItems();
  }

  ngOnDestroy(): void {
    // Unsubscribe from bookmarksChanged event emitter
    this.bookmarksChangedSubscription.unsubscribe();
  }
}
