import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PreloaderService } from '@core';
import { FIREROLE } from '@core/app.constants';
import { PERMISSIONS } from '@core/app.permissions';
import { PermissionStateService } from '@core/service/permission-state.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { FireUserService } from '@core/service/fire-user.service';
import { filter, map, take, distinctUntilChanged, switchMap, catchError, takeWhile, takeUntil, tap, delay, concatMap } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { MenuService } from '@core/bootstrap/menu.service';
import { BreadCrumb } from '@shared/utils/breadcrumb';
import { OfflineStatusService } from '@core/service/offline-status.service';
import { ToastrService } from 'ngx-toastr';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { ObservationService } from '@core/service/observation.service';
import { of, interval, Subject, never, NEVER, from } from 'rxjs';
import { ObservationQueueDto } from '@shared/models/ObservationQueueDto';
import { buildInfo, environment } from '@env/environment';
import { PopUpMessages } from '@shared/utils/messages';


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {
  breadcrumbs: BreadCrumb[] = [];
  constructor(
    private menu: MenuService,
    private preloader: PreloaderService,
    private localStorageService: LocalStorageService,
    private auth: Auth0Service,
    private permissionStateService: PermissionStateService,
    private offlineStatusService: OfflineStatusService,
    private toast: ToastHandlerService,
		private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private observationService: ObservationService,
    private fireUserService: FireUserService) {
      this.auth.isAuthenticated$.subscribe(res =>{
        if(res){
          this.auth.user$.pipe(take(1)).subscribe((user) => {
              const fireUserDto = {
                email: user.email,
                role: FIREROLE.FireAssessor
              }
              this.auth.getAccessTokenSilently().subscribe(token => {
                const tokenValue: any = jwt_decode(token);
                const permissions = tokenValue.permissions;
                if(permissions.includes(PERMISSIONS.CREATE_COMPANY)){
                  fireUserDto.role = FIREROLE.FireManager;
                }
                this.fireUserService.create(fireUserDto).subscribe();
              });
          });
        }
      });

      this.offlineStatusService.latestStatusIsOffline.subscribe(offline => {
        if(offline){
          this.toast.warning(PopUpMessages.StatusChangedOffline);
        } else {

          let self = this;
          let backOffline$ = this.offlineStatusService.statusIsOfflineChanged.pipe(switchMap(offline => {
            if(offline){
              return of(true);
            }
            return NEVER;
          }));

          let lstToUpload = this.localStorageService.getObservationsEligibleToUpload();

          if(lstToUpload && lstToUpload.length) {
            //run in sequence, continuing with next upload once previous is completed
            let uploadArr$ = from(lstToUpload).pipe(concatMap((toUpload) => {
                return this.uploadObservation(toUpload);
              }
            ));

            uploadArr$.pipe(
              takeUntil(backOffline$)
            )
            //using complete: instead of next: so the success popup only shows after all records are processed
            .subscribe({complete: () => {
              this.toast.showSuccess(PopUpMessages.ObservationsUploaded);
            }});
          }
        }
      });

      console.log(
        `\n%cBuild Info:\n\n` +
            `%c ❯ Environment: %c${environment.production ? "production 🏭" : "development 🚧"}\n` +
            `%c ❯ Build Version: ${buildInfo.version}-${buildInfo.sourceHash}\n` +
            ` ❯ Build Timestamp: ${buildInfo.buildDateString}\n` +
            ` ❯ Build Branch: %c${buildInfo.branch}\n`,
        "font-size: 14px; color: #7c7c7b;",
        "font-size: 12px; color: #7c7c7b",
        environment.production ? "font-size: 12px; color: #95c230;" : "font-size: 12px; color: #e26565;",
        "font-size: 12px; color: #7c7c7b",
        "font-size: 12px; color: #bdc6cf",
    );
    }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(() => {
          return this.buildBreadCrumb(this.route.root);
        })
      )
      .subscribe(breadcrumb => {
        const isMenuRoute = this.menu.menu$.value.some(menuEl => {
          return `/${menuEl.route}` === `${this.router.url}`;
        });

        if (!isMenuRoute) {
          this.localStorageService.storeItem(breadcrumb);
        } else {
          const string = this.router.url;
          let newString = string.slice(1).charAt(0).toUpperCase() + string.slice(2);
          this.localStorageService.clearItems();
          const breadcrumb = {
            label: newString,
            params: {},
            url: this.router.url,
          };
          this.localStorageService.storeItem(breadcrumb);
        }
      });
    //sets angular's title from route's data
    //TODO: can be removed/replaced once angular is updated to v14, Route class will have its own title property
    this.router.events.pipe(
			filter(event => event instanceof NavigationEnd),
			map(() => {
        let child = this.route.firstChild;
        //recurse into lowest child of the route tree, return its title data
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data.title) {
                return child.snapshot.data.title;
            } else {
                return null;
            }
        }
        return null;
    })
    ).subscribe((titleData) => {
      if(titleData)
        this.titleService.setTitle(titleData);
    });
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = ''): BreadCrumb {

    const path = route.routeConfig ? `${route.routeConfig.path}` : '';
    const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data['breadcrumb'] : '';
    const routeURL = route.snapshot.url.map(segment => segment.path).join('/');

    url += `${routeURL}`;

    const nextUrl = `${url}`;
    const breadcrumb = {
      label: label,
      params: route.snapshot.params,
      url: url
    };

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl);
    }

    return breadcrumb;
  }

  uploadObservation(record: ObservationQueueDto){
    if(!!record.retry && record.retry > 3) {
      return of({});
    }

    let dto = new ObservationQueueDto(record);
    let formData = dto.toFormData();
    return this.observationService.create(formData).pipe(switchMap(data => {
      this.localStorageService.deleteObservation(record);
      return of({});
    }), catchError((err, caught) => {
      //pop and reinsert with updated retry data
      //note that queueId is renewed to max upon push
      this.localStorageService.deleteObservation(dto);
      dto.lastAttemptDate = new Date();
      dto.lastErrorMsg = err;
      dto.retry++;
      this.localStorageService.pushObservation(dto);
      return of({});
    }));
  }
}


