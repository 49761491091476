import { Injectable, EventEmitter } from '@angular/core';
import { Bookmark } from '@shared/utils/bookmark';
import { LocalStorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  bookmarksChanged = new EventEmitter<Bookmark[]>();

  constructor(private localStorageService: LocalStorageService) {}

  getBookmarks(): Bookmark[] {
    const bookmarks = this.localStorageService.get('bookmarks');
    return Object.values(bookmarks);
  }

  getBookmark(id: number): Bookmark | undefined {
    const bookmarks = this.localStorageService.get('bookmarks');
    return bookmarks[id];
  }

  addBookmark(bookmark: Bookmark): void {
    const bookmarks = this.localStorageService.get('bookmarks');
    bookmarks[bookmark.id] = bookmark;
    this.localStorageService.set('bookmarks', bookmarks);
    this.bookmarksChanged.emit(this.getBookmarks());
  }

  updateBookmark(bookmark: Bookmark): void {
    const bookmarks = this.localStorageService.get('bookmarks');
    bookmarks[bookmark.id] = bookmark;
    this.localStorageService.set('bookmarks', bookmarks);
    this.bookmarksChanged.emit(this.getBookmarks());
  }

  removeBookmark(bookmark: Bookmark): void {
    const bookmarks = this.localStorageService.get('bookmarks');
    delete bookmarks[bookmark.id];
    this.localStorageService.set('bookmarks', bookmarks);
    this.bookmarksChanged.emit(Object.values(bookmarks));
  }
}
