<h1 mat-dialog-title>Import Previous Observations</h1>
<div mat-dialog-content>
  <p>Please select previous date of inspection:</p>
  <mat-radio-group [(ngModel)]="selectedItem" class="list">
    <mat-radio-button *ngFor="let item of items" [value]="item">
      {{ formatDate(item) }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions class="action-btns">
  <button mat-raised-button color="primary" [disabled]="!selectedItem" (click)="import()">
    Import
  </button>
  <button mat-button (click)="close()">Cancel</button>
</div>
