import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ObservationDataService } from '@core/service/observation-data.service';

@Component({
  selector: 'app-observation-database-detail',
  templateUrl: './observation-database-detail.component.html',
  styleUrls: ['./observation-database-detail.component.scss'],
})
export class ObservationDatabaseDetailComponent implements OnInit {
  public title = 'Observation Database Profile';
  public subtitle = '';
  observationData: any;
  observationDataId: any;
  isLoading = true;
  observationMappings: any[] = [];

  constructor(
    private observationDataService: ObservationDataService,
    private route: ActivatedRoute,
    private readonly titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.observationDataId = id;
      this.getData().add(() => {
        if (this.observationData && this.observationData.fireSafetyMeasure)
          this.titleService.setTitle(`${this.observationData.fireSafetyMeasure} - ${this.title}`);
      });
    });
    this.isLoading = false;
  }

  getData() {
    return this.observationDataService
      .getFireSafetyMeasureMappingById(this.observationDataId)
      .subscribe(res => {
        if (res) {
          this.observationData = res;
          if (res.observationMappings) {
            this.observationMappings = res.observationMappings;
          }
          this.isLoading = false;
        }
      });
  }
}
