import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MODE } from '@core/app.constants';
import { PERMISSIONS } from '@core/app.permissions';
import { FireSafetyAuditService } from '@core/service/fire-safety-audit.service';
import { FireSafetyScheduleService } from '@core/service/fire-safety-schedule.service';
import { PermissionStateService } from '@core/service/permission-state.service';
import { MtxDialog, MtxGridColumn } from '@ng-matero/extensions';
import { filter, switchMap } from 'rxjs/operators';
import { Page } from '@shared/utils/page';
import { ToastHandlerService } from '@core/service/toast-handler.service';
import { CreateEditSafetyAuditComponent } from './create-edit-safety-audit/create-edit-safety-audit.component';
import { PopUpMessages } from '@shared/utils/messages';

@Component({
  selector: 'app-fire-safety-audit',
  templateUrl: './fire-safety-audit.component.html',
  styleUrls: ['./fire-safety-audit.component.scss'],
})
export class FireSafetyAuditComponent implements OnInit {
  columns: MtxGridColumn[] = [
    {
      header: 'Inspection Due Date',
      field: 'inspectByDate',
      minWidth: 100,
      formatter: (data, colDef) => {
        return this.datePipe.transform(data.inspectByDate, 'dd-MM-yyyy');
      },
      class: 'inspect',
    },
    {
      header: 'Date of Inspection',
      field: 'inspectDate',
      minWidth: 100,
      formatter: (data, colDef) => {
        return this.datePipe.transform(data.inspectDate, 'dd-MM-yyyy');
      },
    },
    {
      header: 'Certificate Due Date',
      field: 'certDueDate',
      minWidth: 100,
      formatter: (data, colDef) => {
        return this.datePipe.transform(data.certDueDate, 'dd-MM-yyyy');
      },
    },
    {
      header: 'Fire Assessor',
      field: 'fireAssessorName',
      minWidth: 100,
    },
    {
      header: 'Action',
      field: 'operation',
      minWidth: 120,
      width: '120px',
      pinned: 'right',
      type: 'button',
      buttons: [
        {
          type: 'icon',
          icon: 'edit',
          tooltip: 'Edit',
          click: record => this.edit(record),
        },
        {
          color: 'warn',
          icon: 'delete',
          tooltip: 'Delete',
          pop: true,
          popTitle: 'Confirm delete this fire safety audit?',
          popCloseText: 'Close',
          popOkText: 'Ok',
          click: record => this.delete(record),
        },
      ],
    },
  ];
  @Input() fireSafetyAudit: any[] = [];
  list: any[] = [];
  isLoading = true;
  pageOptions = Page.PAGE_OPTIONS;
  pageSize = Page.PAGE_SIZE;
  multiSelectable = true;
  rowSelectable = true;
  hideRowSelectionCheckbox = true;
  showToolbar = true;
  columnHideable = true;
  columnMovable = true;
  rowHover = false;
  rowStriped = false;
  showPaginator = true;
  expandable = false;
  columnResizable = false;
  currentSiteId: any;
  canCreate: boolean;
  canDeleteAndUpdate: boolean;
  constructor(
    private toastHandlerService: ToastHandlerService,
    private fireSafetyAuditService: FireSafetyAuditService,
    private dialog: MtxDialog,
    private route: ActivatedRoute,
    private permissionStateService: PermissionStateService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(p => {
      let id = p.get('id');
      this.currentSiteId = id;
      this.getData();
    });
    this.isLoading = false;
    this.checkPermission();
  }
  checkPermission() {
    this.canCreate = this.permissionStateService.hasPermission(PERMISSIONS.CREATE_FIRESAFETYAUDIT);
    this.canDeleteAndUpdate = this.permissionStateService.hasPermission(
      PERMISSIONS.UPDATE_FIRESAFETYAUDIT
    );
    if (!this.canDeleteAndUpdate) {
      this.columns.pop();
    }
  }
  edit(value: any) {
    const dialogRef = this.dialog.originalOpen(CreateEditSafetyAuditComponent, {
      width: '600px',
      data: { record: value, mode: MODE.EDIT },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireSafetyAuditService.update(data))
      )
      .subscribe(data => {
        this.toastHandlerService.showSuccess(PopUpMessages.EditSuccess, '');
        this.getData();
      });
  }
  create() {
    const dialogRef = this.dialog.originalOpen(CreateEditSafetyAuditComponent, {
      width: '600px',
      data: {
        mode: MODE.CREATE,
        currentSiteId: this.currentSiteId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.fireSafetyAuditService.create(data))
      )
      .subscribe(() => {
        this.toastHandlerService.showSuccess(
          `Fire Safety Audit ${PopUpMessages.CreateSuccess}`,
          ''
        );
        this.getData();
      });
  }
  getData() {
    this.isLoading = true;
    this.fireSafetyAuditService.getBySiteId(this.currentSiteId).subscribe(res => {
      this.list = res;
      this.isLoading = false;
    });
  }
  changePage(event: PageEvent) {}
  delete(value: any) {
    this.fireSafetyAuditService.delete(value.id).subscribe(() => {
      this.toastHandlerService.showSuccess(`Fire Safety Audit ${PopUpMessages.DeleteSuccess}`, '');
      this.getData();
    });
  }

  changeSelect(e: any) {}

  changeSort(e: any) {}

  showDetail(row: any) {
    this.router.navigate([`/fire-safety-audits/detail/${row.rowData.id}`]);
  }
}
