<page-header [title]="title" class="bg-blue-600"></page-header>
<mat-card>
    <mat-card-title>
        Company Detail
    </mat-card-title>
    <div class="content">
        <div class="col">
            <div class="mtx-grid-toolbar-title detail-text">
                Name: {{company?.companyName}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Street: {{company?.street}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                City: {{company?.city}}
            </div>
            <div class="mtx-grid-toolbar-title detail-text">
                Post Code: {{company?.postCode}}
            </div>
        </div>
    </div>
</mat-card>

<app-companycontacts *ngIf="!isLoading" [companyContact]="contact"></app-companycontacts>
<app-sites *ngIf="!isLoading" [site]="site"></app-sites>
