import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

declare const window: any;

@Injectable({ providedIn: 'root' })
export class OfflineStatusService {
  public _statusIsOfflineChanged = new Subject<boolean>();
  public latestStatusIsOffline = new ReplaySubject<boolean>(1);

  get statusIsOfflineChanged() {
    return this._statusIsOfflineChanged.asObservable();
  }

  get isOffline() {
    return !!!window.navigator.onLine;
  }

  constructor() {
    this.latestStatusIsOffline.next(false);
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  private updateOnlineStatus() {
    console.log("offline status changed", !!!window.navigator.onLine);
    this._statusIsOfflineChanged.next(!!!window.navigator.onLine);
    this.latestStatusIsOffline.next(!!!window.navigator.onLine);
  }
}
