import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MODE } from '@core/app.constants';

@Component({
  selector: 'app-create-edit-companycontact',
  templateUrl: './create-edit-companycontact.component.html',
  styleUrls: ['./create-edit-companycontact.component.scss']
})
export class CreateEditCompanycontactComponent implements OnInit {

  contactForm: FormGroup;
  mode;
  MODE = MODE;
  currentCompanyId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateEditCompanycontactComponent>,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { 
    this.mode = this.data.mode;
    this.currentCompanyId = this.data.currentCompId;
  }

  ngOnInit(): void {
    this.initForm();
    if(this.mode === MODE.EDIT) {
      this.contactForm.patchValue(this.data.record);
    }
  }

  initForm() {
    this.contactForm =  this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  getErrorMessage(form: FormGroup) {
    return form.get('email')?.hasError('required')
      ? 'validations.required'
      : form.get('email')?.hasError('email')
      ? 'validations.invalid_email'
      : '';
  }
  saveAndClose() {
    const formValue = this.contactForm.value;
    formValue.companyId = this.currentCompanyId;
    if (this.mode === MODE.EDIT) {
      formValue.id = this.data.record.id;
    }
    this.dialogRef.close(formValue);
  }

}
